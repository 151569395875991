.jobs {
  ::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }

  .jobs__introduction {
    margin-top: 130px;
    padding-bottom: 130px;
    border-bottom: 2px solid $lightGrey;
    align-items: center;
    background-color: $lightGrey;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    padding-left: 7.63vw;
    padding-right: 7.63vw;
    padding-top: 130px;


    .introduction__slider {
      grid-column: 1/7;
      margin-right: 60px;
      padding-top: 111%;
      position: relative;
      border:1px solid black;

      .slider__controls {
        width: 108px;
        height: 42px;
        position: absolute;
        bottom: 24px;
        right: 24px;
        z-index: 3;
        display:flex;
        align-items: center;
        justify-content: space-between;



        button {
          width: 42px;
          height: 42px;
          outline: 0px;
          border-radius: 50%;
          background-color: $color;
          display: flex;
          align-items: center;
          justify-content: center;


          &:nth-child(2) {
            display: block;
            transform: scaleX(-1);
          }

          .arrow {
            transform: translateX(-2px) translateY(0px);
          }
        }
      }

      .slider__title {
        position: absolute;
        bottom: 20px;
        left: 24px;
        right: 140px;
        height: 42px;
        z-index: 4;
        color: $dark;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .slider-office {
        position: absolute;
        top:0px;
        left:0px;
        width: 100%;
        height: 100%;
        z-index: 1;

        .glide__track {
          height: 100%;

          .glide__slides {
            height: 100%;

            .item-expertise {
              .media {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }

    .introduction__content {
      grid-column: 7/13;
      margin-right: 36px;
      margin-left: 10px;
      margin-top: -25px;

      .section__title {
        @include section-content-title-second;
        display: block;
        font-size: 52px;
        color: #190932;
        margin-bottom: 47px;
        display: block;
        line-height: 75px;
      }

      .section__content {
        @include section-content-paragraphe;
        font-size: 18px;
        line-height: 38px;
      }
    }

  }
  .jobs__list {
    margin-left: 8%;
    margin-right: 8%;

    .jobs__banner {
      margin:100px auto;
      max-width: 1200px;

      a{
        text-decoration: none;

        img{
          border: none;
          width: 100%;
          height: auto;
          margin: 0 auto;
          max-width: 1200px;
        }
      }
    }

    .jobs__title {
      text-align:center;
      margin-top:100px;

      .title {
        font-size: 32px;
        line-height: 38px;
      }

      .surtitre {
        text-transform:uppercase;
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 6px;
      }
    }

    .jobs__filter {
      display:flex;
      align-items:center;
      justify-content:center;
      margin-top:70px;
      max-width:100%;

      .filters {
        width: 250px;
        max-width:200px;
        margin: 0px 15px;

        &.unfold {
          z-index:9;
        }
      }
    }

    .jobs__items {
      margin-top:70px;
      margin-bottom:5rem;
      margin-left: 50px;
      margin-right: 50px;
      a {
        color:inherit;
        text-decoration:none;

      }

      .jobs__items__list {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
        margin: 0px;
        padding: 0px;
        list-style:none;
        margin-bottom:60px;
        opacity:1;
        transition:400ms ease-in all;

        &.transition {
          opacity:0;
          transition:400ms ease-in all;
        }

        .jobs__items__item {
          min-height: 100px;
          border: 1px solid #F2F2F2;
          transition:200ms linear all;

          &:hover {
            -webkit-box-shadow: 0px 0px 15px 0px rgba(94,82,111,0.25);
            -moz-box-shadow: 0px 0px 15px 0px rgba(94,82,111,0.25);
            box-shadow: 0px 0px 15px 0px rgba(94,82,111,0.25);
            transition:200ms linear all;
            .item__image {
              color: #7225F1;
              transition:200ms ease-in-out;
            }
          }

          .item__image {
            position:relative;
            width:100%;
            height:165px;
            background-color: #F2F2F2;
            overflow:hidden;
            transition:200ms ease-in-out;

            .logo {
              position:absolute;
              top:50%;
              left:50%;
              transform:translateY(-50%) translatex(-50%);
            }

            img , video, {
              width:120px;
              height:auto;
              position:absolute;
              top:50%;
              left:50%;
              transform:translateY(-50%) translateX(-50%);
              border:0px;
            }
          }
          .item__infos {
            margin-left:23px;
            margin-right:23px;
            margin-top:13px;
            margin-bottom:16px;
            height:160px;
            position:relative;
            .company {
              font-size:12px;
              text-transform:uppercase;
              display:block;
              line-height:17px;
              position:absolute;
              top:0px;
            }
            .position {
              font-size:16px;
              display:block;
              line-height:20px;
              margin-bottom:24px;
              text-align:left;
              top:25px;
              left:0px;
              position:absolute;
              margin-top: -5px;
            }
            .time {
              font-size:12px;
              display:block;
              line-height:20px;
              position:absolute;
              bottom:0px;
              margin-top: 20px;
              display: block;
            }
            .datas {
              list-style:none;
              margin:0px;
              padding:0px;
              display:flex;
              position:absolute;
              bottom:40px;

              .data__item {
                margin-right:30px;
                display:flex;
                align-items:center;
                &:nth-child(2) {
                  margin-right:20px;
                }
                .data__item__image {
                  margin-right:8px;
                  margin-top: -3px;
                  img {
                    width:auto;
                    height:16px;
                  }
                }
                .data__item__title {
                  font-size:12Px;
                  text-transform:uppercase;
                }
              }
            }
          }
        }
      }
    }
  }

  .pagination {
    &.active {

    }
    &.inactive {
      display:none;
    }
  }


  .actus {
    .ct-desktop {
      display:block;
    }
    .ct-mobile {
      display:none;
    }
    .actu-st {
      align-items:center;

      p {
        margin-bottom:0px;
      }
    }
  }

  .cv-anchor {
    margin-top: 50px;
    align-items: center;
    justify-content: center;
    position: relative;
    display: block;
    width: fit-content;
    margin: 0 auto;
    margin-top: 50px;

    &:before {
      content: '';
      position:absolute;
      width: 16px;
      height: 16px;
      left: -20px;
      top:10px;
      background: url('/ui/icons/circle.svg');
      background-size: 18px 18px;
      background-position: -2px -1px;
      background-repeat: no-repeat;
    }

    span {

    }
    a {
      font-weight:bold;
      position:relative;
      cursor: pointer;
    }
  }

  .jobs__cta {
    display: block;
    text-align: center;
  }

  .zone-blob{
    z-index:1;
  }

  .steps {
    margin:0px;
  }

  .filters{
    position: relative;
    z-index:9;
    margin:0 0 1.25rem 0;
    padding:0;
    min-height:60px;

    .btn{
      position: absolute;
      z-index:1;
      top:0;
      right:0;
      display:block;
      margin:0;
      padding:0;
      width:100%;
      font-size:0;
      white-space:nowrap;
      border:solid 2px $lightGrey;
      background-color:$white;
      /*border-image-slice: 1;*/
      border-radius:30px;
      background-origin: border-box;
      background-clip: content-box, border-box;

      cursor:pointer;

    }

    &:not(.unfold){
      .btn{
      }
    }

    .inside{
      display: block;
      padding:.5rem 0;
      border-radius:1.1875rem;
      font-size:.875rem;
      line-height:1rem;
      text-align:left;
      width:100%;
      white-space:nowrap;
      overflow: hidden;
      transition:all 250ms ease-in-out;
      box-shadow: 0 0 .5rem rgba(0,0,0,0);
    }

    .icon{
      margin-left:.5rem;
      font-size:.75rem;
      vertical-align: middle;
      transition:all 250ms ease-in-out;
    }

    span{
      font-family: $fontText;
      color: $dark;
      font-size: 14px;
      line-height: 28px;
    }

    ul{
      margin:0;
      padding:0;
    }

    li{
      margin:0;
      padding:0;
      list-style: none;
    }

    .filters-selected{
      padding:.25rem 1.625rem;
      white-space: nowrap;
      color:$color;
      align-items: center;

      span{
        margin-right:auto;
      }
    }

    .filters-select{
      min-height:0;
      max-height:0;
      overflow:hidden;
      transition:100ms ease-out all;
    }

    &.unfold{

      .filters-selected{
        .icon{
          transform: rotate(-180deg);
        }
      }

      .filters-select{
        min-height:1rem;
        max-height:100px;
        overflow-y:auto;
        transition:300ms ease-out all;
        transition-delay:150ms;
      }

    }

    .filter-btn{
      display: flex;
      margin:0;
      padding:.25rem 1.625rem;
      width:100%;
      background:none;
      border:none;
      outline:none;
      text-align:left;
      cursor: pointer;

      &.selected {
        font-weight: bold;

        span {
          transform:translateX(0px);
          transition:200ms ease-out all;
        }

        .checked {
          opacity:1;
          transition:200ms ease-out all;
          transition-delay:100ms;
        }
      }

      .checked {
        margin-right:10px;
        opacity:0;
        transition:200ms ease-out all;
        svg {
          width:10px;
          height:10px;
        }
      }

      span{
        white-space: nowrap;
        transform:translateX(-15px);
        transition:200ms ease-out all;
        transition-delay:100ms;
      }

      &.active{
        display:none;
      }
    }
  }

  .emplois__titre {
    text-align:center;
    margin-top:100px;

    .title {
      font-size: 32px;
      line-height: 38px;
    }

    .surtitre {
      text-transform:uppercase;
      font-size: 14px;
      line-height: 28px;
      margin-bottom: 6px;
    }
  }

  .emplois__filters {
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:70px;
    max-width:100%;

    .filters {
      width: 250px;
      max-width:200px;
      margin: 0px 15px;

      &.unfold {
        z-index:9;
      }
    }
  }

  .emplois__items {
    margin-top:70px;
    margin-bottom:5rem;
    a {
      color:inherit;
      text-decoration:none;

    }
    .emplois__items__list {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      margin: 0px;
      padding: 0px;
      list-style:none;
      margin-bottom:40px;
      opacity:1;
      transition:400ms ease-in all;

      &.transition {
        opacity:0;
        transition:400ms ease-in all;
      }


      .emplois__items__item {
        min-height: 100px;
        border: 1px solid #F2F2F2;
        transition:200ms linear all;

        &:hover {
          -webkit-box-shadow: 0px 0px 15px 0px rgba(94,82,111,0.25);
          -moz-box-shadow: 0px 0px 15px 0px rgba(94,82,111,0.25);
          box-shadow: 0px 0px 15px 0px rgba(94,82,111,0.25);
          transition:200ms linear all;
          .item__image {
            color: #7225F1;
            transition:200ms ease-in-out;
          }
        }

        .item__image {
          position:relative;
          width:100%;
          height:150px;
          background-color: #F2F2F2;
          overflow:hidden;
          transition:200ms ease-in-out;

          .logo {
            position:absolute;
            top:50%;
            left:50%;
            transform:translateY(-50%) translatex(-50%);
          }

          img , video, {
            width:120px;
            height:auto;
            position:absolute;
            top:50%;
            left:50%;
            transform:translateY(-50%) translateX(-50%);
            border:0px;
          }
        }
        .item__infos {
          margin-left:23px;
          margin-right:23px;
          margin-top:13px;
          margin-bottom:16px;
          height:175px;
          position:relative;

          .company {
            font-size:12px;
            text-transform:uppercase;
            display:block;
            line-height:17px;
            margin-bottom:2px;
            position:absolute;
            top:0px;
          }
          .position {
            font-size:16px;
            display:block;
            line-height:20px;
            margin-bottom:24px;
            text-align:left;
            top:25px;
            left:0px;
            position:absolute;
          }
          .time {
            font-size:12px;
            display:block;
            line-height:20px;
            position:absolute;
            bottom:0px;
          }
          .datas {
            list-style:none;
            margin:0px;
            padding:0px;
            display:flex;
            position:absolute;
            bottom:40px;

            .data__item {
              margin-right:30px;
              display:flex;
              align-items:center;
              &:nth-child(2) {
                margin-right:20px;
              }
              .data__item__image {
                margin-right:8px;
                margin-top: -3px;
                img {
                  width:auto;
                  height:16px;
                }
              }
              .data__item__title {
                font-size:12Px;
                text-transform:uppercase;
              }
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: $deviceL) {
  .jobs {
    ::-webkit-scrollbar {
      width: 0px;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
    }

    .jobs__introduction {
      margin-top: 0px;
      padding-top: 70px;
      padding-bottom: 70px;
      border-bottom: 2px solid $lightGrey;
      display:flex;
      flex-direction: column-reverse;

      .introduction__slider {
        width: 100% !important;
        margin-right: 0px;
        margin-top: 40px;

        .slider__title {
          width: calc(100% - 10px);
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 14px;
          text-align: center;
        }

        .slider__controls {
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
        }

        .slider-office {
          width: 100% !important;

          .glide__track {
            width: 100% !important;

            .glide__slides {

            }
          }
        }
      }

      .introduction__content {
        .section__title {
          @include mob-title-2;
        }

        .section__content {
          grid-column: unset;
        }
      }

      .section__subtitle {
        grid-column: unset;
        margin-bottom: 35px;
        text-transform: uppercase;
      }


    }



    .jobs__list {
      margin-left: 24px;
      margin-right: 24px;

      .jobs__banner {
        margin:70px auto 0;
      }

      .jobs__title {
        margin-top: 70px;

        .title {
          font-size: 24px;
          line-height: 28px;
        }

        .surtitre {
          text-transform:uppercase;
          font-size: 12px;
          line-height: 24px;
          margin-bottom: 6px;
        }
      }

      .jobs__filter {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top:70px;
        max-width:100%;

        .filters {
          width: 100%;
          max-width: unset;
          margin: 15px 0px;

          &.unfold {
            z-index:30;
          }
        }
      }

      .jobs__items {
        margin: 70px 0px;

        a {
          color:inherit;
          text-decoration:none;
        }

        .jobs__items__list {
          display: grid;
          grid-gap: 40px;
          grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
          margin: 0px;
          padding: 0px;
          list-style:none;
          margin-bottom:40px;
          opacity:1;
          transition:400ms ease-in all;

          &.transition {
            opacity:0;
            transition:400ms ease-in all;
          }

          .jobs__items__item {
            min-height: 100px;
            border: 1px solid #F2F2F2;
            transition:200ms linear all;

            &:hover {
              -webkit-box-shadow: 0px 0px 15px 0px rgba(94,82,111,0.25);
              -moz-box-shadow: 0px 0px 15px 0px rgba(94,82,111,0.25);
              box-shadow: 0px 0px 15px 0px rgba(94,82,111,0.25);
              transition:200ms linear all;
              .item__image {
                color: #7225F1;
                transition:200ms ease-in-out;
              }
            }

            .item__image {
              position:relative;
              width:100%;
              height:150px;
              background-color: #F2F2F2;
              overflow:hidden;
              transition:200ms ease-in-out;

              .logo {
                position:absolute;
                top:50%;
                left:50%;
                transform:translateY(-50%) translatex(-50%);
              }

              img , video, {
                width:120px;
                height:auto;
                position:absolute;
                top:50%;
                left:50%;
                transform:translateY(-50%) translateX(-50%);
                border:0px;
              }
            }
            .item__infos {
              margin-left:23px;
              margin-right:23px;
              margin-top:13px;
              margin-bottom:16px;
              height:150px;
              position:relative;
              .company {
                font-size:12px;
                text-transform:uppercase;
                display:block;
                line-height:17px;
                margin-bottom:2px;
                position:absolute;
                top:0px;
              }
              .position {
                font-size:16px;
                display:block;
                line-height:20px;
                margin-bottom:24px;
                text-align:left;
                top:25px;
                left:0px;
                position:absolute;
              }
              .time {
                font-size:12px;
                display:block;
                line-height:20px;
                position:absolute;
                bottom:0px;
              }
              .datas {
                list-style:none;
                margin:0px;
                padding:0px;
                display:flex;
                position:absolute;
                bottom:40px;

                .data__item {
                  margin-right:30px;
                  display:flex;
                  align-items:center;
                  &:nth-child(2) {
                    margin-right:20px;
                  }
                  .data__item__image {
                    margin-right:8px;
                    margin-top: -3px;
                    img {
                      width:auto;
                      height:16px;
                    }
                  }
                  .data__item__title {
                    font-size:12Px;
                    text-transform:uppercase;
                  }
                }
              }
            }
          }
        }
      }
    }

    .pagination {
      &.active {

      }
      &.inactive {
        display:none;
      }
    }


    .actus {
      .ct-desktop {
        display:block;
      }
      .ct-mobile {
        display:none;
      }
      .actu-st {
        align-items:center;

        p {
          margin-bottom:0px;
        }
      }
    }

    .cv-anchor {
      margin-top:64px;
      display:flex;
      align-items:center;
      justify-content:center;

      span {

      }
      a {
        font-weight:bold;
        position:relative;
        &:after {
          content:'';
          width:100%;
          height:3px;
          background-color:blue;
          position:absolute;
          bottom:-6px;
          left:0px;
          background: rgb(114,37,241);
          background: linear-gradient(135deg, rgba(114,37,241,1) 0%, rgba(142,56,237,1) 25%, rgba(164,59,237,1) 50%, rgba(191,53,201,1) 75%, rgba(240,41,136,1) 100%);
        }
      }
    }

    .jobs__cta {
      display: block;
      text-align: center;
    }

    .zone-blob{
      z-index:1;
    }

    .steps {
      margin:0px;
    }



    .filters{
      position: relative;
      z-index:10;
      margin:0 0 1.25rem 0;
      padding:0;
      min-height:60px;

      .btn{
        position: absolute;
        z-index:1;
        top:0;
        right:0;
        display:block;
        margin:0;
        padding:0;
        width:100%;
        font-size:0;
        white-space:nowrap;
        border:solid 2px $lightGrey;
        background-color:$white;
        /*border-image-slice: 1;*/
        border-radius:30px;
        background-origin: border-box;
        background-clip: content-box, border-box;

        cursor:pointer;

      }

      &:not(.unfold){
        .btn{
        }
      }

      .inside{
        display: block;
        padding:.5rem 0;
        border-radius:1.1875rem;
        font-size:.875rem;
        line-height:1rem;
        text-align:left;
        width:100%;
        white-space:nowrap;
        overflow: hidden;
        transition:all 250ms ease-in-out;
        box-shadow: 0 0 .5rem rgba(0,0,0,0);
      }

      .icon{
        margin-left:.5rem;
        font-size:.75rem;
        vertical-align: middle;
        transition:all 250ms ease-in-out;
      }

      span{
        font-family: $fontText;
        color: $dark;
        font-size: 14px;
        line-height: 28px;
      }

      ul{
        margin:0;
        padding:0;
      }

      li{
        margin:0;
        padding:0;
        list-style: none;
      }

      .filters-selected{
        padding:.25rem 1.625rem;
        white-space: nowrap;
        color:$color;
        align-items: center;

        span{
          margin-right:auto;
        }
      }

      .filters-select{
        min-height:0;
        max-height:0;
        overflow:hidden;
        transition:100ms ease-out all;
      }

      &.unfold{

        .filters-selected{
          .icon{
            transform: rotate(-180deg);
          }
        }

        .filters-select{
          min-height:1rem;
          max-height:100px;
          overflow-y:auto;
          transition:300ms ease-out all;
          transition-delay:150ms;
        }

      }

      .filter-btn{
        display: flex;
        margin:0;
        padding:.25rem 1.625rem;
        width:100%;
        background:none;
        border:none;
        outline:none;
        text-align:left;
        cursor: pointer;

        &.selected {
          font-weight: bold;

          span {
            transform:translateX(0px);
            transition:200ms ease-out all;
          }

          .checked {
            opacity:1;
            transition:200ms ease-out all;
            transition-delay:100ms;
          }
        }

        .checked {
          margin-right:10px;
          opacity:0;
          transition:200ms ease-out all;
          svg {
            width:10px;
            height:10px;
          }
        }

        span{
          white-space: nowrap;
          transform:translateX(-15px);
          transition:200ms ease-out all;
          transition-delay:100ms;
        }

        &.active{
          display:none;
        }
      }
    }

    .emplois__titre {
      text-align:center;
      margin-top:100px;

      .title {
        font-size: 32px;
        line-height: 38px;
      }

      .surtitre {
        text-transform:uppercase;
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 6px;
      }
    }

    .emplois__filters {
      display:flex;
      align-items:center;
      justify-content:center;
      margin-top:70px;
      max-width:100%;

      .filters {
        width: 250px;
        max-width:200px;
        margin: 0px 15px;

        &.unfold {
          z-index:9;
        }
      }
    }

    .emplois__items {
      margin-top:70px;
      margin-bottom:5rem;
      a {
        color:inherit;
        text-decoration:none;

      }
      .emplois__items__list {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        margin: 0px;
        padding: 0px;
        list-style:none;
        margin-bottom:40px;
        opacity:1;
        transition:400ms ease-in all;

        &.transition {
          opacity:0;
          transition:400ms ease-in all;
        }


        .emplois__items__item {
          min-height: 100px;
          border: 1px solid #F2F2F2;
          transition:200ms linear all;

          &:hover {
            -webkit-box-shadow: 0px 0px 15px 0px rgba(94,82,111,0.25);
            -moz-box-shadow: 0px 0px 15px 0px rgba(94,82,111,0.25);
            box-shadow: 0px 0px 15px 0px rgba(94,82,111,0.25);
            transition:200ms linear all;
            .item__image {
              color: #7225F1;
              transition:200ms ease-in-out;
            }
          }

          .item__image {
            position:relative;
            width:100%;
            height:150px;
            background-color: #F2F2F2;
            overflow:hidden;
            transition:200ms ease-in-out;

            .logo {
              position:absolute;
              top:50%;
              left:50%;
              transform:translateY(-50%) translatex(-50%);
            }

            img , video, {
              width:120px;
              height:auto;
              position:absolute;
              top:50%;
              left:50%;
              transform:translateY(-50%) translateX(-50%);
              border:0px;
            }
          }
          .item__infos {
            margin-left:23px;
            margin-right:23px;
            margin-top:13px;
            margin-bottom:16px;
            height:150px;
            position:relative;
            .company {
              font-size:12px;
              text-transform:uppercase;
              display:block;
              line-height:17px;
              margin-bottom:2px;
              position:absolute;
              top:0px;
            }
            .position {
              font-size:16px;
              display:block;
              line-height:20px;
              margin-bottom:24px;
              text-align:left;
              top:25px;
              left:0px;
              position:absolute;
            }
            .time {
              font-size:12px;
              display:block;
              line-height:20px;
              position:absolute;
              bottom:0px;
            }
            .datas {
              list-style:none;
              margin:0px;
              padding:0px;
              display:flex;
              position:absolute;
              bottom:40px;

              .data__item {
                margin-right:30px;
                display:flex;
                align-items:center;
                &:nth-child(2) {
                  margin-right:20px;
                }
                .data__item__image {
                  margin-right:8px;
                  margin-top: -3px;
                  img {
                    width:auto;
                    height:16px;
                  }
                }
                .data__item__title {
                  font-size:12Px;
                  text-transform:uppercase;
                }
              }
            }
          }
        }
      }
    }

  }
}