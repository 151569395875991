.app__preloader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top:0px;
  left:0px;
  z-index: 200;
  place-items: center;
  display: block;

  .preloader__container {
    width: 100vw;
    height: 100vh;
    position: relative;

    .preloader__logoBase {
      width: 100vw;
      height: 100vh;
      position: absolute;
      z-index:2;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-color: transparent;


      .preloader__logoBaseArea {
        position: absolute;
        width: 120px;
        height: 60px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        .preloader__logoBaseMask {
          position: absolute;
          width: 120px;
          height: 60px;
          top: 0px;
          left: 0px;
          overflow: hidden;

          .preloader__logoBaseContainer {
            position: absolute;
            width: 120px;
            height: 60px;
            top: 0%;
            left: 0%;

            .logo {
              color: white;
              opacity: 0.4
            }
          }
        }
      }
    }

    .preloader__logoLight {
      width: 100vw;
      height: 100vh;
      position: absolute;
      z-index:2;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-color: transparent;


      .preloader__logoLightArea {
        position: absolute;
        width: 120px;
        height: 60px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        .preloader__logoLightMask {
          position: absolute;
          width: 80px;
          height: 60px;
          top: 0px;
          left: 0px;
          overflow: hidden;

          .preloader__logoLightContainer {
            position: absolute;
            width: 120px;
            height: 60px;
            top: 0%;
            left: 0%;

            .logo {
              color: white;
            }
          }
        }
      }
    }

    .preloader__wrap {
      width: 100%;
      height: 100%;
      position: relative;
    }


    .preloader__background {
      width: 100vw;
      height: 100vh;
      position: absolute;
      z-index:1;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-color: $dark;
      transform-origin: bottom;
      transform: scaleY(0);
    }




  }
}