.blog_homepage {


  .main__list {
    .header__articles.filtre {
      flex-direction: column;

      .list__filters {
        position: unset;
        right: unset;
        z-index: unset;

        margin-top: 18px;

        .filter__list {
          display: flex;
          width: 100%;
          flex-wrap: wrap;

          .filter__listItem {
            @include articlelist-seasonal-brand;
            color: $cleargray;
            margin-left: 35px;
            position: relative;
            transition: 300ms ease-in-out color;
            cursor: pointer;

            &:before {
              content: '';
              background-image: url('/ui/icons/circle.svg');
              background-size: 16px 16px;
              position: absolute;
              left:-10px;
              width: 16px;
              height: 16px;
              top: 55%;
              transform: translateY(-50%) translateX(-100%);
              opacity: 0;
              transition: 300ms ease-in-out opacity;
            }

            &.active {
              color: $color;
              transition: 300ms ease-in-out color;

              &:before {
                opacity: 1;
                transition: 300ms ease-in-out opacity;
              }
            }
          }
        }
      }
    }
  }



  .articles__list {
    width: 100%;
    margin-top: 112px;
    margin-bottom: 98px;
    position:relative;

    &.highlighted {
      background-color: $lightGrey;
      margin-top: 0px;
      margin-bottom:0px;
      padding-top: 112px;
      padding-bottom: 98px;
    }

    .articles__filter {
      position: absolute;
      top:0px;
      right: 8%;
      width: 200px;
      height: 50px;
      z-index: 20;
    }


  }
}