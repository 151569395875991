header.header{
	position:absolute;
	z-index:10000;
	top:2.375rem;
	left:0;
	width:100%;

	.main {
		li {
			text-transform: lowercase;
		}
	}



	.navigation{
		position: relative;
		max-width:80rem;
		min-height: $headerNavMaxH;

		font-family: $abitare350;

		li{
			list-style: none;
		}

		.logo{
			position:relative;
			z-index:2000;
			margin-left:1.5rem;
			width:5rem;
			height:2.5rem;
			font-size:2.5rem;
			line-height:0;

			@media screen and ( max-width: $deviceMd) {
				path, polygon, circle {
					fill:white;
				}
			}

		}


		.menu-burger{
			position: relative;
			z-index:2000;
			margin-right:1.5rem;
			font-size:0;
			line-height:0;

			.menu-burger-btn{
				position:relative;
				margin:1px 0;
				padding:0;
				width:1.125rem;
				height:1rem;
				font-size:0;
				line-height:0;
				text-align:right;
				border:none;
				outline:none;
				background:none;

				.line{
					display:inline-block;
					margin:2px 0;
					height:2px;
					font-size:0;
					background-color:$white;
					transition:all 250ms ease-in-out;
				}

				.line-1,
				.line-3{
					width:1.125rem;
				}
				.line-2{
					width:.525rem;
				}

				&.active{
					.line{
						position: absolute;
						top:50%;
						left:0;
					}
					.line-1{
						transform:rotate(-45deg);
					}
					.line-2{
						width:1.125rem;
						transform:rotate(45deg);
					}
					.line-3{
						width:0;
					}
				}
			}
		}

		.navigation-pannel{
			position:fixed;
			right:0;
			top:0;
			z-index:1000;
			height:0;
			background-image: linear-gradient(213deg, #361561 102%, #190932 1%);
			transition:all 500ms ease-in-out;
			overflow:hidden;

			.navigation-pannel-content{
				padding:6.875rem 1.5rem 1.5rem;

				.socials {
					display:none;
				}
			}

		}

		&.open{
			.navigation-pannel{
				height:100%;
				overflow-y:auto;
			}
		}

		.main{

			ul{
				margin:0;
				padding:0;
			}

			li{
				margin:0;
				font-size:1.5rem;


				button{
					margin:0;
					padding:0;
					background:none;
					border:none;
					outline: none;
				}

				button,
				a{
					color:$white;
					opacity:.75;

					&.selected{
						color:$white;
						opacity:1;
					}
				}

			}
		}

		.languages{
			position: relative;
			margin:0 0 2rem 0;

			.sep{
				position: absolute;
				top:50%;
				left:50%;
				display:block;
				margin:-1px 0 0 -.5625rem;
				width:1.125rem;
				height: 2px;
				background-color:$white;

				svg{
					display:block;
					width:1.125rem;
					height: 2px;
				}
			}

			ul{
				margin:0;
			}
			li{
				margin:0 .75rem;
				font-size:1.5rem;

				&:first-child{
					padding-left:0;
				}
				&:last-child{
					padding-right:0;
				}


				a{
					color:$white;
					opacity:.75;

					&.selected{
						color:$white;
						opacity:1;
					}
				}

			}
		}
	}
}


@media screen and ( max-width: $deviceMd) {
	.navigation-pannel{
		.navigation-pannel-content{
			align-items: unset !important;
    		display: flex;
    		flex-wrap: nowrap !important;
    		height: 100vh;
    		flex-direction: column-reverse;
    		padding: 35% 1.5rem 1.5rem;
    		position: relative;
			justify-content: space-between;

			&:before {
				content:'';
				position:absolute;
				top:0px;
				left:0px;
				width:100%;
				height:100%;
				background:url(../../img/backgrounds/footer_fx.png);
				background-repeat:repeat;
				opacity:0.4;
			}
			.main {
				flex:unset;
				li {
					margin-bottom:23px !important;
				}
			}
			.languages {
				margin: 0px !important;
				width: fit-content;
			}
			.socials {
				position: absolute;
				right: 1.5rem;
				display: flex !important;
				bottom: 1.5rem;
				a {
					margin:0px 12px;
					height:30px;
					color:white;

					.icon {
						svg {
							width:30px;
							height:30px;
						}
					}
				}
			}
		}
	}





}

@media screen and (min-width: $deviceMd) {
	header.header{
		display:block;
		padding:0 1.5rem;
		width:100%;

		.navigation{

			.logo{
				margin-left:0;
				line-height:0;
				width:9rem;
				height:4rem;
				font-size:4rem;
				fill:white;

			}


			.menu-burger{
				display: none;
			}

			.navigation-pannel{
				position:inherit;
				display:block;
				right:inherit;
				bottom:inherit;
				height:auto;
				transition:all 250ms ease-in-out;
				overflow: inherit;
				background:none;

				.navigation-pannel-content{
					padding:0;
				}

			}

			.main{

				li{
					margin:0 .75rem;
					font-size:.75rem;

					&:last-child{
						margin-right:0;
					}

					button,
					a{
						transition: all .25s ease-in-out;

						&:hover{
							text-decoration:none;
							opacity:1;
						}
					}
				}
			}

			.languages{
				position: absolute;
				top:-2rem;
				right:0;
				z-index:200;
				margin:0;
				ul{
					margin:0;
				}
				li{
					padding:0 1rem;
					font-size:.75rem;

					&:first-child{
						padding-left:1rem;
					}
					&:last-child{
						padding-right:0;
					}

					a{

						transition: all .25s ease-in-out;

						&:hover{
							text-decoration:none;
							opacity:1;
						}

					}
				}
			}

		}

		&.light-theme{
			.navigation{

				.main{
					li{
						button,
						a{
							color:$dark;

							&.selected{
								color:$dark;
							}
						}
					}
				}

				.languages{
					li{
						a{
							color:$dark;

							&.selected{
								color:$dark;
							}
						}

					}
				}
			}
		}
	}
}

@media screen and (max-width: $deviceLg){
	header.header{
		.navigation{
			&.iswhite,
			&.open{
				.logo{
					svg,
					svg *{
						//fill:$white!important;
					}
				}
			}
		}
	}


}

@media screen and (min-width: $deviceLg) {
	header.header{
		top:5rem;

		.navigation{

			.main{

				li{
					font-size:1rem;

					a{

					}

					&:first-child{

					}
				}
			}

			.languages{
				top:-4rem;
				li{
					font-size:1rem;
				}

			}
		}
	}
}
