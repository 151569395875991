.articlesList {
  margin-left: 8%;
  margin-right: 8%;
  width: 84%;

  .header__articles {
    display:flex;
    align-items:center;
    justify-content: space-between;
    margin-bottom: 63px;
    position:relative;

    &.description {
      display: grid;
      grid-template-columns: repeat(12, 60px);
      justify-content: space-between;
      align-items: flex-start;

      .section__title {
        grid-column: 1/6;
      }

      .list__description {
        grid-column: 8/13;
        margin-right: 60px;
      }
    }

    &.filtre {
      align-items: flex-start;
    }


    .section__title {
      font-family: $fontText;
      color: $dark;
      font-size: 32px;
      line-height: 38px;
      width: fit-content;
      min-width: fit-content;
    }

    .list__filters {
      position:absolute;
      right:0px;
      z-index: 80;
      width: 300px;
    }

    .filters__articles {
      height: 50px;
      width: 100%;
      z-index: 20;
      display:flex;
      align-items:flex-start;
      justify-content: flex-end;

      .filter__select {
        width: 200px;
      }
    }

    .slider__controls {
      display:flex;
      align-items: center;
      justify-content: space-between;
      width: 110px;
      min-width: 110px;
      height: 42px;
      margin-left: 52px;

      .control {
        width:42px;
        height:42px;
        background-color: $color;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius:50%;
        outline: 0px;

        &:nth-child(2) {
          transform: scaleX(-1);

          .arrow {
            transform: translateX(-2px);
          }
        }

        .arrow {
          width: 10px;
          height: 17px;
          transform: translateX(-3px);
        }

      }
    }
  }


  .articles {

    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 30%);
    grid-row-gap: 120px;
    justify-content: space-between;

    .article {
      margin: 0px 10px;
      .visual {
        width: 100%;
        padding-top: 75%;
        display: block;
        position:relative;
        margin-bottom: 28px;
        background-color: $lightGrey;

        .media {
          position: absolute;
          top:0px;
          left: 0px;
          width: 100%;
          height: 100%;

          .media__container{
            .media__url{
              max-width:100%;
            }
          } 
        }
      }
      .article__title {
        font-family: $fontText;
        font-size: 16px;
        line-height: 28px;
        color: $dark;
      }
      .article__description {
        font-family: $fontText;
        font-size: 16px;
        line-height: 28px;
        color: $cleargray;
        margin-top: 6px;
      }
    }

    .article.blog-article {
      position: relative;
      padding-bottom: 40px;
      .visual {
        position:relative;
        margin-bottom: 15px;

        .media {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          z-index: 1;

          .media__container{
            .media__url{
              max-width:100%;
            }
          } 
        }

        .article__categ {
          padding: 7px 15px;
          background-color: $color;
          position: absolute;
          right: 15px;
          top: 15px;
          z-index: 2;
          border-radius: 5px;
          color: white;
          line-height: 17px;
          font-size: 12px;
        }
      }

      .article__time {
        font-size: 12px;
        line-height: 17px;
        color: $cleargray;
        margin-bottom: 10px;

        span {
          font-weight: bold;
        }
      }

      .article__title {
        @include articlelist-seasonal-brand;
        margin-bottom: 10px;
        margin-right: 20%;
      }

      .article__description {
        @include articlelist-seasonal-brand;
        color: $cleargray;
        margin-bottom: 20px;
      }

      .article__link {
        margin-top: 20px;
        position:absolute;
        width: 100%;
        bottom:0px;
        left:0px;
        span {
          text-align: left;
          width: 100%;
          display: block;
          @include articlelist-seasonal-brand;
        }
      }
    }

    .article.blog-file {
      background-color: $lightGrey;
      padding: 50px 80px 50px 30px;

      .article__title {
        font-size: 32px;
        line-height: 38px;
        color: $dark;
        margin-bottom: 18px;
      }

      .article__subtitle {
        @include articlelist-seasonal-brand;
      }

      .article__description {
        @include articlelist-seasonal-brand;
        color: $cleargray;
        margin-top: 16px;
      }

      .buttonCircle {
        margin-top: 50px;

        span {
          color: $dark;
        }
      }
    }

    .article.blog-newsletter {
      background-color: $lightGrey;
      padding: 50px 80px 50px 30px;

      .article__title {
        font-size: 32px;
        line-height: 38px;
        color: $dark;
        margin-bottom: 18px;
      }

      .article__subtitle {
        @include articlelist-seasonal-brand;
      }

      .form__blog {
        margin-top: 30px;
        width: 100%;

        .form__input {
          width: 100%;
          margin-bottom: 20px;
          background-color: transparent;
          border:0px;
          outline: 0px;
          border-bottom: 2px solid rgba(140, 132, 152, 0.2);
          padding-bottom: 6px;
          line-height: 28px;
          font-size: 16px;

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }

      .buttonCircle {
        margin-top: 50px;

        span {
          color: $dark;
        }
      }
    }

    .article.seasonal {
      .article__brand {
        margin-top: 25px;
        @include articlelist-seasonal-brand;
      }
      .article__title {
        @include articlelist-seasonal-brand;
        color: $cleargray;
        margin-top: 5px;
      }
      .article__contact {
        margin-top: 30px;

        .contact__name {
          @include articlelist-seasonal-brand;
          color: $color;
          font-weight: 500;
        }
        .contact__position {
          @include articlelist-seasonal-brand;
          color: $dark;
          font-weight: 500;
        }
        .contact__email {
          @include articlelist-seasonal-brand;
          color: $dark;
          font-weight: 500;
          margin-top: 15px;
        }
      }
    }
  }

  .articles-slider {
    .article {
      position:relative;
      margin: 0px 10px;
      .visual {
        width: 100%;
        padding-top: 75%;
        display: block;
        position:relative;
        margin-bottom: 28px;
        background-color: $lightGrey;

        .media {
          position: absolute;
          top:0px;
          left: 0px;
          width: 100%;
          height: 100%;
          
          .media__container{
            .media__url{
              max-width:100%;
            }
          } 
        }
      }
      .article__title {
        font-family: $fontText;
        font-size: 16px;
        line-height: 28px;
        color: $dark;
      }
      .article__description {
        font-family: $fontText;
        font-size: 16px;
        line-height: 28px;
        color: $cleargray;
        margin-top: 6px;
        min-height:112px;
      }

      .buttonCircle {
        display:block;
        margin-top: 30px;
        margin-bottom: 30px;
        span {
          color: $dark;
        }
      }
    }
  }

  .pagination {
    margin-top: 50px;
    .buttonCircle {
      display: block;
      margin: 0 auto;
      span {
        color: $color;
      }
    }
  }


}


@media screen and (max-width: $deviceL) {

  .articlesList {
    margin-left: 24px;
    margin-right: 24px;


    .header__articles {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;

      &.filtre {
        padding-bottom: 100px;
      }

      .list__filters {
        right:0px;
        left: 0px;
        top: 50px;
        margin-top: 30px;
        margin-bottom: 30px;
        width: 100%;
        z-index:10;
        background-color: white;

        .filters_list {
          background-color: white;
        }
      }

      &.description {
        grid-template-columns: unset;
        display: block;
        margin-bottom: 45px;


        .section__title {
          grid-column: unset;
          margin-bottom: 35px;
        }

        .list__description {
          grid-column: unset;
          margin-right: 0px;
        }
      }

      .section__title {
        font-family: $fontText;
        color: $dark;
        font-size: 24px;
        line-height: 34px;
        width: unset;
        min-width: unset;
      }

      .filters__articles {
        height: 50px;
        width: 100%;
        z-index: 20;
        display:flex;
        align-items:flex-start;
        justify-content: flex-end;

        .filter__select {
          width: 200px;
        }
      }

      .slider__controls {
        display:flex;
        align-items: center;
        justify-content: space-between;
        width: 110px;
        min-width: 110px;
        height: 38px;
        margin-left: 0px;
        margin-top: 20px;
        width: fit-content;
        min-width: fit-content;

        .control {
          width:38px;
          height:38px;
          background-color: $color;
          display:flex;
          align-items: center;
          justify-content: center;
          border-radius:50%;
          outline: 0px;

          &:nth-child(2) {
            transform: scaleX(-1);
            margin-left: 20px;

            .arrow {
              transform: translateX(-2px);
            }
          }

          .arrow {
            width: 10px;
            height: 17px;
            transform: translateX(-3px);
          }

        }
      }
    }

    .articles {
      display: flex;
      flex-direction: column;


      .article.blog-file {
        background-color: $lightGrey;
        padding: 20px;

        .article__title {
          font-size: 32px;
          line-height: 38px;
          color: $dark;
          margin-bottom: 18px;
        }

        .article__subtitle {
          @include articlelist-seasonal-brand;
        }

        .article__description {
          @include articlelist-seasonal-brand;
          color: $cleargray;
          margin-top: 16px;
        }

        .buttonCircle {
          margin-top: 50px;

          span {
            color: $dark;
          }
        }
      }

      .article.blog-newsletter {
        background-color: $lightGrey;
        padding:  20px;

        .article__title {
          font-size: 32px;
          line-height: 38px;
          color: $dark;
          margin-bottom: 18px;
        }

        .article__subtitle {
          @include articlelist-seasonal-brand;
        }

        .form__blog {
          margin-top: 30px;
          width: 100%;

          .form__input {
            width: 100%;
            margin-bottom: 20px;
            background-color: transparent;
            border:0px;
            outline: 0px;
            border-bottom: 2px solid rgba(140, 132, 152, 0.2);
            padding-bottom: 6px;
            line-height: 28px;
            font-size: 16px;

            &:last-child {
              margin-bottom: 0px;
            }
          }
        }

        .buttonCircle {
          margin-top: 50px;

          span {
            color: $dark;
          }
        }
      }


      .article {
        margin: 0px;
        .visual {
          width: 100%;
          padding-top: 75%;
          display: block;
          position:relative;
          margin-bottom: 28px;
          background-color: $lightGrey;

          .media {
            position: absolute;
            top:0px;
            left: 0px;
            width: 100%;
            height: 100%;
          }
        }
        .article__title {
          font-family: $fontText;
          font-size: 16px;
          line-height: 28px;
          color: $dark;
        }
        .article__description {
          font-family: $fontText;
          font-size: 16px;
          line-height: 28px;
          color: $cleargray;
          margin-top: 6px;
        }
      }

      .article.seasonal {
        .article__brand {
          margin-top: 25px;
          @include articlelist-seasonal-brand;
        }
        .article__title {
          @include articlelist-seasonal-brand;
          color: $cleargray;
          margin-top: 5px;
        }
        .article__contact {
          margin-top: 30px;

          .contact__name {
            @include articlelist-seasonal-brand;
            color: $color;
            font-weight: 500;
          }
          .contact__position {
            @include articlelist-seasonal-brand;
            color: $dark;
            font-weight: 500;
          }
          .contact__email {
            @include articlelist-seasonal-brand;
            color: $dark;
            font-weight: 500;
            margin-top: 15px;
          }
        }
      }

      &.seasonals {
        grid-row-gap: 40px;
      }
    }



  }

}