.news-detail{
	.blobs{
		z-index:-1;
		top:0;
		left:-7rem;
		width:54rem;

		.blob{

			&.layer1{
				left:.20%;
				width:99.80%;
			}

			&.layer2{
				left:.89%;
				width:99.11%;
			}

			&.layer3{
				left:0;
				width:100%;

			}
		}
	}
	.header{
		margin:0 0 1.5rem 0;

		.link{
			margin:0 0 1.5rem 0;

			.arrow{
				display: inline-block;
				margin:0 .25rem 0 0;
				vertical-align:middle;
			}

			span{
				display: inline-block;
				font-family: $abitare350;
				font-size:.875rem;
				line-height:1rem; 
				color:$dark;
				font-weight:700;
				vertical-align:middle;
			}
		}

		.title{
			margin:0 0 1.333rem 0;
			max-width: 58.75rem;
			font-family: $crimson;
			font-size:1.5rem;
			line-height:2rem; 
			font-weight:800;
			color:$dark;
		}

		.date{
			font-family: $abitare50;
			font-size:1rem;
			line-height:2rem; 
			color:$cleargray;
		}
	}
	.latest__news {
		padding-top: 110px;
		padding-bottom: 140px;
	}
	.latest{
		margin:0 0 14rem 0;
		.header{
			margin:0 0 1.255rem 0;
		}
		.title{
			margin:0 0 1.25rem 0;
			padding:0;
			font-family: $abitare1000;
			font-size:1.5rem;
			line-height: 1.625rem;
		}
		.cta{
			margin:0;
			padding:0;
			background:none;
			border:none;
			outline:none
		}
		
		.list{
			position: relative;
			margin:0 -1.5rem 0 -1.5rem;

			.slide{
				position: relative;
				display:block;
				margin:0 -.75rem;
				padding:0 1.5rem;
				max-width:max-content;
				min-width:100%;
				white-space: nowrap;

				.item{
					display: inline-block;
					padding:0 .75rem;
					width:17.5rem;
					white-space: normal;
					vertical-align:top;

					a{
						display:flex;
						flex-wrap:wrap;
						flex:0 0 100%;
						width:100%;
						height:100%;
						max-width:100%;
					}

					.arrow{
						display: inline-block;
					}

					.title{
						margin:0 0 2rem 0;
						min-height:7.5rem;
					}
					.visual{
						width:100%;
					}
				}

			}
		}


	}
	.item{
		padding:0 .875rem 2.6875rem;

		.arrow{
			position: absolute;
			bottom:0;
			right:0;
			display:none;
			width:2rem;
			height:2rem;

			.off,
			.on{
				position: absolute;
				top:0;
				left:0;
			}

			.off{
				opacity:1;
			}

			.on{
				opacity:0;
			}
		}

		a{
			position: relative;
			display: block;
			cursor: pointer;
		}

		.date{
			font-family: $abitare50;
			font-size:.875rem;
			line-height:2rem; 
			color:$cleargray;
			text-align: left;
		}

		.title{
			font-family: $crimson;
			font-weight:800;
			font-size:1.25rem;
			line-height:1.5rem; 
			color:$dark;
			text-align: left;
		}

		.visual{
			position:relative;
			margin:0 0 1.375rem 0;
			padding:70.21% 0 0 0;
			height:0;

			.img-mask{
				position:absolute;
				z-index:2;
				top:0;
				right:0;
				bottom:0;
				left:0;

				display: block;

				overflow: hidden;

				box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16);

				img{
					position:absolute;
					z-index:2;
					top:50%;
					left:50%;
					display: block;
					width:auto;
					height:100%;
					margin:0px;
					top:50%;
					left:50%;
					transform:translateX(-50%) translateY(-50%);
				}
			}

		}
	}
	.hero__page {
		.hero__container .hero__content .hero__main .hero__title_container .hero__surtitle p{
			font-size: 32px;
			line-height: 38px;
			margin-bottom: 20px;
			text-transform: capitalize;
		}
	}
	.news__content {
		display: grid;
		border-bottom: 2px solid $lightGrey;
		padding-bottom: 112px;
		.side__share {
			grid-column: 1/3;
			height: 100%;
			position: relative;

			.share__container {
				position: sticky;
				top: 18vh;
				bottom:0px;

				.share__title {
					@include articlelist-seasonal-brand;
				}

				.share__list {
					display:flex;
					align-items:center;
					justify-content: space-between;
					margin-top: 23px;
				}
			}
		}
		.side__content {
			grid-column: 3/11;
			margin-left: 60px;
			margin-right: 60px;

			.new__detailContact {
				margin-top:75px;

				.contacts-title {
					text-transform: uppercase;
					margin-bottom: 36px;
				}

				.contact-item {
					margin-bottom: 30px;

					&:last-child {
						margin-bottom: 0px;
					}

					.contact-position {
						text-transform: uppercase;
						margin-bottom: 7px;
					}

					.contact-name {
						color: $color;
					}

					.contact-email {
						color: $dark;
						text-decoration: underline;
					}
				}
			}
		}
	}
}

@media screen and ( min-width: $deviceXl  ) {
	.news-detail .news__content .side__content {
		margin-left: 60px;
		margin-right: 60px;
		grid-column: 4/10;
	}
}

@media screen and ( max-width: $deviceL) {
	.news-detail {
		.news__content {
			margin-top: 40px;
			.side__share {
				grid-column: 1/7;
				grid-row: 2/3;
				height: 100%;
				position: relative;
				margin-top: 40px;

				.share__container {
					position: sticky;
					top: 18vh;
					bottom:0px;

					.share__title {
						@include articlelist-seasonal-brand;
					}

					.share__list {
						display:flex;
						align-items:center;
						justify-content: space-between;
						margin-top: 23px;
					}
				}
			}
			.side__content {
				grid-column: 1/7;
				grid-row: 1/2;
				margin-left: 0px;
				margin-right: 0px;
			}
		}
	}
}
