

                                                            /* *************************
                                                            GLOBALS
                                                            ** ************************/


                                                            /* *************************
                                                            COLORS
                                                            ** ************************/
$dark-blue  : #0F0420;
$dark       : #190932;
$medium     : #361561;
$white  	: #ffffff;
$color 		: #7225F1;
$color2		: #8E38ED;
$color3     : #A43BED;
$cleargray 	: #8c8498;
$middlegray : #5E526F;
$lightGrey  : #F0F0F0;
$chaire     : #FF705F;
$bgPage     : #F4F4F4;


                                                            /* *************************
                                                            BREAKPOINTS
                                                            ** ************************/
                                                        
$deviceXs                    : 320px;               
$deviceSm                    : 576px;
$deviceMd                    : 768px;
$deviceL                     : 1024px;
$deviceLg                    : 1140px;
$deviceXl                    : 1440px;

$verticalSm                  : 600px;
$verticalMd                  : 730px;
$verticalXmd                 : 900px;
$verticalLg                  : 1140px;
$navExpandedW : 200px;

$abitare50 : 'Abitare_50', helvetica, Arial, sans-serif;
$abitare350 : 'Abitare_350', helvetica, Arial, sans-serif;
$abitare700 : 'Abitare_700', helvetica, Arial, sans-serif;
$abitare800 : 'Abitare_800', helvetica, Arial, sans-serif;
$abitare1000 : 'Abitare_1000', helvetica, Arial, sans-serif;
$crimson : 'Crimson Text', helvetica, serif;

$manrope : 'Manrope', helvetica, Arial, sans-serif;
$calluna: 'Calluna', serif;

$fontText : $manrope;
$fontSerif: $calluna;

$headerNavMaxH : 2.5rem;



@media screen and (min-width: $deviceMd) {
$headerNavMaxH : 4rem;
}

@media screen and (min-width: $deviceLg) {
}