.page.about__homepage {

  .about__introduction {
    .step__content {
      strong {
        color: $dark;
      }
    }
  }

  .edito__media {
    width: 100vw;
    height: 100vh;
    background-color: $cleargray;

    .media {
      width: 100%;
      height: 100%;
    }
  }

  .about__keyfigures {
    padding-top: 134px;
  }

  .edito {
    padding-top: 120px;
    padding-left: 11vw;
    padding-right: 11vw;

    .section__surtitle {
      grid-column: 1/13;
      text-align:center;
      @include section-content-step;
      text-transform: uppercase;
    }

    .section__title {
      grid-column: 2/12;
      text-align:center;
      margin-top: 10px;
      margin-bottom: 75px;
    }

    .edito__content {
      grid-column: 1/13;
      display:block;
      column-count: 2;
      column-gap: 80px;
      grid-template-columns: repeat(12, 60px);
      justify-content: space-between;
      padding-bottom: 120px;

      .main__content {
        grid-column: 7/12;
        grid-row: 1;
        @include section-content-paragraphe;
      }

      .main__content--highlight {
        grid-column: 2/6;
        margin-top:200px;
        grid-row: 1;
        @include section-keydata-title;
        font-weight: 500;
      }
    }

  }

  .edito__content--carousel {
    padding-top: 120px;
    padding-bottom: 130px;
    background-color: $lightGrey;
    display: grid;
    grid-column: 1/13;
    grid-template-columns: repeat(12, 60px);
    justify-content: space-between;
    align-items: center;
    padding-left: 8%;
    padding-right: 8%;

    .carousel__controleur {
      grid-column: 1/7;
      background-color: white;
      display:flex;
      align-items: center;
      justify-content: center;
      margin-right: 60px;
      padding: 60px 0px;

      .controleur__items {
        width: 389px;
        height: 359px;
        margin:100px 20px;
        position:relative;

        .controleur__item {
          position: absolute;
          width: 210px;
          height: 210px;
          min-width: 210px;
          display: flex;
          align-items: center;
          justify-content: center;
          border:2px solid $lightGrey;
          border-radius: 50%;
          box-sizing: border-box;

          .item__title {
            @include edito-controleur-title;
          }

          &:hover {
            background-color: $lightGrey;
            .item__title {
              color: $color;
            }
          }

          &:first-child {
            position: absolute;
            top: 0px;
            left: 50%;
            transform: translateX(-50%);
          }
          &:nth-child(2){
            position: absolute;
            bottom: 0px;
            left: 0px;
            transform: translateX(0%);
          }
          &:last-child {
            position: absolute;
            bottom: 0px;
            right: 0px;
            transform: translateX(0%);
          }

        }
      }
    }

    .carousel__contents {
      grid-column: 7/13;

      .contents__items {

        .contents__item {
          display: none;
          opacity: 0;
          transition: display 0s linear 300ms, opacity 300ms ease-in-out 0s;

          &.active {
            display: block;
            opacity:1;
            transition: display 0s linear 1s, opacity 300ms ease-in-out 2s;
          }

          .item__title {
            @include section-content-title-second;
            margin-bottom: 35px;
          }

          .item__controleur {
            @include edito-controleur-title;
            color: $color;
            margin-bottom: 20px;
          }

          .item__content {
            @include edito-content-item-title;
            margin-right: 120px;
          }
        }
      }
    }

  }

  .about__good {
    background-color: $lightGrey;
    padding-top: 125px;
    padding-bottom: 125px;
    align-items: center;
      .good__media {
        grid-column: 1/7;
        margin: 0px 25px;
        background-color: white;
        display: grid;
        place-items: center;
        padding-top: 0;
        padding-bottom: 0;
        height:100%;

        .good__mediaContainer {
          width: 23.89vw;

          .displayed-good{
            display: flex;
            flex:0 0 100%;
            flex-wrap: wrap;
            min-width:100%;
            width: 100%;
            align-items: center;
            justify-content:center;

            .media{
              margin-bottom:40px;
              .media__container{
                .media__item{
                  width: auto;
                  height: 60px;
                  min-height:60px;
                  min-width:auto; 
                  object-fit: unset;
                  object-position: unset;
                }
              }   
            }         
            .name{
              display: flex;
              flex:0 0 100%;
              flex-wrap: wrap;
              min-width:100%;
              width: 100%;
              margin-bottom:40px;
              font-size:28px;
              justify-content:center;
              text-align:center;

            }
            .description{
              display: flex;
              flex:0 0 100%;
              flex-wrap: wrap;
              min-width:100%;
              width: 100%;
              font-size:16px;
              line-height:24px;
              justify-content:center;
              text-align:center;

            }
            .link{
              display: flex;
              flex:0 0 auto;
              flex-wrap: wrap;
              min-width:100%;
              width: auto;
              margin-top:20px;
              font-size:16px;
              line-height:24px;
              color:$color;
              justify-content:center;
              text-align:center;
              transition:color 250ms ease-in-out;

              &:hover{
                color:$dark;
              }
            }
          }
        }
      }

      .good__content {
        grid-column: 7/12;
        margin-right: 60px;
        margin: 0px 50px;

        .good__surtitle {
          @include section-content-step;
          text-transform: uppercase;
          margin-bottom: 10px;
        }

        .good__title {
          @include section-content-title-second;
          margin-bottom: 15px;
        }

        .good__list {
          .good__cat {
            margin-bottom: 17px;
            height:28px;
            min-height: 28px;
            transition: 300ms ease-in-out min-height;
            overflow:hidden;
            .cat__head {
              height: 28px;
              display: flex;
              align-items: center;
              cursor: pointer;
              .good__catName {
                @include edito-controleur-title;
                color:$cleargray;
                margin-right: 10px;
              }
              .cat__toggle {
                color: $cleargray;
                transform: rotate(-90deg);
                transition: 300ms ease-in-out all;
              }
            }
            .good__catList {
              padding-top: 22px;
              padding-bottom: 22px;
              margin:0 -20px;
              display: flex;
              flex-wrap: wrap;

              width: 100%;

              .good__catListItem {
                display:flex;
                padding:5px 20px;
                flex:0 0 auto;
                min-width:auto;
                position:relative;

                span{
                  cursor:pointer;
                  transition:color 300ms ease-in-out;
                  &:hover{
                    color:$color;
                  }
                }

                &.active{
                  span{
                    color:$color;
                  }
                }


                .media {
                  display: block;
                  width: 100%;
                  height: 100%;

                  .media__container {
                    display: block;
                    width: 100%;
                    height: 100%;

                    .media-svg {
                      display: block;
                      width: 100%;
                      height: 100%;

                      div {
                        display: block;
                        width: 100%;
                        height: 100%;

                        svg {
                          display: block;
                          width: 100%;
                          height: 100%;
                        }
                      }
                    }
                  }
                }

              }
            }

            &.active {
              min-height:200px;

              .good__catName{
                color: $color;
              }

              .cat__toggle {
                color: $color;
                transform: rotate(0deg);
              }

            }
          }
        }
      }
  }

  .about__talents {
    padding-top: 122px;
    padding-bottom: 122px;
    align-items: center;
    display:flex;
    flex-direction: column;

    .talents__content {
      grid-column: 7/12;
      margin-right: 60px;
      margin: 0px 50px;

      .talents__title{
        @include section-content-title-second;
        margin-bottom: 90px;
      }
    }
    .item__visual {
      width: 100%;
      padding-top: 150.50%;
      position:relative;

      .media {
        position:absolute;
        top:0px;
        left: 0px;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: $deviceL) {
  .page.about__homepage {



    .about__introduction {
      padding-top: 70px;
      padding-bottom: 70px;
      margin-top: 0px;
      margin-bottom: 0px;
      display:flex;
      flex-direction: column;

      .section__title {
        margin-bottom: 35px;
      }

      .step__content {
        margin-left: 0px;
        margin-right: 0px;
      }
    }

    .about__keyfigures {
      padding-top: 0px;
    }

    .about__keydata {

      display:flex;
      flex-direction: column;
      padding-top: 70px;
      padding-bottom: 70px;

      .keydatas {

        display:flex;
        flex-direction: column;
        align-items: center;


        .keydata {

          margin: 0px;
        }

      }

    }

    .edito {
      padding-top: 70px;
      padding-left: 0vw;
      padding-right: 0vw;
      display:flex;
      flex-direction: column;

      .section__surtitle {
        grid-column: unset;
        text-align:center;
        @include section-content-step;
      }

      .section__title {
        grid-column: unset;
        text-align:center;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 10px;
        margin-bottom: 60px;
      }

      .edito__content {
        grid-column: unset;
        display: flex;
        flex-direction: column-reverse;
        grid-template-columns: unset;
        justify-content: unset;
        padding-bottom: 70px;

        .main__content {
          grid-column: unset;
          grid-row: unset;
          @include section-content-paragraphe;
          margin-top:35px;
        }

        .main__content--highlight {
          grid-column: unset;
          margin-top:0px;
          grid-row: unset;
          @include xs-nav-link;
          font-weight: 500;
        }
      }

    }

    .edito__media {
      width: 100vw;
      height: auto;
      background-color: $cleargray;

      .media {
        width: 100%;
        height: 100%;

        .media__item {
          width: 100% !important;
          min-width: 100% !important;
          height: auto !important;
          min-height: auto !important;
        }
      }
    }

    .edito__content--carousel {
      padding-top: 70px;
      padding-bottom: 70px;
      display: flex;
      flex-direction: column;
      grid-template-columns: unset;
      justify-content: space-between;
      align-items: center;
      padding-left: 24px;
      padding-right: 24px;

      .carousel__controleur {
        grid-column: unset;
        display:flex;
        margin-right: 0px;
        padding: 20px 0px;
        width: 100%;
        background-color: $lightGrey;

        .controleur__items {
          display:flex;
          align-items: center;
          justify-content:space-between;
          flex-direction: row;
          flex-wrap: nowrap;
          width: 80vw;
          height: 30px;
          margin: 0px;

          .controleur__item {
            width: fit-content;
            height: fit-content;
            min-width: fit-content;

            margin-top:0px;
            border:2px solid $lightGrey;
            border-radius: 0px;
            position: relative;
            box-sizing: border-box;

            .item__title {
              @include edito-controleur-title;
              font-size: 14px;
            }

            &:hover {
              background-color: $lightGrey;
              .item__title {
                color: $color;
              }
            }

            &:first-child {
              margin-top: 0px;
              position: unset;
              top: unset;
              left: unset;
              transform: unset;
            }
            &:nth-child(2){
              position: unset;
              top: unset;
              left: unset;
              transform: unset;
            }
            &:last-child {
              position: unset;
              top: unset;
              left: unset;
              transform: unset;
            }


          }
        }
      }

      .carousel__contents {
        grid-column: 7/13;

        .contents__items {

          .contents__item {

            .item__title {
              @include mob-title-1;
              margin-bottom: 35px;
            }

            .item__controleur {
              @include edito-controleur-title;
              color: $color;
              margin-bottom: 20px;
            }

            .item__content {
              @include mob-txt-1;
              margin-right: 0px;
            }
          }
        }
      }

    }

    .about__list {
      display: flex;
      flex-direction: column;
      padding-top: 70px;
      margin-top: 0px;
      padding-bottom: 70px;
      margin-bottom: 0px;

      .section__title {
        @include mob-title-1;
        grid-column: unset;
        text-align: center;
        margin: 0px 20px;
        margin-bottom: 70px;
      }

      .list__items {
        grid-column: unset;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        .item {
          display: flex;
          flex-direction: column;
          grid-template-columns: unset;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 75px;

          .item__index {
            width: 50px;
            height: 50px;
            margin-bottom:30px;
          }
        }
      }
    }

    .about__good {
      background-color: $lightGrey;
      padding-top: 70px;
      padding-bottom: 70px;
      align-items: center;
      display:flex;
      flex-direction: column-reverse;


      .good__media {
        width: 100%;
        padding:0px;
        margin-top:50px;

        .good__mediaContainer {
          display: flex;
          flex:0 0 100%;
          flex-wrap: wrap;
          min-width:100%;
          width: 100%;
          align-items: center;
          justify-content:center;

          .displayed-good{
            display: flex;
            flex:0 0 100%;
            flex-wrap: wrap;
            min-width:100%;
            width: 100%;
            align-items: center;
            justify-content:center;
            .media{
              .media__container{
                .media__item{
                  width: auto;
                  height: 60px;
                  min-height:60px;
                  min-width:auto; 
                  object-fit: unset;
                  object-position: unset;
                }
              }   
            }         
            .name{

            }
            .description{

            }
          }
        }
      }

      .good__content {
        grid-column: 7/12;
        margin-right: 0px;
        margin: 0px 0px;

        .good__surtitle {
          @include section-content-step;
          text-transform: uppercase;
          margin-bottom: 10px;
        }

        .good__title {
          @include section-content-title-second;
          margin-bottom: 15px;
        }

        .good__list {
          margin-top: 35px;
          .good__cat {
            margin-bottom: 17px;
            height: 28px;
            min-height: 28px;
            transition: 300ms ease-in-out min-height;
            overflow:hidden;
            .cat__head {
              height: 28px;
              display: flex;
              align-items: center;
              cursor: pointer;
              .good__catName{
                @include edito-controleur-title;
                color: $cleargray;
                margin-right: 10px;
              }
              .cat__toggle {
                color: $cleargray;
                transform: rotate(-90deg);
                transition: 300ms ease-in-out all;
              }
            }
            .good__catList {
              padding-top: 22px;
              padding-bottom: 22Px;
              display: grid;
              grid-template-columns: repeat(3, 30%);
              grid-template-rows: repeat(2, 30px);
              justify-content: space-between;
              width: 100%;
              grid-row-gap: 20px;

              .good__catListItem {
                position:relative;

                .media {
                  display: block;
                  width: 100%;
                  height: 100%;

                  .media__container {
                    display: block;
                    width: 100%;
                    height: 100%;

                    .media-svg {
                      display: block;
                      width: 100%;
                      height: 100%;

                      div {
                        display: block;
                        width: 100%;
                        height: 100%;

                        svg {
                          display: block;
                          width: 100%;
                          height: 100%;
                        }
                      }
                    }
                  }
                }

              }
            }

            &.active {
              min-height:150px;

              .good__catName{
                color: $color;
              }

              .cat__toggle {
                color: $color;
                transform: rotate(0deg);
              }

            }
          }
        }
      }
    }

    .about__talents {
      padding-top: 70px;
      padding-bottom: 70px;
      align-items: center;
      display:flex;
      flex-direction: column;

      .talents__title{
          @include section-content-title-second;
          margin-bottom: 20px;
      }

      .item__visual {
        width: 100%;
        padding-top: 150.50%;
        position:relative;

        .media {
          position:absolute;
          top:0px;
          left: 0px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}