@keyframes blob1 {
  from {opacity: .5}
  to {opacity: 1}
}
@keyframes blob2 {
  from {opacity: .8}
  to {opacity: 1}
}
@keyframes blob3 {
  from {opacity: .4}
  to {opacity: 1}
}
@keyframes blobs {
  from {top: -100vh}
  to {top: 0}
}

.modal.contacts{
	right:0;
	bottom:0;
	z-index:10000;
	display:block;
	background: url('../../img/backgrounds/footer_fx.png'), linear-gradient(136deg,$medium 0%, $dark 100%);
	background-size: 50%, cover;
	color:$white;
	height:0;
	transition:all 250ms ease-in-out;
	overflow-y: auto;
	min-width: 100vw;
	overflow-x: hidden;

	.block{
		pointer-events: none;
		height:calc(100% - 2.375rem);
	}

	.zone-blob{
		padding:2.375rem 0 0 0;
	}

	.blobs{
		z-index:-1;
		top:-100vh;
		left:-32rem;
		width:65.6875rem;


		.point{
			position: absolute;
			top:70%;
			left:57.125%;
			z-index:4;
			display:block;
			width:100%;

			&:before{
				content:'';
				display: inline-block;
				margin:0;
				width:1.4375rem;
				height:1.4375rem;
				border:double 5px  transparent;
				background-image: linear-gradient(123deg, #190932 0%, #A43BED 70%), linear-gradient(123deg,#7225f1 0%, #8e38ed 21%, #a43bed 48%, #bf35c9 74%, #f02988 90%);
				/*border-image-slice: 1;*/
				border-radius:.71875rem;
				box-shadow:0 0 .25rem .125rem rgba(0, 0 , 0 , 0.5);
				background-origin: border-box;
				background-clip: content-box, border-box;
				vertical-align: middle;

				transition: all 125ms ease-in-out;
			}

			span{
				position:absolute;
				top:0;
				left:2rem;

				display: inline-block;
				font-family: $abitare350;
				font-size:.875rem;
				line-height:1.25rem;
				color:$white;
				vertical-align: middle;
				transition: all 350ms ease-in-out;
			}

			&:hover{
				&:before{
					margin:-.28125rem;
					width:2rem;
					height:2rem;
					border-radius:1rem;
				}
			}

		}


		.blob{
			//transition: all 250ms ease-in-out;

			img{
				width:100%;
				height:auto;
			}

			&.layer1{
				top:1.5rem;
				right:0;
				width:100%;
			/*	animation-name: blob1;
  				animation-duration: 2s;
  				animation-iteration-count: infinite;
  				animation-direction: alternate;
  				animation-delay: 4s;*/
			}

			&.layer2{
				top:0;
				right:0;
				width:100%;
		/*		animation-name: blob2;
  				animation-duration: 2s;
  				animation-iteration-count: infinite;
  				animation-direction: alternate;
  				animation-delay: 2s;*/


			}

			&.layer3{
				top:-3rem;
				right:0;
				width:100%;
		/*		animation-name: blob3;
  				animation-duration: 2s;
  				animation-iteration-count: infinite;
  				animation-direction: alternate;*/
			}
		}
	}
	&.show{
		height:100%;

		.blobs{
			&.done{
				.blob{
					top:0;
				}
			}
		}
	}

	.title{
		margin:0 0 1.5rem 0;
		font-family: $abitare1000;
		font-size: 2rem;
		line-height:2.5rem;
		letter-spacing:-.2px;
	}

	.subtitle{
		margin:0 0 2rem 0;
		font-family: $abitare1000;
		font-size: 1.75rem;
		line-height:2rem;
	}

	.introduction{
		margin:0 0 1rem 0;
		font-family: $abitare50;
		font-size: .875rem;
		line-height:1.75rem;
		pointer-events: auto;
	}

	.notices{
		font-family: $abitare50;
		font-size: .75rem;
		line-height:1rem;
		pointer-events: auto;

		a{
			color:$white;
		}
	}

	.socials{
		margin:0 -.5rem 26.875rem  -.5rem;
		pointer-events: auto;

		a{
			padding:0 .5rem;
		}

		.icon{

			color:$white;
			font-size:0;
			line-height:0;

			svg{
				font-size: 1.5rem;
			}
		}
	}

	.btn{

		&.close{
			font-size:1.25rem;
			line-height:1.25rem;
			margin:0 1.5rem 2.5rem 0;
			padding:0;
			background: none;
			border:none;
			outline: none;
			opacity:1;
			transition:all 250ms ease-in-out;
			pointer-events: auto;

			&:hover,
			&:focus,
			&:active{
				outline:none;
				border:none;
				box-shadow: none;
				//transform: rotate(180deg);
			}
		}
	}

	.content{
		margin:0;
		padding:0 1.5rem 3.0625rem 1.5rem;
		min-height:calc(100% - 7.5rem);
	}

	.informations{
		margin:0 0 3rem 0;
		padding:0;
		pointer-events: none;
	}

	.documents{
		/*margin:0 0 2.5rem;*/
		margin:0;
		padding:0;
		pointer-events: auto;
		.doc-list{
			margin:0 -2.5rem;
			padding:0;
		}
		.item{
			margin:0 0 1.5rem 0;
			padding:0 2.5rem;
			list-style: none;
		}

		.download{
			text-align:left;

			.circle{
				position: relative;
				z-index: 0;
				top:0;
				right:0;
				display: inline-block;
				width:3.75rem;
				height:3.75rem;
				vertical-align: middle;
				transform: scaleX(-1);

				.off,
				.on{
					position: absolute;
					top:0;
					right:0;
					transition:all 500ms ease-in-out;
				}

				.off{
					opacity:1;
				}

				.on{
					opacity:0;
				}

			}

			span{
				display: inline-block;
				margin:0 0 0 -2rem;
				vertical-align: middle;
				font-family: $crimson;
				font-size:1.125rem;
				color:$white;
				transition:all 250ms ease-in-out;
			}

			&:hover{
				.circle{
					.off{
						opacity:0;
					}

					.on{
						opacity:1;
					}
				}
			}
		}
	}

	.form{
		margin:0 0 2.5rem;
		padding:0;
		min-height:calc(100% - 2.5rem);
		pointer-events: auto;
		transition:all 250ms ease-in-out;

		&:before{
			opacity:0;
			transition:all 250ms ease-in-out;
		}

		&.sending{
			position: relative;
			pointer-events: none;
			opacity:.5;


			&:before{
				content:'';
				position: absolute;
				top:50%;
				left:50%;
				z-index:3;
				display: block;
				border-radius:1rem;
				height:2rem;
				width:2rem;
				background-color:$white;
				transition:all 250ms ease-in-out;
			}
		}

		&.sent{
			.confirmation{
				display:block;
			}
		}

		.confirmation{
			display:none;
			opacity:0;
			transition: all 250ms ease-in-out;
		}

		.fields{
			margin:0 -.75rem;
			padding:0;
			transition: all 250ms ease-in-out;

			&.hide{
				display:none;
			}
		}

		.label{
			margin:0 0 1.125rem 0;
			font-family: $abitare50;
			font-size: .75rem;
			letter-spacing:1px;
			text-transform: uppercase;
		}

		.field{
			margin:0;
			padding:0 .75rem 2.5rem;

			&:last-child{
				padding:0 .75rem 0;
			}

			.bord{
				display: block;
				margin:0 auto;
				height:2px;
				width:100%;
				background-color:$white;

				&:before{
					content:'';
					display: block;
					margin:0 auto;
					width:100%;
					height:2px;
					background-color:$dark;
					opacity:.25;
					transition: all 250ms ease-in-out;
				}
			}

			.input:focus + .bord,
			.select:focus + .bord,
			.textarea:focus + .bord{
				&:before{
					opacity:0;
				}
			}

			&.error{

				.bord{
					background-color:$color;
				}


				.input,
				.textarea{
					background:rgba($color,.0125);
				}

			}
		}

		.input,
		.textarea{
			margin:0;
			padding:0 0 .4375rem 0;
			width:100%;
			font-family: $abitare50;
			font-size: .875rem;
			line-height:1rem;
			letter-spacing:1px;
			color:$white;
			background:rgba(255,255,255,0);
			outline:none;
			border:none;
			transition: all 250ms ease-in-out;

			&:focus{
				background:rgba(255,255,255,.0125);
			}
		}
		.select{
			font-family: $abitare50;
			font-size: .875rem;
			width:100%;
			border-radius:0;
			border:none;
			outline:none;
			color:$white;
				background:none;
			&:focus{
				color:$white;
				background:none;
			}
		/*	*{
				margin:0;
				padding:0;
				text-indent:0;
			}*/
			&.error{
				background:rgba($color,.0125);
			}
		}
		.select.error + bord{
			background-color:$color;
		}
		.textarea{
			height:7.75rem;
		}

		.file{
			display: none;
		}

		.file-list{
			display: block;
			margin:0 0 .25rem 0;
			min-height: 2rem;
			font-family: $abitare50;
			font-size: .875rem;
			line-height:1rem;
			letter-spacing:1px;
			color:$white;
		}

		.all{
			span{
				color:$white;
			}
		}

		.back{
			.circle{
				.arrow{
					transform: scaleX(1);
				}
			}
		}

		.errors{
			.error{
				margin:0 0 .1125rem 0;
				height:0;
				opacity:0;
				transition: all 250ms ease-in-out;
				overflow: hidden;

				&.show{
					height: auto;
					opacity:1;
				}

				&:last-child{
				margin:0;
				}

				p{
					margin:0;
					padding:0;
					font-family: $abitare50;
					font-size: .75rem;
					line-height:.875rem;
					color:$white;

				}
			}
		}
	}

	.btn{
		&.upload{
			display:none;
			margin:0;
			padding:0;
			border:double 2px transparent;
			background-image: linear-gradient($medium 0%, $dark 100%), linear-gradient(30deg,#f02988 10%, #bf35c9 35%, #a43bed 50%, #8e38ed 65%, #7225f1 90%);
			/*border-image-slice: 1;*/
			border-radius:1.8125rem;
			background-origin: border-box;
			background-clip: content-box, border-box;
			color:$white;

			cursor:pointer;

			&:hover{
				.inside{
					background-color: transparent;
				}
			}

			.inside{
				padding:.875rem 1.75rem;
				border-radius:1.8125rem;
				background-color:$dark;
				transition:all 250ms ease-in-out;
			}

			.icon{
				display: inline-block;
				margin:0 .5rem 0 0;
				font-size:1.125rem;
				line-height:1.125rem;
				vertical-align: middle;
			}

			span{
				display: inline-block;
				font-family: $abitare350;
				font-size:.875rem;
				line-height:1rem;
				vertical-align: middle;
			}
		}
	}
}



@media screen and (min-width: $deviceMd) {
}

@media screen and (max-width: $deviceLg){
	.modal.contacts{

		.btn{

			&.close{
				svg,
				svg *{
					fill:$white!important;
				}
			}
		}
	}
}

@media screen and (min-width: $deviceLg) {


	.modal.contacts{

		.block{
			pointer-events: none;
			height:calc(100% - 6.125rem);
		}

		.zone-blob{
			padding:6.125rem 0 0 0;
		}

		.blobs{
			left:inherit;
			right:44.53125%;
			width:84.097%;


			.point{
				top:70%;
				left:57.125%;

				&:before{
					width:1.4375rem;
					height:1.4375rem;
					border-radius:.71875rem;
				}

				span{
					left:2rem;
					font-size:.875rem;
					line-height:1.25rem;
				}

				&:hover{
					&:before{
						margin:-.28125rem;
						width:2rem;
						height:2rem;
						border-radius:1rem;
					}
				}

			}


			.blob{

				&.layer1{
					top:1.5rem;
					right:0;
					width:100%;
				}

				&.layer2{
					top:0;
					right:0;
					width:100%;


				}

				&.layer3{
					top:-3rem;
					right:0;
					width:100%;
				}
			}
		}
		&.show{
			height:100%;

			.blobs{
				&.done{
					.blob{
						top:0;
					}
				}
			}
		}

		.title{
			margin:0 0 1.25rem 0;
			font-size: 4.0625rem;
			line-height:5rem;
			letter-spacing:-.4px;
		}

		.subtitle{
			margin:0 0 2.5rem 0;
			font-size: 1.75rem;
			line-height:2rem;
		}

		.introduction{
			margin:0 0 1.25rem 0;
			font-size: 1rem;
			line-height:1.75rem;
			pointer-events: auto;
		}

		.socials{
			margin:0 -.5rem;
			pointer-events: auto;

			a{
				padding:0 .5rem;
			}

			.icon{

				svg{
					font-size: 1.25rem;
					transition:all 250ms ease-in-out;

					&:hover{
						opacity:.75;
					}
				}
			}
		}

		.btn{

			&.close{
				font-size:1.25rem;
				line-height:1.25rem;
				margin:0 0 3.75rem 0;
			}
		}

		.content{
			margin:0 -7rem;
			padding:0 0 3.0625rem 0;
			min-height:calc(100% - 7.5rem);
		}

		.informations{
			margin:0 0 2.5rem;
			padding:0 3.5rem 0 7rem;
			pointer-events: none;
		}

		.documents{
			/*margin:0 0 2.5rem;*/
			margin:-10.75rem 0 0 ;
			padding:0 3.5rem 0 7rem;
			pointer-events: auto;
			.doc-list{
				margin:0 -2.5rem;
				padding:0;
			}
			.item{
				margin:0;
				padding:0 2.5rem;
				list-style: none;
			}

			.download{
				text-align:left;

				.circle{
					width:3.75rem;
					height:3.75rem;

				}

				span{
					margin:0 0 0 -2rem;
					font-size:1.125rem;
				}
			}
		}

		.form{
			margin:0 0 2.5rem;
			padding:0 7rem;
			min-height:calc(100% - 2.5rem);
			pointer-events: auto;

			&.sending{
				position: relative;
				pointer-events: none;
				opacity:.5;


				&:before{
					content:'';
					position: absolute;
					top:50%;
					left:50%;
					z-index:3;
					display: block;
					border-radius:1rem;
					height:2rem;
					width:2rem;
					background-color:$white;
					transition:all 250ms ease-in-out;
				}
			}

			.fields{
				margin:0 -.75rem;
			}

			.label{
				margin:0 0 1.125rem 0;
				font-size: .75rem;
				letter-spacing:1px;
			}

			.field{
				margin:0;
				padding:0 .75rem 2.5rem;

				&:last-child{
					padding:0 .75rem 0;
				}

				.bord{
					display: block;
					margin:0 auto;
					height:2px;
					width:100%;
					background-color:$white;

					&:before{
						content:'';
						display: block;
						margin:0 auto;
						width:100%;
						height:2px;
						background-color:$dark;
						opacity:.25;
						transition: all 250ms ease-in-out;
					}
				}

				.input:focus + .bord,
				.select:focus + .bord,
				.textarea:focus + .bord{
					&:before{
						opacity:0;
					}
				}

				&.error{

					.bord{
						background-color:$color;
					}


					.input,
					.select,
					.textarea{
						background:rgba($color,.0125);
					}

				}
			}

			.input,
			.select,
			.textarea{
				padding:0 0 .4375rem 0;
				font-size: .875rem;
				line-height:1rem;
				letter-spacing:1px;
			}
			.textarea{
				height:7.75rem;
			}


			.file-list{
				margin:0 0 .25rem 0;
				min-height: 2rem;
				font-size: .875rem;
				line-height:1rem;
				letter-spacing:1px;
			}

			.errors{
				.error{
					margin:0 0 .1125rem 0;

					&.show{
						height: auto;
					}

					&:last-child{
					margin:0;
					}

					p{
						font-size: .75rem;
						line-height:.875rem;

					}
				}
			}
		}

		.btn{
			&.upload{
				border-radius:1.8125rem;

				.inside{
					padding:.875rem 1.75rem;
					border-radius:1.8125rem;
				}

				.icon{
					margin:0 .5rem 0 0;
					font-size:1.125rem;
					line-height:1.125rem;
				}

				span{
					font-size:.875rem;
					line-height:1rem;
				}
			}
		}
	}
}

.menu-burger.menu-burger__contact {
	position: relative;
	z-index:2000;
	margin-right:1.5rem;
	font-size:0;
	line-height:0;

	.menu-burger-btn{
		position:relative;
		margin:1px 0;
		padding:0;
		width:1.125rem;
		height:1rem;
		font-size:0;
		line-height:0;
		text-align:right;
		border:none;
		outline:none;
		background:none;

		.line{
			display:inline-block;
			margin:2px 0;
			height:2px;
			font-size:0;
			background-color:$white;
			transition:all 250ms ease-in-out;
		}

		.line-1,
		.line-3{
			width:1.125rem;
			opacity:0;
		}
		.line-2{
			width:.525rem;
			opacity:0;
		}
	}

	&.active{
		.line{
			position: absolute;
			top:50%;
			left:0;
		}
		.line-1{
			transform:rotate(-45deg);
			opacity:1;
		}
		.line-2{
			width:1.125rem;
			transform:rotate(45deg);
			opacity:1;
		}
		.line-3{
			width:0;
			opacity:0;
		}
	}
}