.contactPage {
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;


  .contact__container {
    width: 100vw;
    /*height: 100vh;*/
    min-height: 100vh;
    /*max-height: 100vh;*/
    background-color: $dark-blue;
    background-image: url('/ui/backgrounds/hero__shader.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: grid;
    grid-template-columns: repeat(12, 60px);
    justify-content: space-between;
    padding-left: 8%;
    padding-right: 8%;
    grid-template-rows: 20vh 1fr 115px;
    position:relative;

    .socials__list {
      position: absolute;
      right: 8%;
      bottom: 40px;
      height: 20px;
      color: white;
    }

    .contact__wrapper {
      grid-column: 1/13;
      grid-row: 2/3;
      display: grid;
      grid-template-columns: repeat(12, 60px);
      justify-content: space-between;
      padding-top: 20px;

      .contact__content {
        grid-column: 1/6;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 0px;

        .contact__title {
          @include section-content-title;
          color: white;
          margin-bottom: 12px;
          font-size: 50px;
        }

        .contact__description {
          @include section-content-paragraphe;
          color: white;
          margin-bottom: 58px;
        }

        .contact__adress {
          width: 200px;
          display:flex;
          align-items: flex-start;

          .circle {
            width: 23px;
            height: 23px;
            margin-right: 20px;
          }

          .adress__description {
            @include section-content-paragraphe;
            color: white;
            margin-left: 0px;
            display: block;
          }
        }

        .contact__legals {
          @include section-keydata-xs;
          display: none;
          &.desktop {
            display:block;
            p{
              font-size:12px;
              line-height:22px;
            }

          }
        }
      }

      .contact__form {
        grid-column: 7/12;
        padding-top: 0px;

        .form__row {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 40px;

          &:last-child {
            margin-bottom: 58px;
          }
          .form__input {
            width: 100%;
            display :block;
            &:first-child {
              margin-right: 40px;
            }
          }
        }

        .form__select {

          .select__title {
            @include section-content-step;
            text-transform: uppercase;
          }

          .select__container {
            width: 100%;
            height: 45px;
            position: relative;
            margin-top: 11px;
            margin-left: 0px;

            .select__value {
              width: 100%;
              height: 100%;
              position:absolute;
              top:0px;
              left: 0px;
              z-index: 1;
              border-bottom: 1px solid $cleargray;

              .chevron {
                position: absolute;
                width: 16px;
                height: 10px;
                top: 40%;
                transform: translateY(-50%);
                right: 0px;
              }

              span {
                color: white;
                font-size: 16px;
                line-height: 28px;
              }
            }
          }

          .select__item {
            width: 100%;
            height: 100%;
            position:absolute;
            top:0px;
            left: 0px;
            z-index: 2;
            appearance: none;
            background-color: transparent;
            border: none;
            padding: 0 0 0 0;
            margin: 0;
            width: 100%;
            font-family: inherit;
            font-size: inherit;
            cursor: inherit;
            line-height: inherit;
            outline: 0;

            color: white;
            font-size: 16px;
            line-height: 28px;
          }
        }

        .form__message {
          margin-top: 50px;
          position: relative;

          .resizer {
            position: absolute;
            bottom:8px;
            right: 0px;
          }
          .select__title {
            @include section-content-step;
            text-transform: uppercase;
          }

          .textarea__form::-webkit-resizer {
            display: none;
          }

          .textarea__form {
            width: 100%;
            height: 120px;
            margin-top: 20px;
            background-color: transparent;
            border:0px;
            outline: 0px;
            padding:4px;

            border-bottom: 1px solid $cleargray;
            @include section-content-paragraphe;
            color: white;
          }
        }

        .info {
          @include section-content-step;
          display: block;
          margin-top: 15px;
        }

        .buttonCircle {
          margin-top: 0px;
          text-align: center;
        }

        .contact__legals.mobile {
          display: none;
        }

        .form__input {
          background-color: transparent;
          border:0px;
          outline:0px;
          border-bottom: 1px solid $cleargray;
          color: white;
          padding: 5px 0px;
          padding-bottom: 12px;
        }

        .form__input::placeholder {
          color: white;
          font-size: 16px;
          line-height: 28px;
        }
      }



    }

  }
}

@media screen and ( max-width: $deviceL ) {

  .contactPage {
    width: 100vw;
    height: auto;
    min-height: 100vh;
    display: flex;


    .contact__container {
      width: 100vw;
      height: auto;
      min-height: 100vh;
      max-height: none;
      background-color: $dark-blue;
      background-image: url('/ui/backgrounds/hero__shader.png');
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      grid-template-columns: none;
      flex-direction:column;
      justify-content: space-between;
      padding-left: 8%;
      padding-right: 8%;
      grid-template-rows: none;
      position:relative;

      .socials__list {
        position: absolute;
        right: 8%;
        bottom: 40px;
        height: 20px;
        color: white;
      }

      .contact__wrapper {
        grid-column: none;
        grid-row: none;
        display: flex;
        grid-template-columns: none;
        flex-direction:column;
        justify-content: space-between;
        padding-top: 100px;

        .contact__content {
          grid-column: 1/6;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-top: 0px;

          .contact__title {
            @include mob-title-2;
            color: white;
            margin-bottom: 12px;
            font-size: 50px;
          }

          .contact__description {
            @include section-content-paragraphe;
            color: white;
            margin-bottom: 58px;
          }

          .contact__adress {
            width: 200px;
            display:flex;
            align-items: flex-start;

            .circle {
              width: 23px;
              height: 23px;
              margin-right: 20px;
            }

            .adress__description {
              @include section-content-paragraphe;
              color: white;
              margin-left: 0px;
              display: block;
            }
          }

          .contact__legals {
            margin-top: 40px;
            @include section-keydata-xs;
            display: none;
            &.desktop {
              display:none;
            }
          }
        }

        .contact__form {
          grid-column: 7/12;
          padding-top: 0px;
          margin-top: 40px;

          .form__row {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            margin-bottom: 0px;

            &:last-child {
              margin-bottom: 58px;
            }
            .form__input {
              width: 100%;
              display :block;
              margin-bottom: 40px;
              &:first-child {
                margin-right: 40px;
              }
            }
          }

          .form__select {

            .select__title {
              @include section-content-step;
              text-transform: uppercase;
            }

            .select__container {
              width: 100%;
              height: 45px;
              position: relative;
              margin-top: 11px;
              margin-left: 0px;

              .select__value {
                width: 100%;
                height: 100%;
                position:absolute;
                top:0px;
                left: 0px;
                z-index: 1;
                border-bottom: 1px solid $cleargray;

                .chevron {
                  position: absolute;
                  width: 16px;
                  height: 10px;
                  top: 40%;
                  transform: translateY(-50%);
                  right: 0px;
                }

                span {
                  color: white;
                  font-size: 16px;
                  line-height: 28px;
                }
              }
            }

            .select__item {
              width: 100%;
              height: 100%;
              position:absolute;
              top:0px;
              left: 0px;
              z-index: 2;
              appearance: none;
              background-color: transparent;
              border: none;
              padding: 0 0 0 0;
              margin: 0;
              width: 100%;
              font-family: inherit;
              font-size: inherit;
              cursor: inherit;
              line-height: inherit;
              outline: 0;

              color: white;
              font-size: 16px;
              line-height: 28px;
            }
          }

          .form__message {
            margin-top: 50px;
            position: relative;

            .resizer {
              position: absolute;
              bottom:8px;
              right: 0px;
            }
            .select__title {
              @include section-content-step;
              text-transform: uppercase;
            }

            .textarea__form::-webkit-resizer {
              display: none;
            }

            .textarea__form {
              width: 100%;
              height: 120px;
              margin-top: 20px;
              background-color: transparent;
              border:0px;
              outline: 0px;
              padding:4px;

              border-bottom: 1px solid $cleargray;
              @include section-content-paragraphe;
              color: white;
            }
          }

          .info {
            @include section-content-step;
            display: block;
            margin-top: 15px;
          }

          .buttonCircle {
            margin-top: 40px;
            text-align: center;
          }

          .contact__legals.mobile {
            display:block;
            margin-top: 40px;
            margin-bottom: 100px;
            p{
              font-size:12px;
              line-height:22px;
            }
          }

          .form__input {
            background-color: transparent;
            border:0px;
            outline:0px;
            border-bottom: 1px solid $cleargray;
            color: white;
            padding: 5px 0px;
            padding-bottom: 12px;
          }

          .form__input::placeholder {
            color: white;
            font-size: 16px;
            line-height: 28px;
          }
        }



      }

    }
  }
}