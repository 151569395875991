


.blobs__header {
  width: 100vw;
  margin: 0px;
  display: block;
  position: absolute;
  height: 13.3rem;
  left: 0px;

  @media screen and ( max-width:$deviceMd) {
      //display:none;
  }

  &.hid {
    display: none;
  }

  svg {
    overflow: visible !important;
  }

  .blobintro1grad {
    fill: url(#BlobIntro1Grad);
  }

  .blobintro1grad {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: url(#BlobIntro1Grad);
    fill-opacity: 0.1;
  }

  .blobintro3grad {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: url(#BlobIntro3Grad);
    fill-opacity: 0.15;
  }

  @media screen and (max-width: $deviceL) {
    .blobs {
      .blob {
        &.blob-1 {
          left: -9%;
        }

        &.blob-2 {
          left: -10%;
        }

        &.blob-3 {
          left: -11%;
        }
      }
    }
  }


  .blobs {
    z-index: -1;
    top: 0;
    left: calc(20.47% + 4.9375rem);
    width: 115%;

    .blob {

      &.blob-1 {
        left: 1%;
        width: 96.78%;
        @media screen and (max-width: $deviceL) {
          left:-9%;
        }
      }

      &.blob-2 {
        left: 0%;
        width: 98.99%;
        @media screen and (max-width: $deviceL) {
          left:-10%;
        }

      }

      &.blob-3 {
        left: -1%;
        width: 100%;
        @media screen and (max-width: $deviceL) {
          left:-11%;
        }
      }
    }
  }

}