.brands-homepage {
	//padding-top: 7rem;


	.brands__introduction {
		padding-top: 138px;
		padding-bottom: 129px;
		p {
			grid-column: 7/12;
			&.section__title {
				grid-column: 1/5;
			}
		}
	}

	.brands__filter {
		border-bottom: 2px solid $lightGrey;
		.filters {
			grid-column: 1/13;
			margin-bottom: 34px;
		}
	}

	.filters-test{
		@media screen and ( min-width:980px) {
			display:none;
		}

		position: relative;
		z-index:10;
		margin:0 0 1.25rem 0;
		padding:0;
		min-height:3.125rem;

		.btn{
			position: absolute;
			z-index:1;
			top:0;
			right:0;
			display:block;
			margin:0;
			padding:0;
			width:100%;
			font-size:0;
			white-space:nowrap;
			border:double 1px transparent;
			/*border-image-slice: 1;*/
			border-radius:1.25rem;
			background-origin: border-box;
			background-clip: content-box, border-box;
			cursor:pointer;

			@media screen and ( max-width:980px) {
				background-image: linear-gradient($medium 0%, $dark 100%), linear-gradient(30deg,#f02988 10%, #bf35c9 35%, #a43bed 50%, #8e38ed 65%, #7225f1 90%);
			}

		}

		&:not(.unfold){
			.btn{
			}
		}

		.label-tous {
			@media screen and ( min-width:980px) {
				padding: .25rem 1.625rem;
			}
			@media screen and ( max-width:980px) {
				padding:0;
			}
		}

		.inside{
			display: block;
			padding:.5rem 0;
			border-radius:1.1875rem;
			font-size:.875rem;
			line-height:1rem;
			text-align:left;
			background-color:$white;
			width:100%;
			white-space:nowrap;
			overflow: hidden;
			transition:all 250ms ease-in-out;
			box-shadow: 0 0 .5rem rgba(0,0,0,0);

			@media screen and ( min-width:980px) {
				display:flex;
				align-items:center;
				justify-content:flex-start;
			}
		}

		.icon{
			margin-left:.5rem;
			font-size:.75rem;
			vertical-align: middle;
			transition:all 250ms ease-in-out;

			@media screen and ( min-width:980px) {
				display:none;
			}
		}

		span{
			font-family: $abitare350;
			@media screen and ( min-width:980px) {
				font-size: 1rem;
				line-height: 1.75rem;
			}
		}

		ul{
			margin:0;
			padding:0;
		}

		li{
			margin:0;
			padding:0;
			list-style: none;
		}

		.filters-selected{

			@media screen and ( max-width: 980px) {
				padding: .25rem 1.625rem;
				white-space: nowrap;
				color: $color;

				span {
					margin-right: auto;
				}
			}
		}

		.filters-select{

			@media screen and ( min-width: 980px) {
				display:flex;
				align-items:center;
				justify-content:flex-start;
			}

			@media screen and ( max-width: 980px) {
				min-height: 0;
				max-height: 0;
				overflow: hidden;
			}
		}

		&.unfold{

			.filters-selected{
				.icon{
					transform: rotate(-180deg);
				}
			}

			.filters-select{
				min-height:1rem;
				max-height:30rem;
			}

		}

		.filter-btn{
			display: block;
			margin:0;
			padding:.25rem 1.625rem;
			width:100%;
			background:none;
			border:none;
			outline:none;
			text-align:left;
			cursor: pointer;

			span{
				white-space: nowrap;
			}

			&.active{
				display:none;
			}
		}
	}

	.presentation{
		border-bottom: 2px solid $lightGrey;
    	grid-template-columns: unset;
    	justify-content: unset;

		.section__title{
			margin-right:auto;
			margin-bottom:30px;
			width: 44%;
			font-size:36px;
			line-height:42px;
		}

		.section__description{
			margin-bottom:30px;
			width: 100%;
			font-size:16px;
			line-height:22px;
		}


	}

	.filters{
		@media screen and ( max-width:980px) {
			display:none;
		}

		padding:0;
		li{
			margin:0;
			padding:0 .5rem;
			list-style: none;
		}

		.filter-btn{
			display: inline-block;
			margin:0;
			padding:0;
			background:none;
			border:none;
			outline:none;

			&:before{
				content:'';
				display: inline-block;
				margin:0 .5rem 0 0;
				width:10px;
				height:10px;
				border:double 1px  transparent;
				background-image: linear-gradient(white, white), linear-gradient(30deg,#f02988 10%, #bf35c9 35%, #a43bed 50%, #8e38ed 65%, #7225f1 90%);
				/*border-image-slice: 1;*/
				border-radius:.4375rem;
				background-origin: border-box;
				background-clip: content-box, border-box;
				vertical-align: middle;
				opacity:0;
				transition: all 350ms ease-in-out;
			}

			span{
				display: inline-block;
				font-family: $abitare50;
				font-size:1rem;
				line-height:1.75rem;
				vertical-align: middle;
				transition: all 350ms ease-in-out;
			}

			&.active{

				&:before{
					border:double 2px  transparent;
					opacity:1;
				}
				span{
					color:$color
				}

			}
		}
	}

	.list{
		margin:0 -2.3125rem 4.25rem;

		.item{
			margin:0 0 3.125rem 0;
			padding:0 2.3125rem;
			color:$dark;
			transition:all 250ms ease-in-out;
			&:before{
				content:'';
				display: inline-block;
				height:100%;
				width:0;
				vertical-align: middle;
			}

			.logo,
			svg{
				display: inline-block;
				margin:0;
				width:100%;
				vertical-align:middle;
				font-size:2rem;
			}
			.logo div{
				display: block;
				width:100%;
			}

			&.hide{
				display: none;
			}
		}
	}

	.brands-list {
		margin-top: 100px;
		display:flex;
		flex-direction:column;

		.col-brands {
			margin-left: 34px;
			margin-right: 34px;
			display:grid;
			grid-template-columns: repeat(7, 100px);
			justify-content: space-between;
			margin-bottom: 90px;

			.item-brand {
				width: 100%;
				height: 100%;
				display:flex;
				align-items: center;
				justify-content: center;
				color: $dark;
				transition: 100ms ease-in color;

				.logo {
					width: 100%;

					svg {
						width: 100%;

					}
				}

				&:hover {
					color: $color;
					transition: 100ms ease-in color;
				}
			}
		}
	}

}

@media screen and ( min-width: $deviceXl  ) {
	.brands-homepage .brands__introduction p {
		grid-column: 8/12;
	}
}

@media screen and (max-width: $deviceL) {
	.brands-homepage{
		//padding-top: 7rem;


		.brands__introduction {
			padding-top: 74px;
			padding-bottom: 74px;
			grid-template-rows: unset;
			display: flex;
			flex-direction: column;
			height: auto;

			.section__title {
				margin-bottom: 35px;
			}

			p {
				grid-column: unset;
				&.section__title {
					grid-column: unset;
				}
			}
		}

		.presentation{

			margin-bottom:40px;
			
			.section__title{
				margin-bottom:20px;
				width: 100%;
				font-size:22px;
				line-height:28px;
			}

			.section__description{
				margin-bottom:20px;
				font-size:12px;
				line-height:26px;
			}


		}

		.brands__filter {
			display: none;

			.filters {
				grid-column: unset;
				margin-bottom: 34px;
			}
		}


		.filters-test{
			display: none;
		}

		.filters{
			@media screen and ( max-width:980px) {
				display:none;
			}

			padding:0;
			li{
				margin:0;
				padding:0 .5rem;
				list-style: none;
			}

			.filter-btn{
				display: inline-block;
				margin:0;
				padding:0;
				background:none;
				border:none;
				outline:none;

				&:before{
					content:'';
					display: inline-block;
					margin:0 .5rem 0 0;
					width:.875rem;
					height:.875rem;
					border:double 1px  transparent;
					background-image: linear-gradient(white, white), linear-gradient(30deg,#f02988 10%, #bf35c9 35%, #a43bed 50%, #8e38ed 65%, #7225f1 90%);
					/*border-image-slice: 1;*/
					border-radius:.4375rem;
					background-origin: border-box;
					background-clip: content-box, border-box;
					vertical-align: middle;
					opacity:0;
					transition: all 350ms ease-in-out;
				}

				span{
					display: inline-block;
					font-family: $abitare50;
					font-size:1rem;
					line-height:1.75rem;
					vertical-align: middle;
					transition: all 350ms ease-in-out;
				}

				&.active{

					&:before{
						border:double 4px  transparent;
						opacity:1;
					}
					span{
						color:$color
					}

				}
			}
		}

		.list{
			margin:0 -2.3125rem 4.25rem;

			.item{
				margin:0 0 3.125rem 0;
				padding:0 2.3125rem;
				color:$dark;
				transition:all 250ms ease-in-out;
				&:before{
					content:'';
					display: inline-block;
					height:100%;
					width:0;
					vertical-align: middle;
				}

				.logo,
				svg{
					display: inline-block;
					margin:0;
					width:100%;
					vertical-align:middle;
					font-size:2rem;
				}
				.logo div{
					display: block;
					width:100%;
				}

				&.hide{
					display: none;
				}
			}
		}

		.brands-list {
			margin-top: 0px;
			display:flex;
			flex-direction:column;
			margin-left: 24px;
			margin-right: 24px;


			.col-brands {
				margin-left: 34px;
				margin-right: 34px;
				display:grid;
				grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
				grid-column-gap: 40px;
				grid-row-gap: 80px;
				margin-bottom: 40px;
				justify-content: space-between;

				.item-brand {
					width: 100%;
					height: 100%;
					display:flex;
					align-items: center;
					justify-content: center;
					color: $dark;
					transition: 100ms ease-in color;

					.logo {
						width: 100%;

						svg {
							width: 100%;

						}
					}

					&:hover {
						color: $color;
						transition: 100ms ease-in color;
					}
				}
			}
		}

	}
}






