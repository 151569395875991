.universes-detail{

	.hero__page {
		.hero__container .hero__content .hero__main .hero__title_container .hero__surtitle p{
			font-size: 32px;
			line-height: 38px;
			margin-bottom: 20px;
			text-transform: capitalize;
		}
	}

	.blobs{
		z-index:-1;
		top:0;
		left:-3rem;
		width:52rem;

		.blob{

			&.layer1{
				left:.87%;
				width:99.13%;
			}

			&.layer2{
				left:.58%;
				width:99.42%;

			}

			&.layer3{
				left:0;
				width:100%;

			}
		}

	}

	.steps{
		&.header{
			margin-bottom:1.8125rem;
		}
	}

	.header{
		.navigation{
			margin:.84375rem 0 0 0;
			padding:0;

		}
	}

	.keyfigures{
		position:relative;
		
		.blobs{
			z-index:-1;
			top:-20rem;
			right:0;
			left:initial;
			margin:0;
			width:100%;


			.blob{
				left:initial;
				right:0;

				&.layer1{
					width:100%;
				}

				&.layer2{
					width:100%;
				}

				&.layer3{
					width:100%;
				}
			}
		}
	}
	
	.brands{
		.head{
			margin:0 0 1.5rem 0;
			.name{
				font-family: $abitare800;
				color:$color;
			}
		}
		.list{
			margin:0 -1.75rem;

			.item{

				margin:0 0 .875rem 0;
				padding:0 .875rem;
				font-size:0;
				line-height:0;
				color:$dark;

				.logo{
					display: block;
					margin:0;
					font-size:0;
					line-height:0;
				}
				&:nth-child(even){
					margin-top:0;
				}
			}
		}
	}

	.contacts{
		margin:0 0 5rem 0;
		font-family: $abitare350;
		font-size:1.125rem;
		color:$dark;

		.circle{
			.arrow{
				margin:-.425rem 0 0 0rem;
				height:1rem;
				width:1.75rem;
				font-size:0;
			}
		}
		&:hover{
			.circle{
				.arrow{
					margin:-.425rem 0 0 -.8625rem;
				}
			}
		}
	}

	.univers__header {
		background-color: $lightGrey;
		padding-bottom: 0px;

		.introduction {
			padding-top: 0px;
			padding-bottom: 154px;
			border-bottom: 2px solid rgba(186, 181, 193, 0.2);
		}

		.univers-keyfigures {
			padding-top: 100px;
			padding-bottom: 200px;
			border-bottom: 2px solid $lightGrey;
		}

	}

	.presentation {
		padding-top: 115px;
		padding-bottom: 90px;
		border: 0px;

		.details-contact {
			grid-column: 1/6;
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
			grid-column-gap: 20px;
			grid-row-gap: 50px;

			.contact-item {
				margin-bottom: 0px;

				&:first-child {
					margin-right: 10px;
				}

				.contact-position {
					text-transform: uppercase;
					margin-bottom: 7px;
					@include section-content-step;
				}

				.contact-name {
					color: $color;
				}

				.contact-email {
					color: $dark;
					text-decoration: underline;
				}
			}
		}

		.section__title {
			text-transform: uppercase;
			color: $chaire;
			margin-bottom: 51px;
			-webkit-background-clip: unset;
			background: unset;
			-webkit-text-fill-color: unset;
		}

		.section__content {
			grid-column: 7/12;
			grid-row: 1/3;
		}
	}

	.univers-details-l {
		padding-top: 25px;
		padding-top: 25px;



		.details-expertises {
			grid-column: 1/13;
			display: grid;
			grid-template-columns: repeat(12, 60px);
			justify-content: space-between;


			.expertise-item {
				grid-column: span 6;
				margin-right: 60px;

				margin-bottom: 46px;
				&:last-child {
					margin-bottom: 0px;
				}
				.expertise-name {
					@include section-content-step;
					text-transform: uppercase;
					margin-bottom: 7px;
					line-height: 28px;
					color: $color;
				}

				.expertise-description {
					margin-bottom: 22px;
				}

				.expertise__link {
					$color: $dark;
					a, p {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.univers-entites-title {
		padding-top: 140px;
		padding-bottom: 80px;
		border: 0px;
	}

	.univers-entites-list {
		padding-top: 30px;
		padding-bottom: 130px;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(8% , 1fr));
		grid-column-gap: 80px;
		grid-row-gap: 80px;
		align-items: center;
		border-bottom: 2px solid $lightGrey;

		.entity-item {
			width: 100%;
			height: auto;
			color: $dark;
			transition: 300ms ease-in-out color;

			&:hover {
				color: $color;
				transition: 300ms ease-in-out color;
				cursor: pointer;
			}

			svg {
				width: 100%;
				height: auto;
			}
		}

	}


	.latest__devices {
		padding: 150px 0px;
	}

	.seasonal__offer {
		padding-top: 100px;
		padding-bottom: 110px;
		background-color: $lightGrey;
	}
}

@media screen and ( min-width: $deviceXl  ) {
	.universes-detail .introduction .section__content {
		grid-column: 8/12;
		margin-left: 0px;
		margin-right: 0px;
	}
}

@media screen and (max-width: $deviceL) {
	.universes-detail{
		.blobs{
			z-index:-1;
			top:0;
			left:-3rem;
			width:52rem;

			.blob{

				&.layer1{
					left:.87%;
					width:99.13%;
				}

				&.layer2{
					left:.58%;
					width:99.42%;

				}

				&.layer3{
					left:0;
					width:100%;

				}
			}

		}

		.steps{
			&.header{
				margin-bottom:1.8125rem;
			}
		}

		.header{
			.navigation{
				margin:.84375rem 0 0 0;
				padding:0;

			}
		}

		.keyfigures{
			position:relative;

			.blobs{
				z-index:-1;
				top:-20rem;
				right:0;
				left:initial;
				margin:0;
				width:100%;


				.blob{
					left:initial;
					right:0;

					&.layer1{
						width:100%;
					}

					&.layer2{
						width:100%;
					}

					&.layer3{
						width:100%;
					}
				}
			}
		}

		.brands{
			.head{
				margin:0 0 1.5rem 0;
				.name{
					font-family: $abitare800;
					color:$color;
				}
			}
			.list{
				margin:0 -1.75rem;

				.item{

					margin:0 0 .875rem 0;
					padding:0 .875rem;
					font-size:0;
					line-height:0;
					color:$dark;

					.logo{
						display: block;
						margin:0;
						font-size:0;
						line-height:0;
					}
					&:nth-child(even){
						margin-top:0;
					}
				}
			}
		}

		.contacts{
			margin:0 0 5rem 0;
			font-family: $abitare350;
			font-size:1.125rem;
			color:$dark;

			.circle{
				.arrow{
					margin:-.425rem 0 0 0rem;
					height:1rem;
					width:1.75rem;
					font-size:0;
				}
			}
			&:hover{
				.circle{
					.arrow{
						margin:-.425rem 0 0 -.8625rem;
					}
				}
			}
		}

		.univers__header {
			background-color: $lightGrey;
			padding-bottom: 70px;

			.univers-keyfigures {
				padding-top: 70px;
				padding-bottom: 70px;
				border-bottom: 2px solid $lightGrey;
				display: flex;
				flex-direction: column;

				.keydatas {
					display: flex;
					flex-direction: column;
					.keydata {
						margin:0px;
						margin-bottom: 30px;
						width: 100%;
					}
				}
			}

			.introduction, .presentation {
				display: flex;
				flex-direction: column;
				padding-top: 70px;
				padding-bottom: 70px;

				.section__title {
					margin-bottom: 35px;
				}

				.step__content {
					margin-left: 0px;
				}

			}

			.univers-details {
				padding-top: 70px;
				padding-top: 70px;
				display: flex;
				flex-direction: column;

				.details-contact {
					grid-column: unset;
					margin-top: 0px;

					.contact-item {
						margin-bottom: 30px;

						&:last-child {
							margin-bottom: 0px;
						}

						.contact-position {
							text-transform: uppercase;
							margin-bottom: 7px;
						}

						.contact-name {
							color: $color;
						}

						.contact-email {
							color: $dark;
							text-decoration: underline;
						}
					}
				}

				.details-expertises {
					grid-column: unset;
					margin-left: 0px;
					margin-right: 0px;
					margin-top: 30px;
					display: grid;
					grid-template-columns: repeat(4, 60px);
					justify-content: space-between;
					height: fit-content;

					.expertise-item {
						grid-column: span 2;
						@include articlelist-seasonal-brand;
						height: 30px;
					}
				}
			}
		}

		.univers-entites-title {
			padding-top: 70px;
			padding-bottom: 70px;
			border: 0px;
			display: flex;
			flex-direction: column;

			.section__title {
				margin-bottom: 35px;
			}

			.step__content {
				margin-left: 0px;
			}
		}

		.univers-entites-list {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(12.5vw, 1fr));
			grid-column-gap: 40px;
			grid-row-gap: 80px;
			margin-bottom: 40px;

			.entity-item {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #190932;
				transition: 100ms ease-in color;

				&:hover {
					color: $color;
					transition: 300ms ease-in-out color;
					cursor: pointer;
				}

				svg {
					width: 100%;
					height: auto;
				}
			}

		}

		.presentation {
			padding-top: 70px;
			padding-bottom: 70px;
			border-bottom: 0px;
			border-top: 0px;
			flex-direction: column;

			grid-template-rows: unset;
			display: grid;
			grid-template-columns: 1fr;


			.section__title {
				text-transform: uppercase;
				color: $chaire;
				margin-bottom: 35px;

				grid-column: 1/2;
				grid-row: 1/2;
			}

			.step__content {
				grid-column: 1/2;
				grid-row: 2/3;
				margin: 0px;
			}

			.details-contact {
				flex-direction: column;
				margin-top: 52px;
			}
		}

		.univers-details-l {
			padding-top: 25px;
			padding-top: 25px;



			.details-expertises {
				grid-column: 1/13;
				display: grid;
				grid-template-columns: repeat(12, 60px);
				justify-content: space-between;

				display:flex;
				flex-direction: column;


				.expertise-item {
					grid-column: span 6;
					margin-right: 60px;

					margin-bottom: 46px;
					&:last-child {
						margin-bottom: 0px;
					}
					.expertise-name {
						@include section-content-step;
						text-transform: uppercase;
						margin-bottom: 7px;
						line-height: 28px;
						color: $color;
					}

					.expertise-description {
						margin-bottom: 22px;
					}

					.expertise__link {
						$color: $dark;
						a, p {
							text-decoration: underline;
						}
					}
				}
			}
		}


		.univers-keyfigures {
			padding-top: 70px;
			padding-bottom: 70px;
			border-bottom: 2px solid $lightGrey;
			display: flex;
			flex-direction: column;

			.keydatas {
				display: flex;
				flex-direction: column;
				.keydata {
					margin:0px;
					margin-bottom: 30px;
					width: 100%;
				}
			}
		}

		.latest__devices {
			padding: 70px 0px;
		}

		.seasonal__offer {
			padding-top: 70px;
			padding-bottom: 70px;
			background-color: $lightGrey;
		}
	}
}
