.page.expertises-detail {
  .hero__surtitle__media {
    width: 126px;
    height: 126px;

    .module.media {
      .media__container {
        .media-svg {
          display:flex;
          height: 126px;
          width: 126px;
          padding:20px;
          border-radius:50%;
          background-color: white;
          justify-content:center;
          align-items: center;
          div {
            height: 42px;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .detail__close {
    background-color: $lightGrey;
  }

  .introduction {
    padding-top: 25px;
    padding-bottom: 110px;
    background-color: $lightGrey;

    position: relative;

    &:after {
      content: '';
      position:absolute;
      bottom:0px;
      left: 7.63vw;
      right: 7.63vw;
      background-color: $cleargray;
      height: 1px;
      opacity: 0.2;
    }
  }

  .keyfigures {
    padding-top: 100px;
    padding-bottom: 75px;
    border-bottom: 2px solid $lightGrey;
    background-color: $lightGrey;
  }

  .exp__nav {
    display: grid;
    grid-template-columns:  1fr 1fr;
    grid-column-gap: 60px;
    align-items: center;
    justify-content: space-around;
    padding: 50px 0px;
    width:50.7%;
    margin:0 auto;

    .nav-item {
      display:flex;
      align-items: center;
      justify-content: center;
    }

    span {
      color: $dark;
      max-width:300px;
    }
  }

  .latest__devices {
    padding-top: 90px;
    padding-bottom: 150px;
  }

  .seasonal__offer {
    padding-top: 100px;
    padding-bottom: 110px;
    background-color: $lightGrey;
  }

  .expertise__services {
    padding-top: 110px;
    padding-bottom: 90px;

    .services__subtitle {
      @include section-content-step;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 10px;
    }

    .services__title {
      @include section-content-title;
      margin: 0px 20%;
      text-align: center;
      margin-bottom: 10px;
    }

    .expertise__servicesList {

      .service__category {
        display: grid;
        grid-template-columns: repeat(12, 60px);
        justify-content: space-between;
        padding-bottom: 64px;
        border-bottom: 2px solid $lightGrey;
        padding-top: 64px;

        .category__title {

          grid-column: 1/5;
          @include expertise-list-title;
          text-transform: uppercase;

        }

        .category__list {

          grid-column: 7/12;

          .category__description {

            @include expertise-list-title;
            /*text-transform: uppercase;*/
            margin-bottom: 7px;
          }

          .category__items {

            .service__item {

              @include articlelist-seasonal-brand;
              margin-bottom: 28px;
              position: relative;

              &:before {
                position: absolute;
                content: '';
                top:50%;
                transform: translateY(-50%);
                left:-30px;
                width: 8px;
                height: 8px;
                background-color: $color;
                border-radius: 50%;
              }

            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $deviceL) {
  .page.expertises-detail {
    .hero__surtitle__media {
      width: 100px;
      height: 100px;
    }

    .introduction {
      padding-top: 70px;
      padding-bottom: 70px;
      display: flex;
      flex-direction: column;

      .section__title {
        margin-bottom: 35px;
      }

      .step__content {
        margin-left: 0px;
      }
    }

    .keyfigures {
      padding-top: 70px;
      padding-bottom: 70px;
      border-bottom: 2px solid $lightGrey;
      display: flex;
      flex-direction: column;

      .keydatas {
        display: flex;
        flex-direction: column;

        .keydata {
          width: 100%;
          margin: 0px;
          margin-bottom: 30px;
        }
      }
    }

    .exp__nav {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      grid-template-columns: unset;
      grid-column-gap:unset;
      padding: 50px 0px;

      .nav-item {
        display:flex;
        align-items: center;
        justify-content: center;
        margin:10px 0px;

        .buttonCircle {

          span {
            max-width: 85%;
          }
        }
      }

      span {
        color: $dark;
      }
    }

    .latest__devices {
      padding-top: 70px;
      padding-bottom: 70px;
    }

    .seasonal__offer {
      padding-top: 70px;
      padding-bottom: 70px;
      background-color: $lightGrey;
    }

    .expertise__services {
      padding-top: 70px;
      padding-bottom: 0px;

      .services__subtitle {
        @include section-content-step;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 10px;
      }

      .services__title {
        @include mob-title-2;
        margin: 0px 24px;
        text-align: center;
        margin-bottom: 10px;
      }

      .expertise__servicesList {

        .service__category {
          display: flex;
          flex-direction: column;
          padding-bottom: 35px;
          padding-top: 35px;

          .category__title {
            margin-bottom: 30px;
          }

          .category__list {

            .category__description {

              margin-bottom: 15px;
            }

            .category__items {

              .service__item {
                margin-bottom: 10px;
                text-indent: 20px;

                &:before {
                  position: absolute;
                  content: '';
                  top:10px;
                  transform: translateY(0%);
                  left:0px;
                  width: 8px;
                  height: 8px;
                  background-color: $color;
                  border-radius: 50%;
                }

              }
            }
          }
        }
      }
    }
  }
}