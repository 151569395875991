.content {

  .section__title {
    font-family: $fontSerif;
    font-size: 46px;
    color: $color;
    background: rgb(114,37,241);
    background: linear-gradient(135deg, rgba(114,37,241,1) 0%, rgba(142,56,237,1) 25%, rgba(164,59,237,1) 50%, rgba(191,53,201,1) 75%, rgba(240,41,136,1) 100%);
    line-height: 58px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    &.black{
      color: $dark;
      background:none;
      -webkit-background-clip: unset;
      -webkit-text-fill-color: unset;
      -moz-background-clip: unset;
      -moz-text-fill-color: unset;
      background-clip: unset;
      text-fill-color: unset;
    }
  }

  p {
    font-family: $fontText;
    font-size: 16px;
    color: $cleargray;
    line-height: 30px;

    span {
      color: $dark;
    }

    &.content__pxl {
      font-family: $fontText;
      font-size: 20px;
      color: $cleargray;
      line-height: 38px;
    }
  }

  &.content__step {
    padding-top: 150px;
    padding-bottom: 110px;
    border-bottom: 2px solid $lightGrey;

    .section__title {
      @include section-content-step;
      grid-column: 1/6;
      text-transform: uppercase;
    }

    .step__content {
      @include section-content-paragraphe;
      grid-column: 7/12;
      margin-left: 20px;
      margin-right: 40px;

      span {
        color: $dark;
      }
    }
  }


  &.content__listCenter {
    padding-top: 120px;
    padding-bottom: 140px;

    .section__title {
      @include section-content-title;
      grid-column: 2/12;
      text-align: center;
      margin: 0px 60px;
      margin-bottom: 100px;
    }

    .list__items {
      grid-column: 6/11;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      .item {
        display: grid;
        grid-template-columns: repeat(5, 60px);
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 75px;

        &:last-child {
          margin-bottom: 0px;
        }

        .item__index {
          width: 60px;
          height: 60px;
          background-color: $color;
          color: white;
          grid-column: 1/2;
          border-radius: 50%;
          display:flex;
          align-items: center;
          justify-content: center;
        }

        .item__content {
          grid-column: 2/6;
        }
      }
    }
  }
}

@media screen and ( max-width: $deviceL ) {
  .content {
    .section__title {
      @include mob-title-1;
    }

    p {
      @include mob-txt-1;
      color: $cleargray;

      span {
        color: $dark;
      }

      &.content__pxl {
        @include mob-txt-1;
        color: $cleargray;
      }
    }
  }
}