@font-face {
  font-family: Abitare_50;
  src: url(../../../fonts/FSD-AbitareSans-50.woff2) format("woff2");
  font-style: normal
}

@font-face {
  font-family: Abitare_350;
  src: url(../../../fonts/FSD-AbitareSans-350.woff2) format("woff2");
  font-style: normal
}

@font-face {
  font-family: Abitare_700;
  src: url(../../../fonts/FSD-AbitareSans-700.woff2) format("woff2");
  font-style: normal
}

@font-face {
  font-family: Abitare_800;
  src: url(../../../fonts/FSD-AbitareSans-800.woff2) format("woff2");
  font-style: normal
}

@font-face {
  font-family: Abitare_1000;
  src: url(../../../fonts/FSD-AbitareSans-1000.woff2) format("woff2");
  font-style: normal
}

@font-face {
  font-family: "Calluna";
  font-style: normal;
  src: url("../../../fonts/Calluna/Calluna-Regular.woff2") format("woff2"),
  url("../../../fonts/Calluna/Calluna-Regular.woff") format("woff"),
  url("../../../fonts/Calluna/Calluna-Regular.otf") format("otf");
}

@font-face {
  font-family: "Calluna";
  font-style: italic;
  src: url("../../../fonts/Calluna/Calluna-It.woff2") format("woff2"),
  url("../../../fonts/Calluna/Calluna-It.woff") format("woff"),
  url("../../../fonts/Calluna/Calluna-It.ttf") format("otf");
}

@font-face {
  font-family: "Manrope";
  src: url("../../../fonts/Manrope/manrope-regular.woff2") format("woff2"),
  url("../../../fonts/Manrope/manrope-regular.woff") format("woff"),
  url("../../../fonts/Manrope/manrope-regular.otf") format("otf");
}

@font-face {
    font-family: "Manrope";
    font-weight: 600;
    src: url("../../../fonts/Manrope/manrope-semibold.woff2") format("woff2"),
    url("../../../fonts/Manrope/manrope-semibold.woff") format("woff"),
    url("../../../fonts/Manrope/manrope-semibold.otf") format("otf");
}

@font-face {
    font-family: "Manrope";
    font-weight: 500;
    src: url("../../../fonts/Manrope/manrope-medium.woff2") format("woff2"),
    url("../../../fonts/Manrope/manrope-medium.woff") format("woff"),
    url("../../../fonts/Manrope/manrope-medium.otf") format("otf");
}