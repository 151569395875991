.hero__page {
  width: 100vw;
  height: 100vh;
  background-color: $dark-blue;
  overflow:hidden;
  .hero__container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow:hidden;
    background: url('/ui/backgrounds/hero__shader.png');
    background-size: cover;
    background-repeat: no-repeat;

    .hero__content {
      position:absolute;
      width: 100%;
      height: 100%;
      z-index: 3;
      top:0px;
      left: 0px;


      .hero__main {
        width: 100%;
        height: 100%;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        position:relative;


        .hero__title_container {
          max-width: 1220px;
          min-width: 200px;
          margin: 0 auto;
          position: relative;


          .hero__title {
            font-size: 62px;
            color: white;
            line-height: 76px;
            text-align:center;
            margin: 0 auto;
            font-family: $fontSerif;
            /*transform: translateY(15px);*/

            h2, h1, h3, p {
              font-size: 62px;
              color: white;
              line-height: 76px;
              text-align:center;
              margin: 0 auto;
              font-family: $fontSerif;
              transform: translateY(15px);

              strong {
                color: $color;
                font-style: italic;
              }
            }
          }


          .hero__surtitle {
            /*position: absolute;
            top: 0px;
            left: 50%;
            transform: translateX(-50%) translateY(-100%);*/
            color: white;
            width: 100%;


            .hero__surtitle__media {
              margin-bottom: 35px;
              align-items: center;
              display: flex;
              justify-content: center;
              margin: 0 auto;

            }
            .module.media {
              height: 100%;
              min-height: 40px;

              .media__container {
                height: 100%;
                .media-svg {
                  height: 100%;
                  div {
                    height: 100%;
                    svg {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }

            }



            p {
              font-size: 16px;
              color: white;
              line-height: 38px;
              text-align: center;
            }
          }
          .hero__subtitle {
            position: absolute;
            bottom: -50px;
            left: 50%;
            transform: translateX(-50%) translateY(100%);
            color: white;
          }
        }

      }
      .hero__bottom {
        width: 84%;
        height: 20px;
        position:absolute;
        bottom: 40px;
        left: 0px;
        margin-left: 8%;
        margin-right: 8%;
        display:flex;
        align-items:center;
        justify-content: space-between;

        .hero__scroller {
          p {
            color: white;
          }
        }
        .hero__socials {
          a {
            color: white;
          }
        }
      }
    }
    .hero__media {
      position:absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      top:0px;
      left: 0px;

      .media {
        width: 100%;
        height: 100%;

        .media__item{
          opacity:.6;
        }

        img {
          border:0px;
          outline: 0px;
        }

      }
    }
    .hero__logo {
      width: 62%;
      margin: 0 auto;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%) translateY(40%);
      z-index: 1;

      svg{
        display:block;
        margin:0 auto;
      }
    }
  }

  &.brand {

  }

}

@media screen and ( max-width: $deviceL ) {
  .hero__page {
    .hero__container {
      .hero__content {
        .hero__main {
          .hero__title_container{
            width: calc(100% - 48px);

            .hero__title {
              font-size: 36px;
              line-height: 48px;

              h2, h1, h3, p {
                font-size: 36px;
                line-height: 48px;
              }
            }
          }

          .hero__surtitle__media {
            margin-bottom: 35px;
          }

          .hero__subtitle {
            width: fit-content;
          }

        }

      }

    }
  }
}

