.media {
  .media__container {
    width: 100%;
    height: 100%;
    position:relative;
    overflow:hidden;

    .media__video {
      width: 100%;
      height: 100%;
      position: relative;

      &.paused {
        .play__toggle {
          opacity: 1;
          transition: 300ms ease-in-out opacity;
        }
      }

      .play__toggle {
        width: 108px;
        height: 108px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform:translateX(-50%) translateY(-50%);
        opacity: 0;
        transition: 300ms ease-in-out opacity;
      }
    }

    .media__url {
      object-fit: cover;
      object-position: center center;
      min-width: 100%;
      min-height: 100%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }

    .media__item {
      display: block;
      width: 100%;
      height: auto;
      height: 100%;
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center center;
      border: 0px;
      outline : 0px;
      /*
      min-width: 100%;
      min-height: 100%;
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);*/

      video {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}


@media screen and ( max-width: $deviceL) {
  .media {
    .media__container {
      width: 100%;
      height: 100%;
      position:relative;
      overflow:hidden;

      .media__video {
        width: 100%;
        height: 100%;
        position: relative;

        &.paused {
          .play__toggle {
            opacity: 1;
            transition: 300ms ease-in-out opacity;
          }
        }

        .play__toggle {
          width: 50px;
          height: 50px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform:translateX(-50%) translateY(-50%);
          opacity: 0;
          transition: 300ms ease-in-out opacity;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      .media__url {
        object-fit: cover;
        object-position: center center;
        min-width: 100%;
        min-height: 100%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }

      .media__item {
        display: block;
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
        object-position: center center;
        border: 0px;
        outline : 0px;
        /*display: block;
        min-width: 100%;
        min-height: 100%;
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);*/

        video {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}