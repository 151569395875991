.page.common{
	.blobs{
		z-index:-1;
		top:0;
		left:-7rem;
		width:54rem;

		.blob{

			&.layer1{
				left:.20%;
				width:99.80%;
			}

			&.layer2{
				left:.89%;
				width:99.11%;
			}

			&.layer3{
				left:0;
				width:100%;

			}
		}
	}
	.header{
		margin:0 0 2.5rem 0;

		.title{
			margin:0 0 1.333rem 0;
			font-family: $crimson;
			font-size:3rem;
			line-height:3.5rem; 
			font-weight:800;
			color:$dark;
		}

		.subtitle{
			margin:0 0 1.333rem 0;
			max-width: 58.75rem;
			font-family: $crimson;
			font-size:1.5rem;
			line-height:2rem; 
			font-weight:800;
			color:$dark;
		}
	}

	.editorial{
		margin:0 0 5.625rem 0;
		font-family: $abitare50;
		font-size: 1rem;
		line-height:1.25rem;

		blockquote{
			font-size: .75rem;
		}

		a{
			color:$color2;
			font-weight: 600;
		}

		strong{
			font-family: $abitare350;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6{
			font-family: $crimson;
			font-size: 1.375rem;
			line-height:2rem;
			font-weight: 700;
		}
	}
}

@media screen and (min-width: $deviceMd) {
	.page.common{
		.blobs{
			z-index:-1;
			top:0;
			left:-11rem;
			width:155.625%;

			.blob{

				&.layer1{
					left:.20%;
					width:99.80%;
				}

				&.layer2{
					left:.89%;
					width:99.11%;
				}

				&.layer3{
					left:0;
					width:100%;

				}
			}
		}
		.header{
			margin:0 0 2.5rem 0;

			.title{
				margin:0 0 1.333rem 0;
				max-width: 58.75rem;
				font-size:3.5rem;
				line-height:3.875rem; 
			}

			.subtitle{
				margin:0 0 1.333rem 0;
				max-width: 58.75rem;
				font-size:1.75rem;
				line-height:2rem; 
			}
		}

		.editorial{
			margin:0 0 5.625rem 0;
			font-size: 1rem;
			line-height:1.25rem;

			blockquote{
				font-size: .75rem;
			}

			a{
				transition: all 250ms ease-in-out;

				&:hover{
					color:$dark;
					text-decoration: none;
				}
			}
			h1,
			h2,
			h3,
			h4,
			h5,
			h6{
				font-size: 1.375rem;
				line-height:2rem;
			}
		}
	}
}

@media screen and (min-width: $deviceLg) {
	.page.common{
		.blobs{
			z-index:-1;
			top:0;
			left:calc(20.47% + 4.9375rem);
			width:115.625%;
		}
	}

}