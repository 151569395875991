.content__keydata {
  justify-content: space-between;

  .keydatas {
    display: flex;
    align-items: flex-start;
    grid-column: 1/13;
    justify-content: space-around;

    .keydata {
      margin: 0px 40px;
      text-align: center;
      width: fit-content;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }

      .keydata__title {
        @include section-keydata-title;
        line-height: 90px;
      }

      .keydata__subtitle {
        @include section-keydata-subtitle;
      }

      .keydata__light {
        @include section-keydata-light;
      }

      .keydata__xs {
        @include section-keydata-xs;
      }
    }
  }
}

@media screen and (max-width: $deviceL){
  .content__keydata {
    grid-template-columns: 1fr;

    .keydatas {
      grid-column: 1/2;
      flex-direction: column;

      .keydata {

        width: 100%;
        margin: 0px;
        padding-top: 50px;
        padding-bottom: 32px;
        border-top: 2px solid $lightGrey;


        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
          border-bottom: 2px solid $lightGrey;
        }

        .keydata__title {
          @include section-keydata-title;
          line-height: 90px;
        }

        .keydata__subtitle {
          @include section-keydata-subtitle;
        }

        .keydata__light {
          @include section-keydata-light;
        }

        .keydata__xs {
          @include section-keydata-xs;
        }
      }
    }
  }
}