.form__newsletter {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  .form__value {
    font-size: 14px;
    color: white;
    opacity: 0.4;
    background-color: transparent;
    border: 0px;
    height: 30px;
    width: 270px;
    outline: 0px;
    ::placeholder {
      font-size: 14px;
      color: white;
      opacity: 0.4;
    }
  }
  .form__submit {
    width: fit-content;
    height: 30px;
    color: white;
  }
}