.buttonCircle {
  cursor: pointer;
  span {
    color: white;
  }

  .circle{
    position: relative;
    z-index: 0;
    top:0;
    right:0;
    display: inline-block;
    width:3.75rem;
    height:3.75rem;
    vertical-align: middle;

    .arrow{
      position: absolute;
      z-index: 1;
      top:50%;
      left:50%;
      display: block;
      margin:-.75rem 0 0 0rem;
      height:1.5rem;
      width:2rem;
      opacity:0;
      transition: all 250ms ease-in-out;
      transform: scaleX(-1);
    }

    .circlesvg{
      svg{

        .cta-ltr-circle,
        .cta-circle{
          transform: rotate(-135deg);
          transform-origin: center;
        }
      }
    }

    .off,
    .on{
      position: absolute;
      top:0;
      right:0;
      transition:all 500ms ease-in-out;
    }

    .off{
      opacity:1;
    }

    .on{
      opacity:0;
    }

  }

  span{
    display: inline-block;
    margin:0 -2rem 0 0;
    vertical-align: middle;
    color: white;
    transition:300ms ease-in-out all;
    max-width: 90%;
    text-align: right;
  }


  &:hover {
    span {
      margin: 0 .5rem 0 0;
      transition-delay:0s !important;
      transition:400ms ease-in-out all;
    }
  }
}