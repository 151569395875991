.homepage{


	::-webkit-scrollbar {
		width: 0px;  /* Remove scrollbar space */
		background: transparent;  /* Optional: just make scrollbar invisible */
	}
	/* Optional: show position indicator in red */


	padding:0;


	.home__brands {
	    display:flex;
	    flex-direction: column;
	    align-items: center;
	    justify-content:space-between;
	    height: auto;
	    padding-top: 81px;
	    padding-bottom: 35px;
		background-color: $lightGrey;
		border-bottom: 2px solid $lightGrey;

	    .brands__list {
	      margin-left: 34px;
	      margin-right: 34px;
	      display:grid;
	      grid-template-columns: repeat(6, 1fr);
	      grid-column-gap: 72px;
	      grid-template-rows: repeat(2, 1fr);
	      grid-row-gap: 40px;
	      width: calc(100% - 68px);


	      .brand__item {
	        padding-top: 35%;
	        position: relative;
	        color: black;
	        transition: 200ms ease-in-out color;

	        &:hover {
	          color: $color;
	          transition: 200ms ease-in-out color;
	          cursor:pointer;
	        }

	        .item__container {
	          position: absolute;
	          top:0px;
	          left: 0px;
	          width: 100%;
	          height: 100%;

	          .logo {
	            width: 100%;
	            height: 100%;
	            display: block;

	            div {
	              width: 100%;
	              height: 100%;
	            }

	            svg {
	              width: 100%;
	              height: 100%;
	              display: block;
	            }
	          }
	        }
	      }
	    }

	    .buttonCircle{
	    	margin-top:60px;
	    	span{
	    		color:$dark;
	    	}
	    }
	}

	.home__introduction {
		/*height: 100vh;*/
		padding-top: 137px;
		padding-bottom: 0;
	    background-color: $lightGrey;
		overflow:hidden;


		.content__media {
			grid-column: 1/7;
			display: block;
			/*padding-top: 122%;
			grid-row: 2/3;
			position:relative;
			overflow: hidden;
			height: 0px;*/

			.media {
				/*position: absolute;
				top:0px;
				left:0px;
				width: 100%;
				height: 100%;*/

    			.media__item {
    				height:auto;
    				min-height:unset;
    			}
			}
		}
		.content__content {
			grid-column: 7/12;
			/*grid-row: 2/3;*/

			.section__title {
				margin: 35px 0px;
			}

			.content__pxl {
				margin-right: 18%;
				margin-bottom: 44px;

				strong {
					color: black;
				}
			}

			.buttonCircle {
				span {
					color: $dark;
				}
			}
		}
	}

	.home__keyfigures {
		padding-top: 128px;
		padding-bottom: 118px;
	    background-color: $lightGrey;
		border-bottom: 2px solid $lightGrey;

		.keydatas {
			justify-content: space-between;
			.keydata {
				max-width: 25%;
			}
		}
	}

	.home__reassur {
		padding-top: 158px;
		padding-bottom: 80px;
	    padding-left: 11vw;
	    padding-right: 11vw;
		border-bottom: 2px solid $lightGrey;

		.logo{
	      grid-column: 3/11;
	      margin-bottom:40px;
	      text-align:center;
		}

		.content-text{
			grid-column: 2/12;

			&.line-1{
			    margin:0 0 40px 0;
			}

			&.line-2{
			    margin: 10px 0 75px 0;
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6{
				display: block;
			    margin: 0;
			    padding: 0;
			    border: 0;
			    vertical-align: baseline;
				font-family: "Calluna", serif;
			    font-size: 58px;
			    line-height: 70px;
			    text-align: center;
			    color: #190932;
			    background: none;
			}

			p{
				display: block;
			    margin:0;
			    font-family: "Manrope", helvetica, Arial, sans-serif;
			    font-size: 14px;
			    line-height: 28px;
			    color: #8c8498;
			    font-weight: 500;
			    text-align: center;
			    text-transform: uppercase;
			}
		}

	}

	.home__universes {
		grid-template-rows: 250px 1fr;
		padding-top: 152px;
		grid-row-gap: 90px;
		background-color: $lightGrey;

		.side__title {
			grid-column: 1/7;
			grid-row: 1/2;
		}

		.side__content {
			grid-column: 7/12;
			grid-row: 1/2;
			margin-right: 18%;

			.buttonCircle {
				margin-top: 42px;
				display: block;
				span {
					color: $dark;
				}
			}

		}

		.universes__list {
			margin-top: 115px;
			grid-column: 1/13;
			grid-row: 2/3;
			display: grid;
			justify-content: space-between;
			grid-column-gap: 7%;
			grid-row-gap: 140px;
			grid-template-columns: repeat(3,1fr);
			grid-auto-columns: 30%;
			background-color: $lightGrey;

			.colItems-1 {
				transform: translateY(-100px);
			}

			.colItems-2 {
				transform: translateY(100px);
			}

			.colItems-3 {
				transform: translateY(0px);
			}

			.item {
				display:block;
				margin-bottom: 140px;

				a {

					&:hover {
						.univers__list__container {
							.item__brands {
								opacity:1;
								transition: 300ms ease-in-out opacity;
							}

							.overlay {
								opacity:0.4;
								transition: 300ms ease-in-out opacity;
							}
						}
					}

					.item__name {
						font-family: $fontText;
						font-size: 32px;
						line-height: 38px;
						color: $dark;
						margin-bottom: 24px;
						width: 50%;
					}

					.univers__list__container {
						position: relative;


						.item__brands {
							position: absolute;
							top: 78px;
							left: 94px;
							bottom: 78px;
							right: 94px;
							z-index:5;
							display:flex;
							flex-direction:column;
							align-items:center;
							justify-content:space-between;
							opacity:0;
							transition: 300ms ease-in-out opacity;

							.universeBrandsItem {
								width:100%;

								.media {
									color: white;

									svg {
										width: 100%;
									}
								}
							}

						}

						.overlay {
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0px;
							left: 0px;
							z-index: 2;
							background-color: black;
							opacity:0;
							transition: 300ms ease-in-out opacity;

						}
					}

					.item__media {
						width: 100%;
						padding-top: 155%;
						position:relative;

						.media {
							position:absolute;
							top:0px;
							left: 0px;
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	}

	.home__expertises {
		display: flex;
   		flex-wrap: wrap;
		grid-template-columns:unset;
		align-items:center;
		justify-content: space-between;
		padding-top: 56px;
		padding-bottom: 150px;

		.expertises__header {
			display:flex;
			flex: 0 0 auto;
		    width: auto;
		    max-width: 100%;
			align-items:center;
			justify-content: space-between;
			order:1;
		    /*flex: 0 0 auto;
			width: auto;
			max-width: 100%;*/

			.side {
				display:flex;
				align-items:center;
				justify-content: space-between;

				.controls {
					display:flex;
					align-items: center;
					justify-content: space-between;
					width: 110px;
					height: 42px;
					margin-left: 52px;

					.control {
						width:42px;
						height:42px;
						background-color: $color;
						display:flex;
						align-items: center;
						justify-content: center;
						border-radius:50%;
						outline: 0px;

						&:nth-child(2) {
							transform: scaleX(-1);

							.arrow {
								transform: translateX(-2px);
							}
						}

						.arrow {
							width: 10px;
							height: 17px;
							transform: translateX(-3px);
						}

					}
				}

			}

		}

		.expertises-cta-all{
			display:flex;
			order:2;
			flex: 0 0 auto;
		    width: auto;
		    max-width: 100%;
		
			.buttonCircle {
				span {
					color: $dark;
				}
			}
		}
		.expertises__list {
			display:flex;
			flex: 0 0 100%;
		    width: 100%;
		    max-width: 100%;
			margin-top: 128px;
			/*grid-column: 1/13;*/
			order:3;

			.buttonCircle {
				margin: 0 auto;
				width: fit-content;
				display: block;
				margin-top: 30px;

				position: absolute;
				bottom: 30px;
				left: 50%;
				transform: translateX(-50%);

				span {
					color: $dark;
				}
			}

			.item-expertise {
				height: auto;
				position: relative;
				max-width: 33vw;
				padding-bottom: 130px;
				padding-left: 20px;
				padding-right: 20px;

				&:after {
					content: '';
					position: absolute;
					width: 2px;
					height: 100%;
					top: 0px;
					right: -7px;
					background-color: $lightGrey;
				}

				.item__visual {
					display:flex;
					width: 200px;
					height: 200px;
					position:relative;
					border-radius: 50%;
					border:2px solid $lightGrey;
					margin-bottom: 45px;
					margin-left: auto;
					margin-right: auto;
					box-sizing: border-box;
					justify-content:center;
					align-items:center;
					/*
					.media {
						width: 50px;
						height: 50px;
						position:absolute;
						top:50%;
						left: 50%;
						transform: translateX(-50%) translateY(-50%);
					}*/

					.media-svg {
						width: 100%;
						height: 100%;

						svg {
							width: 100%;
							height: 100%;
						}
					}

				}

				.expertises__title {
					font-family: $fontText;
					font-weight: 500;
					color: $dark;
					font-size: 32px;
					line-height: 38px;
					margin-bottom: 20px;
					text-align: center;
				}

				.expertise__catch {
					font-family: $fontText;
					font-size: 16px;
					line-height: 28px;
					color: $cleargray;
					text-align: center;
				}

			}
		}
	}

	.zone-blob{
		z-index:1;
	}

	.large-blob{
		position: absolute;
		z-index:-1;
		top:0;
		left:50%;
		display:block;
		margin:0 0 0 -70rem;
		width:95rem;


		.large-blob-fx-mask{
			position: absolute;
			top:0;
			left:0;
			z-index:3;
			display:block;
			width:100%;
			height:auto;


			image{
				mask: url(#hpIntroFxMask);
			}

		}
	}

	.blobs{
		top: -6.75rem;
	    left: 11rem;
	    width: 25rem;

		.blob{

			&.layer1{
				left:5.423%;
				width:94.577%;
			}

			&.layer2{
				left:0;
				width:100%;

			}

			&.layer3{
				left:1.52%;
				width:98.48%;

			}
		}

	}

	h2.title{
		font-family: $abitare800;
		font-size:2rem;
		line-height:2.5rem;
	}

	.baseline{
		position: relative;
		padding:6.75rem 0 6.25rem 0;
		p{
			position:relative;
			z-index:2;
			font-size:1.5rem;
			line-height:2rem;
			color:$white;
			font-family: $abitare1000;
			max-width: 17rem;

			.highlight{
				margin-left:1rem;
				background-color:$white;
				color:$dark;
			}
		}
	}

	.vision{
		padding:3.625rem 0 8.125rem;

		h2.title{
			margin:0 0 1.8125rem 0;
		}

		.text{
			font-family: $abitare50;
			font-size:.875rem;
			line-height:1.5rem;
		}

		.keywords{
			transform: rotate(-90deg);
			li{
				left:-33rem;
				position: relative;
				list-style: none;
				margin:1rem 0;
				white-space: nowrap;
				line-height:4.75rem;
		/*		height:2rem;
				background-color:#ff0000;*/

				&.keyword-1{
					margin-left:9.1875rem;
				}
				&.keyword-3{
					margin-left:5.4375rem;
				}

				.keyword{
					display: inline-block;
					width: auto;
					&:before,
					&:after{
						content:'';
						display: inline-block;
						margin:.625rem 0 0 0;
						width:.625rem;
						height:.625rem;
						border-radius:.3125rem;
						background: linear-gradient(129.75deg,#7225f1 0%, #8e38ed 21%, #a43bed 50%, #bf35c9 75%, #f02988 100%);
						vertical-align:middle;
					}

					span{
						display: inline-block;
						margin:0 1rem;
						color: $dark;
						font-family: $crimson;
						font-size: 2.5rem;
						font-weight: bold;
						text-transform: lowercase;
						vertical-align:middle;
					}
				}
				&.keyword-3{
					.keyword:first-child{
						&:after{
							display:none;
						}
					}
				}
			}
		}
	}

	.universes{
		position: relative;
		padding:8.125rem 0 0 0;

		.blobs{
			z-index:-1;
			top:-12rem;
			right:85%;
			left:initial;
			margin:0;
			width:200%;


			.blob{
				left:initial;
				right:0;

				&.layer1{
					width:96.74%;
				}

				&.layer2{
					width:100%;
				}

				&.layer3{
					width:68.333%;
				}
			}
		}

		.item{
			&:nth-child(even){
				margin-top:2.5625rem;
			}
		}
	}

	.expertises{
		padding:3rem 0 5rem 0;

		h2.title{
			margin:0 0 .5625rem 0;
		}

		.text{
			font-family: $abitare50;
			font-size:.875rem;
			line-height:1.5rem;
		}

		.list{
			position: relative;
			overflow-x: scroll;
			margin:0 -1.5rem 2.5rem -1.5rem;
			width:calc(100% + 3rem);

			.slide{
				position: relative;
				display:block;
				margin:0 -1.875rem;
				width:300vw;
				white-space: nowrap;
			}
		}

		.item{
			display: inline-block;
			width:33%;
			padding:0 1.875rem;

			&:hover {
				.visual {
					transform:translateY(-6%);
					transition:250ms ease-out all;
				}
			}

			.block{
				display:block;
			}

			.visual{
				position: relative;
				margin: 0 auto;
				padding-top: 150%;
				height: 0;
				transition:250ms ease-out all;

				img{
					width: auto;
					height: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);

					&.iconshadow{
						opacity:.4;
					}
				}
			}

			.name{
				margin:0 0 1rem 0;
				font-family: $crimson;
				font-size:1.5rem;
				line-height:2rem;
				font-weight:bold;
				text-align:center
			}

			.description{
				font-family: $abitare50;
				font-size:.875rem;
				line-height:1.25rem;
				text-align:center;
			}
		}
	}


	.brands{
		margin:0 0 3rem 0;

		.introduction{
			margin:0 0 2rem 0;
		}

		.text{
			max-width: 30rem;
			font-family: $abitare50;
			font-size:.875rem;
			line-height:1.5rem;
		}

		.list{
			margin:0 -.6125rem;

			text-align:left;


			.line{
				position:relative;
				display:block;
				margin:0 0 3.125rem 0;
				overflow: hidden;
				min-height:4.5rem;
				width:100%;
  				transform: translate3d(0, 0, 0);

  				.toscroll{
  					position: absolute;
  					top:0;
					white-space:nowrap;
					font-size:0;
					line-height:0;
					width:max-content;
					min-height:4.5rem;
  					transform: translate3d(0, 0, 0);
	  			}

				&:nth-child(odd){
  					.toscroll{
						left:0;
  					}
				}

				&:nth-child(even){
  					.toscroll{
						right:0;
  					}
				}

			}

			.bundle{
				display:inline-block;
			}

			.item{
				display:inline-block;
				margin:0 2.3125rem;
				padding:0;
				color:$dark;
				text-align: center;

				.logo{
					display:inline-block;
				}
				img,
				svg{
					max-width:7.5rem;
					height:auto;
				}
			}

		}
	}

	.news{
		padding:2.1875rem 0 14rem 0;

		h2.title{
			margin:0 0 1.5rem 0;
		}

		.cta{

			.circle{
				.cta-ltr-arrow{
					/*transform-origin: center;
					transform:rotateX(180deg) translateX(-100%);*/
				}
			}
		}

		.text{
			max-width: 30rem;
			font-family: $abitare50;
			font-size:1rem;
			line-height:2rem;
		}

		.limiter{
			position: relative;
			overflow-x: scroll;
			margin:0 0 3rem 0!important;

			.slide{
				position: relative;
				margin:0;
				padding:0 1rem;
				width:max-content;
				white-space: nowrap;

			}
		}

		.item{
			width:17.5rem;
			padding:0 .75rem;
			white-space:normal;

			.arrow{
				position: absolute;
				bottom:0;
				right:0;
				display:block;
				width:2rem;
				height:2rem;

				.off,
				.on{
					position: absolute;
					top:0;
					right:0;
				}

				.off{
					opacity:1;
				}

				.on{
					opacity:0;
				}
			}

			a{
				position: relative;
				display:block;
				padding:0 0 3rem 0;
				cursor: pointer;
			}

			.date{
				font-family: $abitare50;
				font-size:.875rem;
				line-height:2rem;
				color:$cleargray;
				text-align: left;
			}

			.title{
				font-family: $crimson;
				font-weight:800;
				font-size:1.25rem;
				line-height:1.75rem;
				color:$dark;
				text-align: left;
			}


			.visual{
				position:relative;
				margin:0 0 1.375rem 0;
				padding:75% 0 0 0;
				height:0;

				&:before{
					position:absolute;
					z-index:1;
					top:.75rem;
					left:-.75rem;

					content:'';

					display: block;

					width:100%;
					height:100%;

					border:1px solid;
					border-image-source: linear-gradient(30deg,#f02988 10%, #bf35c9 35%, #a43bed 50%, #8e38ed 65%, #7225f1 90%);
					border-image-slice: 1;
					transition: all 125ms ease-in-out;
				}

				.img-mask{
					position:absolute;
					z-index:2;
					top:0;
					right:0;
					bottom:0;
					left:0;

					display: block;

					overflow: hidden;
					transition: all 125ms ease-in-out;

					box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16);

					img{
						position:absolute;
						z-index:2;
						top:0;
						left:50%;
						display: block;
						margin:0 0 0 -66.23%;
						width:auto;
						height:100%;
						margin:0px;
						top:50%;
						left:50%;
						transform:translateX(-50%) translateY(-50%);
					}
				}

			}
		}
	}
}

@media screen and ( min-width: $deviceXl  ) {
	.homepage {
		.home__introduction {
			.content__content {
				max-width: 600px;
			}
		}
	}
}

@media screen and ( max-width: $deviceL ) {
	.homepage{


	    .home__brands {
			display:flex;
			flex-direction: column;
			align-items: center;
			justify-content:center;
			width: 100%;
			height: auto;
			padding-top: 52px;
			padding-bottom: 52px;

		    .brands__list {
		        margin-top: 0px;
		        margin-bottom: 36px;
		        margin-left: 0px;
		        margin-right: 0px;

		        grid-template-columns: repeat(3, 79px);
		        grid-column-gap: unset;
		        justify-content: space-between;
		        grid-row-gap: 30px;
		        width: calc(100% - 48px);



		        .brand__item {
					padding-top: 50%;
					position: relative;
					color: black;

			        .item__container {
			            position: absolute;
			            top:0px;
			            left: 0px;
			            width: 100%;
			            height: 100%;

			            .logo {
			              width: 100%;
			              height: 100%;
			              display: block;

			              div {
			                width: 100%;
			                height: 100%;
			              }

			              path {
			                color: black;
			                fill: black;
			              }

			              svg {
			                width: 100%;
			                height: 100%;
			                display: block;

			              }
			            }
			        }
		        }

		    }
	        .buttonCircle{
	        	margin-top:0;
	        }
	    }

		.home__keyfigures {
			display: none;
		}

		.home__reassur {
			padding-top: 70px;
			padding-left: 0vw;
			padding-right: 0vw;
			padding-bottom:10px;
			display:flex;
			flex-direction: column;

			.logo{
		      grid-column: 3/11;
		      margin-bottom:40px;
		      text-align:center;
			}

			.content-text{
				grid-column: 3/11;

				&.line-1{
				    margin:0 0 40px 0;
				}

				&.line-2{
				    margin: 10px 0 75px 0;
				}

				h1,
				h2,
				h3,
				h4,
				h5,
				h6{
				    font-size: 29px;
				    line-height: 35px;
				}

				p{
					font-size: 14px;
				    line-height: 28px;
				}
			}

		}

		.home__introduction {
			grid-template-rows: unset;
			display: flex;
			flex-direction: column;
			height: auto;
			padding-bottom: 0;
			padding-top: 72px;


			.content__media {
				width: 100%;
				/*padding-top: 122%;
				min-height: 400px;*/
				margin-top: 50px;

				.media {
					/*position: absolute;
					top:0px;
					left:0px;*/
					width: 100%;
					height: 100%;

					svg {
						width: 100%;
						height: 100%;
					}
				}
			}
			.content__content {
				grid-column: 1/7;
				grid-row: 2/3;

				.section__title {
					margin: 0px 0px;
					margin-top: 0px;
					margin-bottom: 20px;
					color: $dark;
				}

				.content__pxl {
					margin-right: 0px;
					margin-bottom: 36px;
				}

				.buttonCircle {
					span {
						color: $dark;
					}
				}
			}
		}

		.home__universes {
			grid-template-rows: unset;
			padding-top: 152px;
			grid-row-gap: unset;
			margin-bottom: 0px;

			padding-top: 72px;
			background-color: $lightGrey;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			margin-left: 0px;
			margin-right: 0px;
			padding-left: 24px;
			padding-right: 24px;

			.side__title {
				grid-column: unset;
				grid-row: unset;
				margin-bottom: 20px;

			}

			.side__content {
				grid-column: unset;
				grid-row: unset;
				margin-right: 0%;

				.buttonCircle {
					margin-top: 36px;
					margin-bottom: 70px;
				}

			}

			.universes__list {
				margin-top: 0px;
				grid-column: unset;
				grid-row: unset;
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				grid-column-gap: 0px;
				grid-row-gap: 0px;
				grid-template-columns: unset;
				grid-auto-columns: unset;

				.colItems-1 {
					transform: translateY(0px);
				}

				.colItems-2 {
					transform: translateY(0px);
				}

				.colItems-3 {
					transform: translateY(0px);
				}

				.item {
					margin-bottom: 60px;

					a {
						.item__name {
							font-size: 24px;
							line-height: 28px;
							width: 100%;
						}

						.item__media {
							width: 100%;
							padding-top: 155%;
							position:relative;

							.media {
								position:absolute;
								top:0px;
								left: 0px;
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}
		}

		.home__expertises {
			padding-top: 30px;
			padding-bottom: 70px;
			display:flex;
			flex-direction: column;

			.expertises__header {
				grid-column: 1/7;
				margin-left: 24px;
				margin-right: 24px;
				order:1;

				.side {
					width: 100%;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.controls {
						margin-top: 20px;
						width: 100px;
						height: 42px;
						margin-left: 0px;

						.control {
							width:42px;
							height:42px;
							background-color: $color;
							display:flex;
							align-items: center;
							justify-content: center;
							border-radius:50%;
							outline: 0px;

							&:nth-child(2) {
								transform: scaleX(-1);

								.arrow {
									transform: translateX(-2px);
								}
							}

							.arrow {
								width: 10px;
								height: 17px;
								transform: translateX(-3px);
							}

						}
					}

				}
			}

			.expertises-cta-all{
				display:flex;
				margin-top:36px;
				order:3;
				justify-content:center;
			}

			.expertises__list {
				margin-top: 57px;
				grid-column: 1/7;
				margin-left: 0px;
				margin-right: 0px;
				/*padding:0 20px;*/
				display: flex;
				flex-direction: column;
				align-items: center;
				order:2;



				.buttonCircle {
					margin-top: 36px;
					margin-bottom: 0px;
					span {
						color: $dark;
					}
				}


				.item-expertise {
					padding: 0 33px;
					height: auto;
					max-width: unset;
					margin: 0px!important;
					/*border:1px solid black;*/

					.item-expertise-container{
						display:block;
						background-color: white;
						padding:52px 0 130px 0;
					}

			/*		.item__visual {
						width: 100px;
						height: 100px;
						display:block;
						position:relative;
						border-radius: 50%;
						border:2px solid $lightGrey;
						margin-bottom: 45px;

						.media {
							width: 30px;
							height: 30px;
							position:absolute;
							top:50%;
							left: 50%;
							transform: translateX(-50%) translateY(-50%);

							svg {
								width: 100%;
								height: 100%;
							}
						}

					}*/

					.expertises__title {
						font-family: $fontText;
						font-weight: 500;
						color: $dark;
						font-size: 24px;
						line-height: 24px;
						margin-bottom: 18px;
					}

					.expertises__listItems {
						display:flex;
						flex-direction: column;
						align-items: center;
						justify-content: flex-start;

						.item__expertise {
							font-family: $fontText;
							font-size: 16px;
							line-height: 28px;
							color: $cleargray;
						}
					}

				}
			}
		}

		.zone-blob{
			z-index:1;
		}

		.large-blob{
			position: absolute;
			z-index:-1;
			top:0;
			left:50%;
			display:block;
			margin:0 0 0 -70rem;
			width:95rem;


			.large-blob-fx-mask{
				position: absolute;
				top:0;
				left:0;
				z-index:3;
				display:block;
				width:100%;
				height:auto;


				image{
					mask: url(#hpIntroFxMask);
				}

			}
		}

		.blobs{
			top: -6.75rem;
			left: 11rem;
			width: 25rem;

			.blob{

				&.layer1{
					left:5.423%;
					width:94.577%;
				}

				&.layer2{
					left:0;
					width:100%;

				}

				&.layer3{
					left:1.52%;
					width:98.48%;

				}
			}

		}

		h2.title{
			font-family: $abitare800;
			font-size:2rem;
			line-height:2.5rem;
		}

		.baseline{
			position: relative;
			padding:6.75rem 0 6.25rem 0;
			p{
				position:relative;
				z-index:2;
				font-size:1.5rem;
				line-height:2rem;
				color:$white;
				font-family: $abitare1000;
				max-width: 17rem;

				.highlight{
					margin-left:1rem;
					background-color:$white;
					color:$dark;
				}
			}
		}

		.vision{
			padding:3.625rem 0 8.125rem;

			h2.title{
				margin:0 0 1.8125rem 0;
			}

			.text{
				font-family: $abitare50;
				font-size:.875rem;
				line-height:1.5rem;
			}

			.keywords{
				transform: rotate(-90deg);
				li{
					left:-33rem;
					position: relative;
					list-style: none;
					margin:1rem 0;
					white-space: nowrap;
					line-height:4.75rem;
					/*		height:2rem;
                            background-color:#ff0000;*/

					&.keyword-1{
						margin-left:9.1875rem;
					}
					&.keyword-3{
						margin-left:5.4375rem;
					}

					.keyword{
						display: inline-block;
						width: auto;
						&:before,
						&:after{
							content:'';
							display: inline-block;
							margin:.625rem 0 0 0;
							width:.625rem;
							height:.625rem;
							border-radius:.3125rem;
							background: linear-gradient(129.75deg,#7225f1 0%, #8e38ed 21%, #a43bed 50%, #bf35c9 75%, #f02988 100%);
							vertical-align:middle;
						}

						span{
							display: inline-block;
							margin:0 1rem;
							color: $dark;
							font-family: $crimson;
							font-size: 2.5rem;
							font-weight: bold;
							text-transform: lowercase;
							vertical-align:middle;
						}
					}
					&.keyword-3{
						.keyword:first-child{
							&:after{
								display:none;
							}
						}
					}
				}
			}
		}

		.universes{
			position: relative;
			padding:8.125rem 0 0 0;

			.blobs{
				z-index:-1;
				top:-12rem;
				right:85%;
				left:initial;
				margin:0;
				width:200%;


				.blob{
					left:initial;
					right:0;

					&.layer1{
						width:96.74%;
					}

					&.layer2{
						width:100%;
					}

					&.layer3{
						width:68.333%;
					}
				}
			}

			.item{
				&:nth-child(even){
					margin-top:2.5625rem;
				}
			}
		}

		.expertises{
			padding:3rem 0 5rem 0;

			h2.title{
				margin:0 0 .5625rem 0;
			}

			.text{
				font-family: $abitare50;
				font-size:.875rem;
				line-height:1.5rem;
			}

			.list{
				position: relative;
				overflow-x: scroll;
				margin:0 -1.5rem 2.5rem -1.5rem;
				width:calc(100% + 3rem);

				.slide{
					position: relative;
					display:block;
					margin:0 -1.875rem;
					width:300vw;
					white-space: nowrap;
				}
			}

			.item{
				display: inline-block;
				width:20%;
				padding:0 1.875rem;

				&:hover {
					.visual {
						transform:translateY(-6%);
						transition:250ms ease-out all;
					}
				}

				.block{
					display:block;
				}

				.visual{
					position: relative;
					margin: 0 auto;
					padding-top: 150%;
					height: 0;
					transition:250ms ease-out all;

					img{
						width: auto;
						height: 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translateX(-50%) translateY(-50%);

						&.iconshadow{
							opacity:.4;
						}
					}
				}

				.name{
					margin:0 0 1rem 0;
					font-family: $crimson;
					font-size:1.5rem;
					line-height:2rem;
					font-weight:bold;
					text-align:center
				}

				.description{
					font-family: $abitare50;
					font-size:.875rem;
					line-height:1.25rem;
					text-align:center;
				}
			}
		}


		.brands{
			margin:0 0 3rem 0;

			.introduction{
				margin:0 0 2rem 0;
			}

			.text{
				max-width: 30rem;
				font-family: $abitare50;
				font-size:.875rem;
				line-height:1.5rem;
			}

			.list{
				margin:0 -.6125rem;

				text-align:left;


				.line{
					position:relative;
					display:block;
					margin:0 0 3.125rem 0;
					overflow: hidden;
					min-height:4.5rem;
					width:100%;
					transform: translate3d(0, 0, 0);

					.toscroll{
						position: absolute;
						top:0;
						white-space:nowrap;
						font-size:0;
						line-height:0;
						width:max-content;
						min-height:4.5rem;
						transform: translate3d(0, 0, 0);
					}

					&:nth-child(odd){
						.toscroll{
							left:0;
						}
					}

					&:nth-child(even){
						.toscroll{
							right:0;
						}
					}

				}

				.bundle{
					display:inline-block;
				}

				.item{
					display:inline-block;
					margin:0 2.3125rem;
					padding:0;
					color:$dark;
					text-align: center;

					.logo{
						display:inline-block;
					}
					img,
					svg{
						max-width:7.5rem;
						height:auto;
					}
				}

			}
		}

		.international{
			position: relative;
			padding:4rem 0;

			.blobs{
				z-index:-1;
				top:-3.5rem;
				right:-6.5rem;
				left:initial;
				margin:0;
				width:100%;
				height:33.4375rem;


				.blob{
					left:initial;
					right:0;
					width:auto;

					div,
					svg{
						display:block;
						width:auto;
						height:100%;
					}

					&.layer1{
						height:88.96%;
					}

					&.layer2{
						height:100%;
					}

					&.layer3{
						height:94.98%;
					}
				}
			}

			h2.title{
				margin:0 0 1.3125rem 0;
				max-width: 27.5rem;
				color:$white;
			}

			.text{
				max-width: 27.5rem;
				font-family: $abitare50;
				font-size:1rem;
				line-height:2rem;
				color:$white;
			}
			@media screen and ( max-width: 730px) {

				.introduction{
					margin-bottom: 8rem;
					margin-top: 1rem;
				}
			}
			@media screen and ( min-width: 730px) {

				.introduction{
					margin:5rem 0;
				}
			}


			.list{
				margin:-.6125rem -.6125rem;
			}

			.item{
				padding:.6125rem .6125rem;

				.block{
					display:block;
				}

				.visual{
					position:relative;
					margin-bottom:1.25rem;
					padding-top:60%;
					height:0;

					img{
						position: absolute;
						z-index:2;
						top:50%;
						left:50%;
						margin:-19.38% 0 0 -30%;
						width:60%;
						height:auto;
					}

					&:before{
						content:'';
						position: absolute;
						z-index:1;
						bottom:-1.25rem;
						left:50%;
						margin:0 0 0 -39.555%;
						padding-top:45.736%;
						width:75.116%;
						height:0;
						background-image:url('../../img/locations_shadow.png');
						background-size: cover;
					}
				}

				.label{
					margin:0;
					font-family: $crimson;
					font-size:1rem;
					line-height:1.375rem;
					text-align:center;
					white-space: normal;
				}

			}

			.mapmain {
				width:100%;

				svg {
					width:100%;
				}
			}
		}

		.news{
			padding:2.1875rem 0 14rem 0;

			h2.title{
				margin:0 0 1.5rem 0;
			}

			.cta{

				.circle{
					.cta-ltr-arrow{
						/*transform-origin: center;
                        transform:rotateX(180deg) translateX(-100%);*/
					}
				}
			}

			.text{
				max-width: 30rem;
				font-family: $abitare50;
				font-size:1rem;
				line-height:2rem;
			}

			.limiter{
				position: relative;
				overflow-x: scroll;
				margin:0 0 3rem 0!important;

				.slide{
					position: relative;
					margin:0;
					padding:0 1rem;
					width:max-content;
					white-space: nowrap;

				}
			}

			.item{
				width:17.5rem;
				padding:0 .75rem;
				white-space:normal;

				.arrow{
					position: absolute;
					bottom:0;
					right:0;
					display:block;
					width:2rem;
					height:2rem;

					.off,
					.on{
						position: absolute;
						top:0;
						right:0;
					}

					.off{
						opacity:1;
					}

					.on{
						opacity:0;
					}
				}

				a{
					position: relative;
					display:block;
					padding:0 0 3rem 0;
					cursor: pointer;
				}

				.date{
					font-family: $abitare50;
					font-size:.875rem;
					line-height:2rem;
					color:$cleargray;
					text-align: left;
				}

				.title{
					font-family: $crimson;
					font-weight:800;
					font-size:1.25rem;
					line-height:1.75rem;
					color:$dark;
					text-align: left;
				}


				.visual{
					position:relative;
					margin:0 0 1.375rem 0;
					padding:75% 0 0 0;
					height:0;

					&:before{
						position:absolute;
						z-index:1;
						top:.75rem;
						left:-.75rem;

						content:'';

						display: block;

						width:100%;
						height:100%;

						border:1px solid;
						border-image-source: linear-gradient(30deg,#f02988 10%, #bf35c9 35%, #a43bed 50%, #8e38ed 65%, #7225f1 90%);
						border-image-slice: 1;
						transition: all 125ms ease-in-out;
					}

					.img-mask{
						position:absolute;
						z-index:2;
						top:0;
						right:0;
						bottom:0;
						left:0;

						display: block;

						overflow: hidden;
						transition: all 125ms ease-in-out;

						box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16);

						img{
							position:absolute;
							z-index:2;
							top:0;
							left:50%;
							display: block;
							margin:0 0 0 -66.23%;
							width:auto;
							height:100%;
							margin:0px;
							top:50%;
							left:50%;
							transform:translateX(-50%) translateY(-50%);
						}
					}

				}
			}
		}
	}
}
