nav.navigation {
  position:absolute;
  z-index:100;
  top:72px;
  left:0;
  width: 100%;
  display:flex;
  align-items:center;
  justify-content: center;
  overflow:hidden;

  .navigation__container {
    position: relative;
    font-family: $abitare350;
    margin-left: 8vw;
    margin-right: 8vw;
    height: 72px;
    display:flex;
    align-items:center;
    justify-content: space-between;
    width: 100%;


    .navigation__side {
      display:flex;
      align-items:center;
      justify-content: flex-start;

      .navigation__logo {
        width: 148px;
        height: 72px;
        margin-right: 50px;

        .logo {
          width: 100%;
          height: 100%;

          div, svg {
            width: 100%;
            height: 100%;
            color: white;
          }
        }
      }
      .navigation__navSide {
        display:flex;
        align-items:center;
        justify-content: flex-start;

        .languages {
          margin-right: 33px;

          .languages-list {
            display:flex;
            list-style: none;
            margin-bottom: 0px;
            padding-inline-start: 0px;

            .sep {
              margin: 0px 14px;
            }

            .languages-item {

              &:hover {
                a {
                  opacity: 1;
                  transition: 300ms ease-in-out opacity;
                }
              }
              a {
                font-size: 12px;
                color: white;
                line-height: 17px;
                opacity:0.5;
                transition: 300ms ease-in-out opacity;

                &.selected {
                  opacity: 1;
                }
              }
            }
          }
        }

        .secondNav {
          display:flex;
          list-style: none;
          margin-bottom: 0px;
          padding-inline-start: 0px;

          &.mobile {
            display: none;
          }

          .nav-item {
            margin-right: 16px;
            a {
              font-size: 12px;
              color: white;
              line-height: 17px;
              opacity: 0.4;
              transition: 200ms ease-in-out opacity;

              &:hover {
                opacity: 0.8;
                transition: 200ms ease-in-out opacity;
              }
            }

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
    .navigation__navMain {
      display:flex;
      align-items:center;
      justify-content: flex-end;
      .MaindNav {
        display:flex;
        align-items:center;
        justify-content: flex-end;
        margin-bottom: 0px;
        padding-inline-start: 0px;
        list-style: none;

        .nav-item {
          margin-left: 24px;
          &:first-child {
            margin-left: 0px;
          }
          a {
            color: white;
            font-size: 15px;
            position:relative;

            .indicator {
              position:absolute;
              top: 20px;
              left:0px;
              width: 100%;
              height: 2px;
              background: rgb(114,37,241);
              background: linear-gradient(270deg, rgba(114,37,241,1) 0%, rgba(142,56,237,1) 25%, rgba(164,59,237,1) 50%, rgba(191,53,201,1) 75%, rgba(240,41,136,1) 100%);
              opacity: 0;
              transition: 200ms ease-in-out opacity;
            }

            &.selected {
              .indicator {
                opacity: 1;
                transition: 200ms ease-in-out opacity;
              }
            }
            &:hover {
              text-decoration: none;
              .indicator {
                opacity: 1;
                transition: 200ms ease-in-out opacity;
              }
            }
          }

          .openclose {
            padding: 12px 21px;
            border-radius: 21px;
            background-color: $color;
            color: white;
            font-size: 15px;
            font-weight: 600;


            .indicator {
              display: none;
            }
          }
        }
      }
    }
    .secondNav.mobile {
      display: none;
    }
    .mobile-btn-cnt {
      display:none;
    }
    .contact__modal.mobile {
      padding: 11px 21px;
      border-radius: 21px;
      background-color: $color;
      color: white;
      font-size: 14px;
      display: none;
    }
    .navigation__burger {
      display: none;
      .menu-burger-btn {
        .line {

        }
      }
    }
  }
}

@media screen and ( max-width: 1200px) {
  nav.navigation {
    .navigation__container {
      .navigation__side {
        .navigation__logo {
          .logo {

          }
        }
        .navigation__navSide {
          .languages {
            .languages-list {
              .languages-item {

              }
            }
          }
          .secondNav {
            .nav-item {

            }
          }
        }
      }
      .navigation__navMain {
        .MaindNav {
          .nav-item {

          }

          .openclose {
            display: block;
            padding: 5px 10px;
          }
        }
      }

      .navigation__burger {
        .menu-burger-btn {
          .line {

          }
        }
      }
    }
  }

  nav.navigation {
    top:0px;
    padding-top: 0px;
    background-color: transparent;
    align-items: flex-start;
    overflow:hidden;
    height: 83px;


    &.open {
      height: 100vh;
      background-color: $dark;
    }

    .navigation__container {
      height: 100vh;
      width: calc( 100% - 48px);
      display: block;
      position:relative;


      .navigation__side {
        position: absolute;
        top: 18px;
        left:0px;

        .navigation__logo {

          height: 40px;
          width: 80px;
          margin-right: 28px;

          .logo {

          }
        }
        .navigation__navSide {


          .languages {


            .languages-list {

              .sep {

              }

              .languages-item {
                a {

                }
              }
            }
          }

          .secondNav {
            display: none;
          }
        }
      }
      .navigation__navMain {
        display: block;
        position: absolute;
        top: 20vh;
        left: 0px;
        height: 55vh;
        width: 100%;
        overflow-y: auto;

        .MaindNav {
          height: 100%;
          width: 100%;
          display:flex;
          align-items:flex-start;
          justify-content: space-between;
          flex-direction: column;

          .nav-item {
            margin-left: 0px;

            &.mod {
              display: none;
            }

            .openclose {
              padding:5px 20px;
              display:block;
            }

            a {
              color: white;
              @include xs-nav-link;
              font-weight: 600;

              .indicator {
               display: none;
              }
            }
          }
        }
      }
      .secondNav.mobile {
        position: absolute;
        left: 0px;
        width: 100%;
        bottom: 5vh;
        height: 10vh;
        display:flex;
        align-items:flex-start;
        justify-content: space-between;
        flex-direction: column;
        opacity: 0.4;

        a {
          color: white;
        }
      }
      .contact__modal.mobile {
        display: block;
        position: absolute;
        top: 82vh;
        left: 0px;

      }
      .mobile-btn-cnt {
        display:block;
      }
      .navigation__burger {
        display:block;
        width: 50px;
        height: 50px;
        position: absolute;
        right: 0px;
        top: 13px;

        .menu-burger-btn {
          width: 100%;
          height: 100%;
          display:flex;
          align-items: center;
          justify-content: center;
          padding: 0px;
          outline: 0px;

          .line__container {
            width: 18px;
            height: 10px;
            position: relative;

            .line {
              width: 18px;
              height: 2Px;
              border-radius: 2Px;
              background-color: white;

              &.line-1 {
                position: absolute;
                top: 0px;
                left:0px;
                transform: translateY(0%) rotate(0deg);
              }

              &.line-2 {
                position: absolute;
                bottom: 0px;
                left:0px;
                transform: translateY(0%) rotate(0deg);
              }
            }

          }

          &.active {
            .line__container {
              width: 20px;
              height: 20px;
              position: relative;

              .line {
                width: 20px;
                height: 4Px;
                border-radius: 2Px;
                background-color: white;

                &.line-1 {
                  position: absolute;
                  top: 50%;
                  left:0px;
                  transform: translateY(-50%) rotate(45deg);
                }

                &.line-2 {
                  position: absolute;
                  top: 50%;
                  left:0px;
                  transform: translateY(-50%) rotate(-45deg);
                }
              }

            }
          }
        }

        .menu-burger-btns {
          width: 100%;
          height: 100%;
          display:flex;
          align-items: center;
          justify-content: center;
          padding: 0px;
          outline: 0px;

          .line__container {
            width: 20px;
            height: 20px;
            position: relative;

            .line {
              width: 20px;
              height: 4Px;
              border-radius: 2Px;
              background-color: white;

              &.line-1 {
                position: absolute;
                top: 50%;
                left:0px;
                transform: translateY(-50%) rotate(45deg);
              }

              &.line-2 {
                position: absolute;
                top: 50%;
                left:0px;
                transform: translateY(-50%) rotate(-45deg);
              }
            }

          }

        }

        &.activee {
          .menu-burger-btn {
            width: 100%;
            height: 100%;
            display:flex;
            align-items: center;
            justify-content: center;
            padding: 0px;

            .line__container {
              width: 20px;
              height: 20px;
              position: relative;

              .line {
                width: 20px;
                height: 4Px;
                border-radius: 2Px;
                background-color: white;

                &.line-1 {
                  position: absolute;
                  top: 50%;
                  left:0px;
                  transform: translateY(-50%) rotate(45deg);
                }

                &.line-2 {
                  position: absolute;
                  top: 50%;
                  left:0px;
                  transform: translateY(-50%) rotate(-45deg);
                }
              }

            }

          }
        }

        &.actives {
          .menu-burger-btn {
            width: 100%;
            height: 100%;
            display:flex;
            align-items: center;
            justify-content: center;
            padding: 0px;

            .line__container {
              width: 20px;
              height: 20px;
              position: relative;

              .line {
                width: 20px;
                height: 4Px;
                border-radius: 2Px;
                background-color: white;

                &.line-1 {
                  position: absolute;
                  top: 3px;
                  left:0px;
                }

                &.line-2 {
                  position: absolute;
                  bottom: 3px;
                  left:0px;
                }
              }

            }

          }
        }
      }
    }
  }
}