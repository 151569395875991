.news-homepage{
	//padding-top: 7rem;
	::-webkit-scrollbar {
		width: 0px;  /* Remove scrollbar space */
		background: transparent;  /* Optional: just make scrollbar invisible */
	}
	/* Optional: show position indicator in red */

	//padding-top: 7rem;

	.news__list {
		width: 100%;
		margin-top: 112px;
		margin-bottom: 98px;
		position:relative;

		.news__filter {
			position: absolute;
			top:0px;
			right: 8%;
			width: 200px;
			height: 50px;
			z-index: 20;
		}
	}

	.pagination {
		padding-top: 100px;
		padding-bottom: 0px;
		display:flex;
		align-items: center;
		justify-content: center;
		span {
			color: $dark;
		}
	}


	.highlight{

		.item{
			padding:0 0 3rem;

			.visual{
				position:relative;
				margin:0 0 1.375rem 0;
				padding:70.21% 0 0 0;
				height:0;

				.img-mask{
					position:absolute;
					z-index:2;
					top:0;
					right:0;
					bottom:0;
					left:0;

					display: block;

					overflow: hidden;

					box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16);

					img{
						position:absolute;
						z-index:2;
						top:0;
						left:50%;
						display: block;
						margin:0 0 0 -63.23%;
						width:auto;
						height:100%;
						margin:0px;
						top:50%;
						left:50%;
						transform:translateX(-50%) translateY(-50%);
					}
				}

			}


			.date{
				font-size:1rem;
				line-height:1.5rem; 
			}

			.title{
				font-size:1.5rem;
				line-height:2rem; 
			}
		}


	}

	.latest{
		.header{
			margin:0 0 1.255rem 0;
		}
		.title{
			margin:0 0 1.25rem 0;
			padding:0;
			font-family: $abitare1000;
			font-size:1.5rem;
			line-height: 1.625rem;
		}
		.cta{
			margin:0;
			background:none;
			border:none;
			outline:none
		}
		
		.list{
			position: relative;
			overflow-x: scroll;
			margin:0 -1.5rem 0 -1.5rem;

			.slide{
				position: relative;
				display:block;
				margin:0 -.75rem;
				padding:0 1.5rem;
				max-width:max-content;
				min-width:100%;
				white-space: nowrap;

				.item{
					display: inline-block;
					padding:0 .75rem;
					width:17.5rem;
					white-space: normal;
					vertical-align:top;
					height:100%;

					a{
						display:flex;
						flex-wrap:wrap;
						flex:0 0 100%;
						width:100%;
						height:100%;
						max-width:100%;
					}

					.arrow{
						display: inline-block;
					}

					.title{
						margin:0 0 2rem 0;
						min-height:7.5rem;
					}
					.visual{
						width:100%;
					}
				}

			}
		}

	}

	.all{
		margin:3rem 0 14rem 0;
		.title{
			margin:0 0 1.25rem 0;
			padding:0;
			font-family: $abitare1000;
			font-size:1.5rem;
			line-height: 1.625rem;
		}
		.filters{
			position: relative;
			z-index:10;
			margin:0 0 1.25rem 0;
			padding:0;
			min-height:3.125rem;

			.btn{
				position: absolute;
				z-index:1;
				top:0;
				right:0;
				display:block;
				margin:0;
				padding:0;
				width:100%;
				font-size:0;
				white-space:nowrap;
				border:double 1px transparent;
				background-image: linear-gradient($medium 0%, $dark 100%), linear-gradient(30deg,#f02988 10%, #bf35c9 35%, #a43bed 50%, #8e38ed 65%, #7225f1 90%);
				/*border-image-slice: 1;*/
				border-radius:1.25rem; 
				background-origin: border-box;
				background-clip: content-box, border-box;

				cursor:pointer;

			}
				
			&:not(.unfold){
				.btn{
				}
			}

			.inside{
				display: block;
				padding:.5rem 0;
				border-radius:1.1875rem;
				font-size:.875rem;
				line-height:1rem; 
				text-align:left;
				background-color:$white;
				width:100%;
				white-space:nowrap;
				overflow: hidden;
				transition:all 250ms ease-in-out;
				box-shadow: 0 0 .5rem rgba(0,0,0,0);
			}

			.icon{
				margin-left:.5rem;
				font-size:.75rem;
				vertical-align: middle;
				transition:all 250ms ease-in-out;
			}

			span{
				font-family: $abitare350;
			}

			ul{
				margin:0;
				padding:0;
			}

			li{
				margin:0;
				padding:0;
				list-style: none;
			}

			.filters-selected{
				padding:.25rem 1.625rem;
				white-space: nowrap;
				color:$color;

				span{
					margin-right:auto;
				}
			}

			.filters-select{
				min-height:0;
				max-height:0;
				overflow:hidden;
			}

			&.unfold{

				.filters-selected{
					.icon{
						transform: rotate(-180deg);
					}
				}

				.filters-select{
					min-height:1rem;
					max-height:30rem;
				}

			}

			.filter-btn{
				display: block;
				margin:0;
				padding:.25rem 1.625rem;
				width:100%;
				background:none;
				border:none;
				outline:none;
				text-align:left;
				cursor: pointer;

				span{
					white-space: nowrap;
				}

				&.active{
					display:none;
				}
			}
		}
		.list{
			margin-top:0;
			.item{

				&.hide{
					display: none;
				}
			}
		}
	}

	.list{
		margin:4.875rem -.875rem 0;
	}

	.item{
		padding:0 .875rem 2.6875rem;

		.arrow{
			position: absolute;
			bottom:0;
			right:0;
			display:none;
			width:2rem;
			height:2rem;

			.off,
			.on{
				position: absolute;
				top:0;
				left:0;
			}

			.off{
				opacity:1;
			}

			.on{
				opacity:0;
			}
		}

		a{
			position: relative;
			display: block;
			cursor: pointer;
		}

		.date{
			font-family: $abitare50;
			font-size:.875rem;
			line-height:2rem; 
			color:$cleargray;
			text-align: left;
		}

		.title{
			font-family: $crimson;
			font-weight:800;
			font-size:1.25rem;
			line-height:1.5rem; 
			color:$dark;
			text-align: left;
		}

		.visual{
			position:relative;
			margin:0 0 1.375rem 0;
			padding:70.21% 0 0 0;
			height:0;

			.img-mask{
				position:absolute;
				z-index:2;
				top:0;
				right:0;
				bottom:0;
				left:0;

				display: block;

				overflow: hidden;

				box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16);

				img{
					position:absolute;
					z-index:2;
					top:0;
					left:50%;
					display: block;
					margin:0 0 0 -63.23%;
					width:auto;
					height:100%;
					margin:0px;
					top:50%;
					left:50%;
					transform:translateX(-50%) translateY(-50%);
				}
			}

		}
	}
}



@media screen and (max-width: $deviceL) {
	.news-homepage{
		//padding-top: 7rem;
		::-webkit-scrollbar {
			width: 0px;  /* Remove scrollbar space */
			background: transparent;  /* Optional: just make scrollbar invisible */
		}
		/* Optional: show position indicator in red */

		//padding-top: 7rem;

		.news__list {
			width: 100%;
			margin-top: 70px;
			margin-bottom: 70px;
			position:relative;

			.news__filter {
				position: absolute;
				top:0px;
				right: 8%;
				width: 200px;
				height: 50px;
				z-index: 20;
			}
		}


		.highlight{

			.item{
				padding:0 0 3rem;

				.visual{
					position:relative;
					margin:0 0 1.375rem 0;
					padding:70.21% 0 0 0;
					height:0;

					.img-mask{
						position:absolute;
						z-index:2;
						top:0;
						right:0;
						bottom:0;
						left:0;

						display: block;

						overflow: hidden;

						box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16);

						img{
							position:absolute;
							z-index:2;
							top:0;
							left:50%;
							display: block;
							margin:0 0 0 -63.23%;
							width:auto;
							height:100%;
							margin:0px;
							top:50%;
							left:50%;
							transform:translateX(-50%) translateY(-50%);
						}
					}

				}


				.date{
					font-size:1rem;
					line-height:1.5rem;
				}

				.title{
					font-size:1.5rem;
					line-height:2rem;
				}
			}


		}

		.latest{
			.header{
				margin:0 0 1.255rem 0;
			}
			.title{
				margin:0 0 1.25rem 0;
				padding:0;
				font-family: $abitare1000;
				font-size:1.5rem;
				line-height: 1.625rem;
			}
			.cta{
				margin:0;
				background:none;
				border:none;
				outline:none
			}

			.list{
				position: relative;
				overflow-x: scroll;
				margin:0 -1.5rem 0 -1.5rem;

				.slide{
					position: relative;
					display:block;
					margin:0 -.75rem;
					padding:0 1.5rem;
					max-width:max-content;
					min-width:100%;
					white-space: nowrap;

					.item{
						display: inline-block;
						padding:0 .75rem;
						width:17.5rem;
						white-space: normal;
						vertical-align:top;
						height:100%;

						a{
							display:flex;
							flex-wrap:wrap;
							flex:0 0 100%;
							width:100%;
							height:100%;
							max-width:100%;
						}

						.arrow{
							display: inline-block;
						}

						.title{
							margin:0 0 2rem 0;
							min-height:7.5rem;
						}
						.visual{
							width:100%;
						}
					}

				}
			}

		}

		.all{
			margin:3rem 0 14rem 0;
			.title{
				margin:0 0 1.25rem 0;
				padding:0;
				font-family: $abitare1000;
				font-size:1.5rem;
				line-height: 1.625rem;
			}
			.filters{
				position: relative;
				z-index:10;
				margin:0 0 1.25rem 0;
				padding:0;
				min-height:3.125rem;

				.btn{
					position: absolute;
					z-index:1;
					top:0;
					right:0;
					display:block;
					margin:0;
					padding:0;
					width:100%;
					font-size:0;
					white-space:nowrap;
					border:double 1px transparent;
					background-image: linear-gradient($medium 0%, $dark 100%), linear-gradient(30deg,#f02988 10%, #bf35c9 35%, #a43bed 50%, #8e38ed 65%, #7225f1 90%);
					/*border-image-slice: 1;*/
					border-radius:1.25rem;
					background-origin: border-box;
					background-clip: content-box, border-box;

					cursor:pointer;

				}

				&:not(.unfold){
					.btn{
					}
				}

				.inside{
					display: block;
					padding:.5rem 0;
					border-radius:1.1875rem;
					font-size:.875rem;
					line-height:1rem;
					text-align:left;
					background-color:$white;
					width:100%;
					white-space:nowrap;
					overflow: hidden;
					transition:all 250ms ease-in-out;
					box-shadow: 0 0 .5rem rgba(0,0,0,0);
				}

				.icon{
					margin-left:.5rem;
					font-size:.75rem;
					vertical-align: middle;
					transition:all 250ms ease-in-out;
				}

				span{
					font-family: $abitare350;
				}

				ul{
					margin:0;
					padding:0;
				}

				li{
					margin:0;
					padding:0;
					list-style: none;
				}

				.filters-selected{
					padding:.25rem 1.625rem;
					white-space: nowrap;
					color:$color;

					span{
						margin-right:auto;
					}
				}

				.filters-select{
					min-height:0;
					max-height:0;
					overflow:hidden;
				}

				&.unfold{

					.filters-selected{
						.icon{
							transform: rotate(-180deg);
						}
					}

					.filters-select{
						min-height:1rem;
						max-height:30rem;
					}

				}

				.filter-btn{
					display: block;
					margin:0;
					padding:.25rem 1.625rem;
					width:100%;
					background:none;
					border:none;
					outline:none;
					text-align:left;
					cursor: pointer;

					span{
						white-space: nowrap;
					}

					&.active{
						display:none;
					}
				}
			}
			.list{
				margin-top:0;
				.item{

					&.hide{
						display: none;
					}
				}
			}
		}

		.list{
			margin:4.875rem -.875rem 0;
		}

		.item{
			padding:0 .875rem 2.6875rem;

			.arrow{
				position: absolute;
				bottom:0;
				right:0;
				display:none;
				width:2rem;
				height:2rem;

				.off,
				.on{
					position: absolute;
					top:0;
					left:0;
				}

				.off{
					opacity:1;
				}

				.on{
					opacity:0;
				}
			}

			a{
				position: relative;
				display: block;
				cursor: pointer;
			}

			.date{
				font-family: $abitare50;
				font-size:.875rem;
				line-height:2rem;
				color:$cleargray;
				text-align: left;
			}

			.title{
				font-family: $crimson;
				font-weight:800;
				font-size:1.25rem;
				line-height:1.5rem;
				color:$dark;
				text-align: left;
			}

			.visual{
				position:relative;
				margin:0 0 1.375rem 0;
				padding:70.21% 0 0 0;
				height:0;

				.img-mask{
					position:absolute;
					z-index:2;
					top:0;
					right:0;
					bottom:0;
					left:0;

					display: block;

					overflow: hidden;

					box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16);

					img{
						position:absolute;
						z-index:2;
						top:0;
						left:50%;
						display: block;
						margin:0 0 0 -63.23%;
						width:auto;
						height:100%;
						margin:0px;
						top:50%;
						left:50%;
						transform:translateX(-50%) translateY(-50%);
					}
				}

			}
		}
	}

}
