footer.footer {
  width: 100vw;
  height: 100vh;
  background-color: $dark-blue;
  overflow:hidden;

  &.home {
    .footer__container {
      display:grid;
      grid-template-rows: 43% 1fr;
      grid-template-columns: 8% 1fr 8%;
    }
  }

  .footer__container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow:hidden;
    background: url('/ui/backgrounds/hero__shader.png');
    background-size: cover;
    background-repeat: no-repeat;
    display:grid;
    grid-template-rows: 36% 1fr;
    grid-template-columns: 8% 1fr 8%;

    .footer__hero {
      grid-column: 2/3;
      grid-row: 1/2;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      .footer__navigation {
        display:flex;
        align-items:center;
        justify-content:space-between;
        height: 100%;
        width: 100%;

        .btn-footernav {
          color: $cleargray;
          text-decoration: none;
          font-size: 14px;
          font-family: $fontText;
          display:block;
          text-transform: uppercase;
        }

        .navigation {
          width: 70%;
          min-width: 70%;
          display:flex;
          align-items: center;
          justify-content: center;

          .navigation__item {
            width: 100%;
            display:flex;
            align-items:center;
             &:first-child {
               justify-content: flex-end;

               .buttonCircle {
                 margin-right: 40px;

                 span {
                   margin: 0 -3rem 0 0;
                   text-align: right;
                 }

                 &:hover {
                   span {
                     margin: 0 0.5rem 0 0;
                   }
                 }
               }
             }
             &:last-child {
              justify-content: flex-start;
               .buttonCircle {
                 display: flex;
                 align-items: center;
                 flex-direction: row-reverse;
                 margin-left: 40px;

                 span {
                   margin: 0 0rem 0 -3rem;
                   text-align: left;
                 }

                 .circle {
                   transform: scaleX(-1);
                 }

                 &:hover {
                   span {
                     margin: 0 0rem 0 0.5rem;
                   }
                 }
               }
             }
            .buttonCircle {
              text-decoration: none;
              .circle{
                width:90px;
                height:90px;
              }
              span{
                font-size:24px;
                max-width:150px;
              }
              .cta-ltr-arrow {
                transform: scaleX(-1) translateX(-55px) !important;
                opacity: 0 !important;
                transition: 300ms ease-in-out transform;
              }

              &:hover {
                .cta-ltr-arrow {
                  opacity: 1 !important;
                  transform: scaleX(-1) translateX(-9px) !important;
                  transition: 300ms ease-in-out transform;
                }
              }
            }
          }
        }
      }
    }

    .footer__main {
      grid-column: 2/3;
      grid-row: 2/3;
      display: grid;
      grid-template-rows: 20px 40px 1fr 20px 33px;
      grid-template-columns:1fr;
      .top, .main, .bottom {
        grid-column: 1/2;
      }

      .top, .bottom {
        display:flex;
        align-items: center;
        justify-content: space-between;
      }

      .main {
        display:grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        .footer__content {
          grid-column: 1/2;
          grid-row: 1/2;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          h2 {
            font-size: 62px;
            color: white;
            line-height: 76px;
            width: 100%;
            text-align:center;
            margin: 0 auto;
            font-family: $fontSerif;
            margin-bottom: 30px;
          }
        }
      }

      .top {
        grid-row: 2/3;

        .footer__menu {
          list-style: none;
          display:flex;

          .nav-item {
            margin-right: 34px;
            &:last-child {
              margin-right: 0px;
            }

            &:hover {
              a {
                span {
                  opacity: 1;
                  transition: 200ms ease-in-out opacity;
                }
              }
            }

            a {
              span {
                color: white;
                font-size: 14px;
                opacity: 0.4;
                transition: 200ms ease-in-out opacity;
              }
            }
          }
        }
      }

      .main {
        grid-row: 3/4;
      }

      .bottom {
        grid-row: 4/5;

        .footer__links {
          list-style: none;
          display:flex;
          width:100%;

          .nav-item {
            margin-right: 34px;
            &:last-child {
              margin-right: 0px;
            }

            span {
              opacity: 0.5;
              color: white;
              font-size: 14px;
              transition: 200ms ease-in-out opacity;
            }

            &:hover {
                span {
                  opacity: 1;
                  transition: 200ms ease-in-out opacity;
                }

            }

            &.light {
              a {
                span {
                  opacity: 1;
                  transition: 200ms ease-in-out opacity;
                }
              }
            }

          }

          .social-links {
            margin-left:auto;
            a{
              svg {
                color: white;
              }
            }
          }
        }

        .footer__socials {
          a {
            color: white;
          }
        }
      }
    }

  }
}

@media screen and ( max-width: $deviceL ) {
  footer.footer {
    height: unset;

    &.home {
      .footer__container {
        display: flex;
        flex-direction: column;
        grid-template-rows: unset;
        grid-template-columns: unset;

      }
    }

    .footer__container {
      display: flex;
      flex-direction: column;
      grid-template-rows: unset;
      grid-template-columns: unset;

      .footer__hero {
        grid-column: 1/2;
        grid-row: 1/2;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        .footer__navigation {
          display:flex;
          align-items:center;
          justify-content:space-between;
          height: 100%;
          width: 100%;

          .btn-footernav {
            display: none;
          }

          .navigation {
            width: 100%;
            min-width: 100%;
            padding-top: 70px;
            padding-bottom: 70px;
            flex-direction: column;

            .navigation__item {
              width: 100%;
              justify-content: center;

              &:first-child {
                margin-bottom: 15px;
                justify-content: center;
                .buttonCircle {
                  margin-right: 0px;
                }
              }
              &:last-child {
                margin-top: 30px;
                justify-content: center;

                .buttonCircle {
                  margin-left: 0px;

                }
              }
              .buttonCircle {
                text-decoration: none;
                .cta-ltr-arrow {
                  transform: scaleX(-1) translateX(-55px) !important;
                  opacity: 0 !important;
                  transition: 300ms ease-in-out transform;
                }

                &:hover {
                  .cta-ltr-arrow {
                    opacity: 1 !important;
                    transform: scaleX(-1) translateX(-9px) !important;
                    transition: 300ms ease-in-out transform;
                  }
                }
              }
            }
          }
        }
      }

      .footer__main {
        display:flex;
        flex-direction: column;
        grid-column: unset;
        grid-row: unset;
        grid-template-rows: unset;
        grid-template-columns: unset;
        margin-left: 24px;
        margin-right: 24px;

        .top, .main, .bottom {
          grid-column: unset;
        }

        .top, .bottom {

          flex-direction: column;
        }

        .main {
          display: block;
          grid-template-columns: unset;
          grid-template-rows: unset;

          .footer__content {
            grid-column: 1/2;
            grid-row: 1/2;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-bottom: 70px;

            h2 {
              font-size: 38px;
              color: white;
              line-height: 48px;
              width: 100%;
              text-align:center;
              margin: 0 auto;
              font-family: $fontSerif;
              margin-bottom: 30px;
            }

            .buttonCircle {

            }
          }
        }

        .top {
          grid-row: unset;
          margin-bottom: 50px;

          .footer__menu {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 43px;
            justify-content: space-between;

            .nav-item {
              margin-right: 0px;
            }
          }

          .footer__newsletter {
            width: calc(100% - 0px);
            .form__newsletter {
              .form__newsletterContainer {
                display: flex;
              }
            }
          }


        }

        .main {
          grid-row: unset;
        }

        .bottom {
          grid-row: unset;
          margin-right:-24px;
          margin-bottom: 40px;
          margin-left:-24px;

          .footer__links {
            list-style: none;
            display: flex;
            align-items: center;
            /*flex-direction: column;*/
            margin-bottom: 25px;
            margin-left: -40px;
            margin-right: -40px;
            flex-wrap: wrap;

            .nav-item {
              padding:0 20px;
              margin:0 0 20px 0;
              /*margin-right: 0px;
              margin-bottom: 20px;
              &:last-child {
                margin-right: 0px;
              }*/
              display: flex;
              flex: 0 0 auto;
              align-self:flex-start;
              width: auto;
              max-width: 100%;

              span {
                color: white !important;
              }

              &.light {
                a {
                  span {
                    opacity: 1;
                  }
                }
              }

              &:hover {
                a {
                  span {
                    opacity: 1;
                  }
                }
              }

              a {
                span {
                  color: white;
                  font-size: 14px;
                  opacity: 0.6;
                }
              }
              button {
                span {
                  color: white;
                  font-size: 14px;
                  opacity: 0.6;
                }
              }
            }
            .social-links{
              align-self:flex-start;
                padding-right:20px;
            }
            .social-link {
              &:last-child{
              }
            }
          }
        }
      }

    }
  }
}