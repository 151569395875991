
@import '~bootstrap/scss/bootstrap.scss';

@import "_setup/variables.scss";
@import "_setup/reset.scss";
@import "_setup/layout.scss";

@import "_setup/mixins/typo.scss";
@import "_setup/mixins/layout.scss";
@import "_setup/mixins/text.scss";

@import "_setup/content.scss";

@import "transversals/glide.scss";
@import "transversals/header.navigation.scss";
@import "transversals/footer.scss";
@import "transversals/footer.navigation.scss";
@import "transversals/structure.scss";
@import "transversals/universes.scss";
@import "transversals/contacts.scss";
@import "transversals/navigation.scss";
@import "transversals/contact.modal.scss";


@import "components/PageHero.scss";
@import "components/Footer/footer.scss";
@import "components/articlesList.scss";
@import "components/detailClose.scss";
@import "components/preloader.scss";
@import "components/figures.scss";

@import "partials/buttonCircle.scss";
@import "partials/socials.scss";
@import "partials/formNewsletter.scss";
@import "partials/media.scss";
@import "partials/filterSelect.scss";


@import "pages/common.scss";
@import "pages/home.scss";
@import "pages/universes.scss";
@import "pages/brands.scss";
@import "pages/expertises.scss";
@import "pages/expertises.detail.scss";
@import "pages/news.scss";
@import "pages/genheader";
@import "pages/blobsHeader";
@import "pages/jobs";
@import "pages/blog.home.scss";
@import "pages/about.home.scss";