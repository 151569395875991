@mixin section-content-title {
  font-family: $fontSerif;
  font-size: 62px;
  color: $dark;
  line-height: 76px;
}

@mixin section-content-title-second {
  font-family: $fontSerif;
  font-size: 46px;
  color: $dark;
  line-height: 58px;
}

@mixin section-content-paragraphe {
  font-family: $fontText;
  font-size: 16px;
  color: $cleargray;
  line-height: 30px;
}

@mixin section-keydata-title {
  font-family: $fontText;
  font-size: 32px;
  color: $dark;
  line-height: 44px;
  font-weight: 600;
}

@mixin section-keydata-subtitle {
  font-family: $fontText;
  font-size: 16px;
  color: $dark;
  line-height: 30px;
}

@mixin section-keydata-light {
  font-family: $fontText;
  font-size: 16px;
  color: $cleargray;
  line-height: 22px;
}

@mixin section-keydata-xs {
  font-family: $fontText;
  font-size: 12px;
  color: $cleargray;
  line-height: 27px;
}

@mixin section-grid-xs {
  font-family: $fontText;
  font-size: 12px;
  color: $cleargray;
  line-height: 20px;
}

@mixin section-content-step {
  font-family: $fontText;
  font-size: 14px;
  color: $cleargray;
  line-height: 28px;
  font-weight: 500;
}

@mixin expertise-list-title {
  font-family: $fontText;
  font-size: 14px;
  color: $cleargray;
  line-height: 24px;
}

@mixin edito-controleur-title {
  font-family: $fontText;
  font-size: 20px;
  color: $dark;
  line-height: 28px;
}

@mixin edito-content-item-title {
  font-family: $fontText;
  font-size: 20px;
  color: $cleargray;
  line-height: 38px;
}


@mixin articlelist-seasonal-brand {
  font-family: $fontText;
  font-size: 16px;
  color: $dark;
  line-height: 28px;
}

@mixin xs-nav-link {
  font-family: $fontText;
  font-size: 22px;
  line-height: 30px;
}



@mixin mob-title-1 {
  font-family: $fontSerif;
  font-size: 28px;
  line-height: 38px;
}

@mixin mob-title-2 {
  font-family: $fontSerif;
  font-size: 38px;
  line-height: 48px;
}

@mixin mob-txt-1 {
  font-family: $fontText;
  font-size: 16px;
  line-height: 32px;
}
