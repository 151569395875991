.universes-homepage{
	//padding-top: 7rem;

	//padding-top: 7rem;
	background-color: $bgPage;

	.universes__introduction {
		margin-top: 146px;
		margin-bottom: 200px;

		.section__title {
			grid-column: 1/6;
		}
		.section__content {
			grid-column: 7/13;
		}
	}

	.universes__keyfigures {
		padding-top: 134px;
		padding-bottom: 100px;
		border-bottom: 1px solid rgba(186, 181, 193, 0.21);
	}


	.universes__list {
		margin-top: 115px;
		margin-bottom: 150px;
		grid-column: 1/13;
		grid-row: 2/3;
		display: grid;
		justify-content: space-between;
		grid-column-gap: 7.36vw;
		grid-row-gap: 140px;
		grid-template-columns: repeat(3,1fr);
		padding-left: 4.79vw;
		padding-right: 4.79vw;

		.colItems-1 {
			transform: translateY(-100px);
		}

		.colItems-2 {
			transform: translateY(100px);
		}

		.colItems-3 {
			transform: translateY(0px);
		}

		.item {
			display:block;
			margin-bottom: 200px;

			a {

				&:hover {
					.univers__list__container {
						.item__brands {
							opacity:1;
							transition: 300ms ease-in-out opacity;
						}

						.overlay {
							opacity:0.4;
							transition: 300ms ease-in-out opacity;
						}
					}
				}

				.item__name {
					font-family: $fontText;
					font-size: 32px;
					line-height: 38px;
					color: $dark;
					margin-bottom: 24px;
					width: 50%;
				}

				.univers__list__container {
					position: relative;


					.item__brands {
						position: absolute;
						top: 78px;
						left: 94px;
						bottom: 78px;
						right: 94px;
						z-index:5;
						display:flex;
						flex-direction:column;
						align-items:center;
						justify-content:space-between;
						opacity:0;
						transition: 300ms ease-in-out opacity;

						.universeBrandsItem {
							width:100%;

							.media {
								color: white;

								svg {
									width: 100%;
								}
							}
						}

					}

					.overlay {
						position: absolute;
						width: 100%;
						height: 100%;
						top: 0px;
						left: 0px;
						z-index: 2;
						background-color: black;
						opacity:0;
						transition: 300ms ease-in-out opacity;

					}
				}

				.item__media {
					width: 100%;
					padding-top: 155%;
					position:relative;

					.media {
						position:absolute;
						top:0px;
						left: 0px;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}

@media screen and ( min-width: $deviceXl  ) {
	.universes-homepage .universes__introduction .section__content {
		grid-column: 8/12;
	}
}

@media screen and (max-width: $deviceL) {
	.universes-homepage{
		//padding-top: 7rem;

		//padding-top: 7rem;
		.universes__introduction {
			margin-top: 70px;
			margin-bottom: 70px;
			display:flex;
			flex-direction: column;

			.section__title {
				grid-column: unset;
				margin-bottom: 35px;
			}
			.section__content {
				grid-column: unset;
				margin-right: 0px;
			}
		}

		.universes__keyfigures {
			padding-top: 0px;
		}

		.universes__list {

			margin-top: 0px;
			grid-column: unset;
			grid-row: unset;
			width: unset;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			grid-column-gap: 0px;
			grid-row-gap: 0px;
			grid-template-columns: unset;
			grid-auto-columns: unset;

			.colItems-1 {
				transform: translateY(0px);
			}

			.colItems-2 {
				transform: translateY(0px);
			}

			.colItems-3 {
				transform: translateY(0px);
			}

			.item {
				margin-bottom: 60px;

				a {
					.item__name {
						font-size: 24px;
						line-height: 28px;
						width: 100%;
					}

					.item__media {
						width: 100%;
						padding-top: 155%;
						position:relative;

						.media {
							position:absolute;
							top:0px;
							left: 0px;
							width: 100%;
							height: 100%;
						}
					}
				}
			}

		}
	}
}
