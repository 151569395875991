
body{
	width: 100vw;
	overflow-x: hidden;
	

	&.locked{
		height:100vh !important;
		overflow:hidden !important;
	}
}

.cursor{
	display: none;
}

.cookieConsent{
	svg,
	svg *{
		fill:$white!important;
	}		
}

.unify {
  font-family: $fontText;
	position : relative;

	&.hidden {
		display: none;
	}

}

.content{

}

button.cta{
	margin:0;
	padding:0;
	background:none;
	border:none;
	outline: none;
}

.cta{

	cursor:pointer;

	.circle{
		position: relative;
		z-index: 0;
		top:0;
		right:0;
		display: inline-block;
		width:3.75rem;
		height:3.75rem;
		vertical-align: middle;

		.arrow{
			position: absolute;
			z-index: 1;
			top:50%;
			left:50%;
			display: block;
			margin:-.75rem 0 0 0rem;
			height:1.5rem;
			width:2rem;
			opacity:0;
			transition: all 250ms ease-in-out;
			transform: scaleX(-1);
		}

		.circlesvg{
			svg{

				.cta-ltr-circle,
				.cta-circle{
					transform: rotate(-135deg);
					transform-origin: center;
				}
			}
		}

		.off,
		.on{
			position: absolute;
			top:0;
			right:0;
			transition:all 500ms ease-in-out;
		}

		.off{
			opacity:1;
		}

		.on{
			opacity:0;
		}

	}

	span{
		display: inline-block;
		margin:0 -2rem 0 0;
		vertical-align: middle;
		font-family: $fontText;
		color:$dark;
		font-weight:600;
	}
}

.zone-blob{
	position: relative;
	z-index: -1;
	display:block;
	margin:0 -1.5rem;

	&.block-l{
		padding:0;
		max-width:100%;
	}
}

.blobs{
	position: absolute;

	img,
	svg{
		height:auto;
	}


	.blob{
		position: absolute;
		top:0;

		&.layer1{
			z-index: 3;

		}

		&.layer2{
			z-index: 2;
		
		}

		&.layer3{
			z-index: 1;
		}
	}
}

.block-l{		
	max-width:80rem;
	padding:0 1.5rem;
/*	padding-top:100px;
	background-color: $white;*/
}
.block{		
	padding-left:1.5rem;
	padding-right:1.5rem;
}

.page{

	.zone-blob{
		margin:0 -1.5rem 11.3125rem -1.5rem;
	}

	.header{
		position:relative;

		.navigation{
			left:0;
			top:0;
			width: 100%;
			margin:.84375rem 0 0 0;
			padding:0;
			font-size:0;
			white-space:nowrap;

			.cta{
				display:inline-block;
				font-size:0;
				width:1.625rem;
				height:1.625rem;
				&.previous{
					&:hover{
						.arrow{
							svg{
								margin:0 0 0  -.5rem;
							}
						}
					}
				}
				&.next{
					@media screen and ( min-width:980px) {
						&:hover{
							.arrow{
								svg{
									margin:0 -.5rem 0 0;
								}
							}
						}
					}
				}
			}
			.arrow{
				svg{
					width:.875rem;
					height:1.625rem;
					transition:all 250ms ease-in-out;
				}
			}
		}

	}

	.end-block{
		margin:0 0 14rem 0;

		.navigation{
			margin:0;
			padding:0;
			li{
				padding:0;
				list-style: none;
				
				.cta{
					display:inline-block;
					color:$dark;
					white-space:nowrap;

					.circle{
						width:7rem;
						height:7rem;

						svg{
							width:auto;
							height:7rem;
						}
						.arrow{
							margin:-1rem 0 0 0rem;
							height:2rem;
							width:3.5rem;
							transform: scaleX(1);
							svg{
								height: 2rem;
							}
						}
					}
					span{
						margin:0;
					}
					.text{
						display: inline-block;
						
					}
					.label{
						display: block;
						font-family: $abitare50;
						font-size:.875rem;
						line-height: 1em;
						letter-spacing:2px;
						text-transform: uppercase;
					}
					.name{
						display: block;
						font-family: $crimson;
						font-size:2em;
						line-height:2rem;
						text-transform: lowercase;
						text-transform: capitalize;
					}
				}

				&.previous{
					text-align:right;

					.text{
						margin:0 -3.5rem 0 0;
					}
				}

				&.next{
					margin-top:3.125rem;
					text-align:left;


					.circle{
						transform: scaleX(-1);
					}

					.text{
						margin:0 0 0 -3.5rem;
					}
				}
			}

			li.previous {
				.cta {
					.text, span {
						transition-delay:200ms !important;
						transition:300ms ease-in-out all;
					}
				}
				@media screen and ( min-width: 960px ) {
					.cta:hover {
						.text, span {
							margin: 0 .5rem 0 0;
							transition-delay:0s !important;
							transition:300ms ease-in-out all;
						}
					}
				}

			}

			li.next {
				.cta {
					.text, span {
						transition-delay:200ms !important;
						transition:300ms ease-in-out all;
					}
				}
				@media screen and ( min-width: 960px ) {
					.cta:hover {
						.text, span {
							margin: 0 0 0 .5rem;
							transition-delay:0s !important;
							transition:300ms ease-in-out all;
						}
					}
				}

			}

		}
	}
}

.steps{
	position: relative;
	margin:0 0 5rem 0;
	padding:0 1.6875rem;
	/*max-width:71.25rem;*/

	.label-side{
		position: absolute;
		right:100%;
		top:0;
		z-index: 1;
		display:block;
		margin:0;
		width:100%;
		font-family: $abitare50;
		font-size:.75rem;
		line-height:.8125rem;
		color:$middlegray;
		text-transform: uppercase;
		text-align:right;
		letter-spacing: .125rem;
		transform: rotate(-90deg);
		transform-origin: top right;
	}
}

.keyfigures{

	.list{
		margin:0 -1.6875rem;
	}
	.item{
		margin-bottom:1.5rem;
		padding:0 1.6875rem;
		text-align: center;

		.text{
			display: inline-block;
			text-align: center;
		}

		.figure{
			transition:300ms ease-in-out all;
			margin:0 0 .75rem 0;
			font-family: $abitare800;
			font-size: 4.5rem;
			line-height: 4.9375rem;
			/*white-space: nowrap;*/
			background: linear-gradient(30deg,$dark 10%, $dark 90%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			transform: all 250ms ease-in-out;
		}

		.label{
			margin:0 0 .25rem 0;
			font-family: $abitare350;
			font-size: 1.125rem;
			line-height: 1rem;
			font-weight:bold;
		}

		.description{
			margin:0 0 .25rem 0;
			font-family: $abitare50;
			font-size: 1rem;
			line-height: 1.125rem;
		}

		.source{
			font-family: $abitare50;
			font-size: .75rem;
			line-height: 1rem;
		}
	}
}

h1.title{
	margin-left:-.25rem;
	margin-bottom:1.5rem;
	font-family: $abitare1000;
	font-size:3rem;
	line-height:3.25rem;
}

@media screen and (min-width: $deviceMd) {
	.cta{
		
		span{
			transition:all 250ms ease-out 180ms;
		}
		.text{
			transition:all 250ms ease-out 240ms;
			span{
				transition:all 250ms ease-out 180ms;
			}
		}
		&:hover{
			cursor:pointer !important;
			span{
				margin:0 .5rem 0 0;
				color:$color;
			}	
			.text{
				transition:all 200ms ease-out 0s;
				transition-delay:80ms;
			}	
		}
	}

	.zone-blob{
		margin:0 auto;

		&.block-l{
			max-width:1280px;
		}
	}

	h1.title{
		margin-bottom:6rem;
		max-width:32rem;
		font-size:4.0635rem;
		line-height:4.25rem;
	}
	.page{

		.zone-blob{
			margin:0 auto 13.3125rem auto;
		}


		.header{

			.navigation{
				margin:1.6875rem 0 0 0;
			}

		}

		.end-block{
			margin:0 0 5rem 0;

			.navigation{
				margin:0 -2.5rem;
				padding:0;
				li{

					padding:0 2.5rem;
					.cta{
						transition: all 250ms ease-in-out;

						&:hover{
							text-decoration:none;
							color:$color;

							.label{
								color:$dark;
							}

							.circle{
								.arrow{
									margin:-1rem 0 0 -1.75rem;
									opacity:1;
								}
							}
						}

						.circle {
							width:7rem;
							height:7rem;

							svg{
								width:auto;
								height:7rem;
							}
							.arrow{
								margin:-1rem 0 0 0rem;
								height:2rem;
								width:3.5rem;
								svg{
									height: 2rem;
								}
							}
						}
						.label{
							font-size:.875rem;
							line-height: 1em;
							letter-spacing:2px;
						}
						.name{
							font-size:3em;
							line-height:3rem;
						}
					}
					&.previous{
						.text{
							margin:0 -3.5rem 0 0;
						}

						.cta{
							&:hover{
								.text{
									margin:0 .5rem 0 0;
								}
							}
						}

					}

					&.next{
						margin-top:10.125rem;
						.text{
							margin:0 0 0 -3.5rem;
						}

						.cta{
							&:hover{
								.text{
									margin:0 0 0 .5rem;
								}
							}
						}
					}
				}

			}
		}

	}

	.steps{
		margin:0 0 5rem 0;
		padding:0 4.375rem;
		/*max-width:71.25rem;*/

		.label-side{
			width:50%;
			font-size:.875rem;
			line-height:1rem;
			letter-spacing: .125rem;
		}
	}

	.keyfigures{

		.list{
			margin:0 -2.5rem;
		}
		.item{
			margin-bottom:2.28125rem;
			padding:0 2.5rem;
			display:flex;

			&:nth-child(odd){
				flex-direction:row-reverse;
			}

			&:nth-child(even){
				flex-direction:row;
				margin-top:5.5625rem;
			}

			.figure{
				transition:300ms ease-in-out all;
				margin:0 0 .75rem 0;
				font-size: 4rem;
				line-height: 4.25rem;
			}

			.label{
				margin:0 0 .25rem 0;
				font-size: 1.125rem;
				line-height: 1.5rem;
			}

			.description{
				margin:0 0 .25rem 0;
				font-size: 1rem;
				line-height: 1.125rem;
			}

			.source{
				font-size: .75rem;
				line-height: 1rem;
			}

			&:hover{

				.figure{
					transition:300ms ease-in-out all;
					background: linear-gradient(30deg,#7225f1 10%, #8e38ed 35%, #a43bed 50%, #bf35c9 65%, #f02988 90%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
		}
	}
}


@media screen and (max-width: $deviceLg){
}

@media screen and (min-width: $deviceLg) {

	body{
		//:none !important;
	}

	a,
	button,
	input,
	select,
	textarea{
		&:hover{
			//cursor:none;
		}
	}

	.cursor{
		display:none;

		opacity:0;
		position:absolute;
		top:-1000rem;
		left:-1000rem;
		z-index: 10000000;

		font-size:0;
		line-height:0;

		&.active {
			opacity:1;
			transition:400ms ease-in-out opacity 300ms;
		}

		&.slider {
			.cursor-slider-left {
				#arrowLeft {
					opacity:1;
					transition:300ms ease-in-out opacity;
				}
			}

			.cursor-slider-right {
				#arrowRight {
					opacity: 1;
					transition: 300ms ease-in-out opacity;
				}
			}
		}

		.cursor-circle{
			display:none;
			font-size:0;
			line-height:0;
			svg{
   				position: fixed;
   				top:-1.25rem;
   				left:-1.25rem;
			    z-index: 20000;
				width:2.5rem;
				height:2.5rem;
			    user-select: none;
			    pointer-events: none;
			}
		}
		.cursor-dot{
			display:none;
			font-size:0;
			line-height:0;
			svg{
   				position: fixed;
   				top:-1.25rem;
   				left:-1.25rem;
			    z-index: 20000;
				width:2.5rem;
				height:2.5rem;
			    user-select: none;
			    pointer-events: none;
			}
		}

		.cursor-slider-left {
			#arrowLeft {
				position: fixed;
				top: -6px;
				left: -42px;
				z-index: 20000;
				-webkit-user-select: none;
				user-select: none;
				pointer-events: none;
				width: 12px;
				height: 12px;
				stroke-width: 0px;
				opacity:0;
				transition:300ms ease-in-out opacity;
			}
		}

		.cursor-slider-right {
			#arrowRight {
				position: fixed;
				top: -6px;
				left: 30px;
				z-index: 20000;
				-webkit-user-select: none;
				user-select: none;
				pointer-events: none;
				width: 12px;
				height: 12px;
				stroke-width: 0px;
				opacity:0;
				transition:300ms ease-in-out opacity;
			}
		}
	}

	.block{		
		max-width:71.25rem;
	}

	.page{

		.header{

			.navigation{

				.cta{
					&.previous{

					}
					&.next{
						.arrow{
							svg{
								.universe-arrow-right-grad{
									fill:$white!important;
								}
							}
						}
					}
				}
			}

		}

	}

	.keyfigures{

		.item{
			.figure{
				font-size: 6rem;
				line-height: 6.625rem;
			}
		}
	}


}

@media screen and ( min-width: $deviceMd) {
	.title-reveal {
		transform:translateY(30px);
		opacity:0;
		transition:300ms ease-in-out all;

		&.visible {
			transform:translateY(0px);
			opacity:1;
			transition:300ms ease-in-out all;
			transition-delay:500ms
		}
	}
}

.image.imageLzy {
	display:block;
	position:relative;
	&:before {
		content:'';
		position:absolute;
		top:0px;
		left:0px;
		width:100%;
		height:100%;
		background-color:red;
	}
}
.image.imageLazy.loading {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.imageParentLoading:after {
	content: '';
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	background-color: red;
	z-index: 3;
	position: absolute;
}

.animatedLink{
	display: inline-block;
	position:relative;
	span{
		display: block;
		margin:0 0 0rem 0;
	}

	&:after{
		content:'';
		display: block;
		position:absolute;
		bottom:0px;
		width:100%;
		height:0px;
		border-style: solid;
		border-bottom-width: 0;
		border-image-source: linear-gradient(to right, rgba(114, 37, 241, 0.99), #8e38ed 21%, #a43bed 48%, #bf35c9 75%, #f02988);
		border-image-slice: 1;
		transform:scaleX(0);
		transform-origin:left;

	}

	&:before {
		content:'';
		display: block;
		position:absolute;
		bottom:0px;
		width:100%;
		height:0px;
		border-style: solid;
		border-bottom-width: 0;
		border-image-source: linear-gradient(to right, rgba(114, 37, 241, 0.99), #8e38ed 21%, #a43bed 48%, #bf35c9 75%, #f02988);
		border-image-slice: 1;
		opacity:0;
	}

	&.visible {
		&:after{
			transform:scaleX(1);
			transform-origin:left;
			transition:500ms ease-in all;

		}

		&:before {
			opacity:1;
			transition-delay:500ms;
		}
	}

	&:hover {
		&:after {
			animation-name: adresseafter;
			animation-duration: 1s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			animation-delay:0s;
		}

		&:before{
			animation-name: adresseBefore;
			animation-duration: 1s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			animation-delay:0.2s;
		}
	}
}

@media screen and (min-width: $deviceMd) {
	.animatedLink {
		span {
			margin: 0 0 0rem 0;
		}
	}
}
