.section {
  display:grid;
  grid-template-columns: repeat(12, 60px);
  justify-content: space-between;
  margin-left: 7.63vw;
  margin-right: 7.63vw;

  &.fullWidth {
    margin-left: 0%;
    margin-right: 0%;

    &.containered {
      padding-left: 8vw;
      padding-right: 8vw;
    }

    .contained {
      margin-left: 8vw;
      margin-right: 8vw;
    }
  }
}

.page {
  width: 100%;
  padding: 0px;
}

@media screen and ( max-width: $deviceL ) {
  .section {
    grid-template-columns: repeat(6, 26px);
    margin-left: 24px;
    margin-right: 24px;

    &.fullWidth {
      margin-left: 0%;
      margin-right: 0%;

      .contained {
        margin-left: 10%;
        margin-right: 10%;
      }
    }
  }
}