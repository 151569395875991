.header__page {
  width:100%;
  margin:0px;
  overflow-x:hidden;
  display:block;
  position:absolute;
  top:13.3rem;
  left:0px;
  height:90px;
  display:block;

  @media screen and ( max-width:$deviceMd) {
    //display:none;
  }

  &.active {
    opacity:1;
  }
  &.hidden-b {
    opacity:0;
  }

  .blobs {
    z-index: -1;
    top: 0;
    left: initial;
    right: -41rem;
    width: 94.5rem;

    .blob.layer1 {
      left: 3.22%;
      width: 96.78%;
    }

    .blob.layer2 {
      left: 1.01%;
      width: 98.99%;
    }
    .blob.layer3 {
      left: 0;
      width: 100%;
    }
  }

  .contain {
    width:100%;
    position:relative;
  }
  .titles {
    list-style:none;
    padding:0px;
    height:70px;
    position:relative;
    overflow:hidden;
    li {
      position:absolute;
      top:0px;
      left:0px;
      transform:translateY(70px);
      font-size: 4.0635rem;
      line-height: 4.25rem;
      font-family: "Abitare_1000", helvetica, Arial, sans-serif;

      .char {
        transform:translateY(70px);
        opacity:0;
      }
    }
  }

  .zone-blobs {
    height : 13.3125rem;
    width:100%;
  }
}