.detail__close {
  width: 100%;
  padding-top: 46px;
  padding-bottom: 75px;
  display:flex;
  align-items: center;
  justify-content: center;

  .close__page {
    width: 42px;
    height: 42px;
    font-size:0;
    background-color: $color;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition:all 250ms ease-in-out;

    &:hover{
      transform:scale(1.125) rotate(180deg);
    }
  }
}

@media screen and (max-width: $deviceL) {
  .detail__close {
    padding-top: 70px;
    padding-bottom: 0px;
  }
}