.socials__list {
  display:flex;
  align-items:center;
  justify-content: flex-start;

  &:hover {
    svg {
      opacity: 0.4;
      transition: 200ms ease-in-out opacity;
    }
  }


  li {
      width: 16px;
      height: 16px;
      margin-right: 16px;

      &:hover {
        svg {
          opacity: 1;
          transition: 200ms ease-in-out opacity;
        }
      }

      &:last-child {
        margin-right: 0px;
      }

      svg {
        width: 16px;
        height: 16px;
        transition: 200ms ease-in-out opacity;
      }
    }

  
}
