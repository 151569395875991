.filter__select {
  width: 100%;
  position:relative;
  border: 1px solid $lightGrey;
  border-radius: 30px;
  position: relative;
  background-color: white;

  &.closed {
    .filter__action {
      .toggle {
        outline: 0px;
        .arrow {
          transform: rotate(0deg);
          transition: 200ms ease-in-out transform;
        }
      }
    }
    .filters_list {
      overflow:hidden;
      margin-bottom: 0px;
      height: 0px;
      transition: 200ms ease-in-out height;

    }
  }

  .filter__action {
    display:flex;
    justify-content: space-between;
    margin:3px 10px;
    .current {

      cursor: pointer;
      position: relative;
      overflow:hidden;
      height: 28px;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 20px;

      span {
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        left: 0px;
        height: 100%;
        font-family: $fontText;
        font-size: 14px;
        line-height: 28px;
        color:$dark;
      }
    }

    .toggle {
      outline: 0px;
      .arrow {
        transform: rotate(180deg);
        transition: 200ms ease-in-out transform;
      }
    }
  }

  .filters_list {

    display: block;
    height: fit-content;
    transition: 200ms ease-in-out height;
    margin:10px;
    margin-top: 0px;

    li {
      font-family: $fontText;
      font-size: 14px;
      line-height: 28px;
      color:$dark;
      cursor : pointer;
      padding: 5px 20px;

      &.hidden {
        display: none;
      }
    }
  }


}