.page.expertises{
	//padding-top: 7rem;
	h1.title {
		opacity:0;
	}

	.expertises__introduction {
		margin-top: 146px;
		padding-bottom: 86px;
		border-bottom: 2px solid $lightGrey;
		margin-bottom: 100px;

		.section__title {
			grid-column: 1/6;
		}
		.section__content {
			grid-column: 7/12;
			margin-right: 18%;

			strong {
				color: $dark;
			}
		}
	}

	.introduction{
		margin:0 -3.625rem 1.8125rem;
		.catch,
		.text{
			padding:0 3.625rem;
		}
		.catch{
			font-family: $crimson;
			font-size: 1.5rem;
			line-height:2rem;
			font-weight: bold;
		}
		.text{
			font-family: $abitare50;
			font-size:1rem;
			line-height:1.75rem;
		}
	}

	.expertises__list {
		display: grid;
		grid-template-columns: repeat(12,60px);
		justify-content: space-between;
		grid-row-gap: 100px;
		margin: 100px 8% 155px;

		.item__expertiseItem {
			grid-column: 3 span;
			background-color: transparent;
			transition: 300ms ease-in-out background-color;
			padding: 10px;

			&:hover {

				.item__expertise {

					.media__container{
						border:2px solid $middlegray;
					}

					.visual {
						border:2px solid $cleargray;
						transition: 300ms ease-in-out border;
					}
				}
			}


		}


		.item__expertise {
			display: flex;
			align-items:center;
			justify-content: center;
			flex-direction: column;

			.media__container{
				display:flex;
				width: 120px;
				height: 120px;
				position:relative;
				border-radius: 50%;
				border:2px solid $lightGrey;
				margin-bottom: 42px;
				margin-left: auto;
				margin-right: auto;
				box-sizing: border-box;
				justify-content:center;
				align-items:center;
				transition:border 250ms ease-in-out;
			}

			.visual {
				width: 100px;
				height: 100px;
				border-radius: 50%;
				border:2px solid $lightGrey;
				position:relative;
				margin-bottom: 30px;
				transition: 300ms ease-in-out border;

				img {
					display: block;
					max-width:50px;
					max-height: 50px;
					position:absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
				}
			}

			.name {
				font-family: $fontText;
				font-size: 20px;
				line-height: 38px;
				color: $dark;
				text-align:center;
			}

			.description {
				@include section-grid-xs;
				margin-top: 42px;
				text-align: center;

				p{
					font-size: 14px;
					line-height: 17px;
					
				}
			}
		}
	}

	.list{
		margin:0 -2.4625rem;

		.item{
			padding:0 2.4625rem 2.4625rem;

			&:hover {

				.visual {

					.icon {
						top:-6%;
						transition:300ms ease-in-out all;
					}
					img.iconshadow {
						opacity:0.3;
						transition:300ms ease-in-out all;
					}

				}

			}

			.visual{
				position: relative;
				display: block;
				margin:0 auto;
				padding:60% 0 0 0;
				width:50%;


				img{
					position: absolute;
					top:0;
					left:0;
					width:100%;
					height:auto;

					&.icon{
						z-index: 2;
						transition:300ms ease-in-out all;
					}

					&.iconshadow{
						z-index: 1;
						opacity:.6;
						transition:300ms ease-in-out all;
					}
				}
			}

			.name{
				display: block;
				margin:0 0 .75rem 0;
				font-family: $crimson;
				font-size: 1.9375rem;
				line-height:2.6875rem;
				font-weight: 600;
			}

			.list-lines{
				display: block;
				ul{
					display: block;
					margin:0;
					padding:0;
				}

				.list-item{
					margin:0 0 .25rem 0;
					list-style: none;

					&:before{
						content:'';
						display: inline-block;
						margin:0 1rem 0 0;
						width:.875rem;
						height:.875rem;
						border:double 4px  transparent;
						background-image: linear-gradient(white, white), linear-gradient(30deg,#f02988 10%, #bf35c9 35%, #a43bed 50%, #8e38ed 65%, #7225f1 90%);
						/*border-image-slice: 1;*/
						border-radius:.4375rem; 
						background-origin: border-box;
						background-clip: content-box, border-box;
						vertical-align: middle;
					}

					.list-text{
						display: inline-block;
						font-family: $abitare50;
						font-size:1rem;
						line-height:1.25rem;
						vertical-align: middle;
					}

				}
			}
		}
	}
}

@media screen and ( min-width: $deviceXl  ) {
	.page.expertises .expertises__introduction .section__content {
		grid-column: 8/12;
		margin-right: 0%;
	}
}






@media screen and (max-width: $deviceL) {
	.page.expertises{
		//padding-top: 7rem;
		h1.title {
			opacity:0;
		}

		.expertises__introduction {
			padding-top: 70px;
			margin-top: 0px;
			padding-bottom: 70px;
			border-bottom: 2px solid $lightGrey;
			margin-bottom: 70px;
			display:flex;
			flex-direction: column;

			.section__title {
				grid-column: unset;
				margin-bottom: 35px;
			}
			.section__content {
				grid-column: unset;
				margin-right: 0px;
			}
		}

		.introduction{
			margin:0 -3.625rem 1.8125rem;
			.catch,
			.text{
				padding:0 3.625rem;
			}
			.catch{
				font-family: $crimson;
				font-size: 1.5rem;
				line-height:2rem;
				font-weight: bold;
			}
			.text{
				font-family: $abitare50;
				font-size:1rem;
				line-height:1.75rem;
			}
		}

		.expertises__list {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
			grid-column-gap: 40px;
			grid-row-gap: 40px;
			margin: 0px 24px 70px;

			.item__expertise {
				display: flex;
				align-items:center;
				justify-content: center;
				flex-direction: column;

				.visual {
					width: 100px;
					height: 100px;
					border-radius: 50%;
					border:2px solid $lightGrey;
					position:relative;
					margin-bottom: 30px;

					img {
						display: block;
						max-width:50px;
						max-height: 50px;
						position:absolute;
						top: 50%;
						left: 50%;
						transform: translateX(-50%) translateY(-50%);
					}
				}

				.name {
					font-family: $fontText;
					font-size: 20px;
					line-height: 38px;
					color: $dark;
				}
			}
		}

		.list{
			margin:0 -2.4625rem;

			.item{
				padding:0 2.4625rem 2.4625rem;

				&:hover {

					.visual {

						.icon {
							top:-6%;
							transition:300ms ease-in-out all;
						}
						img.iconshadow {
							opacity:0.3;
							transition:300ms ease-in-out all;
						}

					}

				}

				.visual{
					position: relative;
					display: block;
					margin:0 auto;
					padding:60% 0 0 0;
					width:50%;


					img{
						position: absolute;
						top:0;
						left:0;
						width:100%;
						height:auto;

						&.icon{
							z-index: 2;
							transition:300ms ease-in-out all;
						}

						&.iconshadow{
							z-index: 1;
							opacity:.6;
							transition:300ms ease-in-out all;
						}
					}
				}

				.name{
					display: block;
					margin:0 0 .75rem 0;
					font-family: $crimson;
					font-size: 1.9375rem;
					line-height:2.6875rem;
					font-weight: 600;
				}

				.list-lines{
					display: block;
					ul{
						display: block;
						margin:0;
						padding:0;
					}

					.list-item{
						margin:0 0 .25rem 0;
						list-style: none;

						&:before{
							content:'';
							display: inline-block;
							margin:0 1rem 0 0;
							width:.875rem;
							height:.875rem;
							border:double 4px  transparent;
							background-image: linear-gradient(white, white), linear-gradient(30deg,#f02988 10%, #bf35c9 35%, #a43bed 50%, #8e38ed 65%, #7225f1 90%);
							/*border-image-slice: 1;*/
							border-radius:.4375rem;
							background-origin: border-box;
							background-clip: content-box, border-box;
							vertical-align: middle;
						}

						.list-text{
							display: inline-block;
							font-family: $abitare50;
							font-size:1rem;
							line-height:1.25rem;
							vertical-align: middle;
						}

					}
				}
			}
		}
	}
}