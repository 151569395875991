.brands-detail {
  display: inherit;

  .blobs {
    z-index: -1;
    top: -4rem;
    left: -7rem;
    width: 45rem;

    .blob {
      &.layer1 {
        left: 2.09%;
        width: 97.91%;
      }

      &.layer2 {
        left: 0;
        width: 100%;


      }

      &.layer3 {
        left: .1%;
        width: 99.90%;

      }
    }

  }

  .steps {
    &.header {
      margin-bottom: 1.8125rem;
    }
  }

  .logo {
    margin: 0;
    font-size: 14.75rem;
    line-height: 0;
    color: $dark;

    svg {
    }
  }

  .detail__close {
    padding-bottom: 0px;
  }

  .toggle__container {
    display: flex;
    flex-direction: column;
  }

  .latest__devices {
    padding-top: 90px;
    padding-bottom: 150px;
  }

  .seasonal__offer {
    padding-top: 100px;
    padding-bottom: 110px;
    background-color: $lightGrey;
  }

  .content__keydata {
    padding-top: 100px;
    padding-bottom: 113px;
    border-bottom: 2px solid $lightGrey;
  }

  .introduction {
    padding-bottom: 112px;
    padding-top: 100px;
    border-bottom: 2px solid $lightGrey;

    .introduction__infos {
      grid-column: 1/5;
      display: grid;
      grid-template-columns: repeat(4, 60px);
      grid-template-rows: 100px 100px;
      justify-content: space-between;
      grid-row-gap: 25px;

      .introduction__info {
        grid-column: span 2;
        height: 100px;

        .title {
          @include section-content-step;
          color: $chaire;
          text-transform: uppercase;
        }

        .data {
          @include articlelist-seasonal-brand;
          color: $cleargray;
          margin-top: 7px;
        }

        .socials__brand {
          margin-top: 7px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: $cleargray;

          .social-item {
            margin-left: 16px;

            a {
              color: $cleargray;
            }

            &:first-child {
              margin-left: 0px;
            }
          }
        }
      }

    }

    .introduction__content {
      grid-column: 7/13;
      /*margin-right: 60px;*/

      span {
        color: $dark;
      }

    }
  }

  .collaboration {
    padding-top: 80px;
    padding-bottom: 80px;
    border-bottom: 0px;
    border-top: 0px;

    .section__title {
      text-transform: uppercase;
      color: $chaire;
      margin-bottom: 51px;
    }

    .section__content {
      grid-column: 7/12;
      grid-row: 1/3;
    }

    .details-contact {
      grid-column: 1/6;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
      grid-column-gap: 20px;
      grid-row-gap: 50px;

      .contact-item {
        margin-bottom: 0px;

        &:first-child {
          margin-right: 10px;
        }

        .contact-position {
          text-transform: uppercase;
          margin-bottom: 7px;
          @include section-content-step;
        }

        .contact-name {
          color: $color;
        }

        .contact-email {
          color: $dark;
          text-decoration: underline;
        }
      }
    }
  }

  .details {
    padding-top: 80px;
    padding-bottom: 145px;
    border-bottom: 2px solid $lightGrey;


    .details-expertises {
      grid-column: 1/13;
      display: grid;
      grid-template-columns: repeat(12, 60px);
      justify-content: space-between;


      .expertise-item {
        grid-column: span 6;
        margin-right: 60px;

        margin-bottom: 46px;

        &:last-child {
          margin-bottom: 0px;
        }

        .expertise-name {
          @include section-content-step;
          text-transform: uppercase;
          margin-bottom: 7px;
          line-height: 28px;
          color: $color;
        }

        .expertise-description {
          margin-bottom: 22px;
        }

        .expertise__link {
          $color: $dark;

          a, p {
            text-decoration: underline;
          }
        }
      }
    }
  }


  .expertises {
    margin-bottom: 6rem;

    .head {
      max-width: 30rem;
    }
  }

  .expertises-list {
    margin: 0 0 8rem 0;
    width: 100%;

    .line {
      display: block;
      margin: .375rem 0;
      white-space: nowrap;
    }

    .bundle {
      display: inline-block;

      &.copy {
        display: none;
      }
    }

    .item {
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;

      &:before {
        content: '';
        display: inline-block;
        margin: .5rem 0 0 0;
        width: .625rem;
        height: .625rem;
        border-radius: .3125rem;
        background: linear-gradient(129.75deg, #7225f1 0%, #8e38ed 21%, #a43bed 50%, #bf35c9 75%, #f02988 100%);
        vertical-align: middle;
      }

      span {
        display: inline-block;
        margin: 0 1.5rem;
        font-family: $crimson;
        font-size: 2em;
        line-height: 3.125rem;
        color: $dark;
        font-weight: bold;
        text-transform: lowercase;
        vertical-align: middle;
        white-space: nowrap;
        opacity: .2;
      }

      &.highlighted {
        span {
          opacity: 1;
        }
      }
    }

  }
}

@media screen and ( min-width: $deviceXl  ) {
  .brands-detail .introduction .introduction__content {
    grid-column: 7/13;
  }
}

@media screen and (max-width: $deviceL) {
  .brands-detail {
    display: inherit;

    .blobs {
      z-index: -1;
      top: -4rem;
      left: -7rem;
      width: 45rem;

      .blob {
        &.layer1 {
          left: 2.09%;
          width: 97.91%;
        }

        &.layer2 {
          left: 0;
          width: 100%;


        }

        &.layer3 {
          left: .1%;
          width: 99.90%;

        }
      }

    }

    .steps {
      &.header {
        margin-bottom: 1.8125rem;
      }
    }

    .logo {
      margin: 0;
      font-size: 14.75rem;
      line-height: 0;
      color: $dark;

      svg {
      }
    }

    .latest__devices {
      padding-top: 70px;
      padding-bottom: 70px;
    }

    .seasonal__offer {
      padding-top: 70px;
      padding-bottom: 70px;
      background-color: $lightGrey;

    }

    .toggle__container {
      display: flex;
      flex-direction: column-reverse;
    }

    .content__keydata {
      padding-top: 0px;
      padding-bottom: 0px;
      border-bottom: 0px;
      border-top: 0px;
    }


    .introduction {
      padding-bottom: 70px;
      display: flex;
      flex-direction: column;
      border-bottom: 0px;

      .introduction__infos {
        grid-column: 1/5;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
        justify-content: space-between;
        grid-row-gap: 20px;

        .introduction__info {
          grid-column: span 2;
          height: auto;

          .title {
            @include section-content-step;
            color: $chaire;
            text-transform: uppercase;
          }

          .data {
            @include articlelist-seasonal-brand;
            color: $cleargray;
            margin-top: 7px;
          }

          .socials__brand {
            margin-top: 7px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: $cleargray;

            .social-item {
              margin-left: 16px;

              a {
                color: $cleargray;
              }

              &:first-child {
                margin-left: 0px;
              }
            }
          }
        }

      }

      .introduction__content {
        grid-column: unset;
        margin-right: 0px;
        margin-top: 70px;

        span {
          color: $dark;
        }

      }
    }

    .collaboration {
      padding-top: 70px;
      padding-bottom: 70px;
      border-bottom: 0px;
      border-top: 0px;
      flex-direction: column;

      grid-template-rows: unset;
      display: grid;
      grid-template-columns: 1fr;


      .section__title {
        text-transform: uppercase;
        color: $chaire;
        margin-bottom: 35px;

        grid-column: 1/2;
        grid-row: 1/2;
      }

      .section__content {
        grid-column: 1/2;
        grid-row: 2/3;
      }

		.details-contact {
			flex-direction: column;
			margin-top: 52px;
		}
    }

    .details {
      padding-top: 70px;
      padding-bottom: 70px;
      display: flex;
      flex-direction: column-reverse;

      .details-expertises {
        grid-column: unset;
        width: 100%;
        margin-bottom: 70px;
		  display: flex;
		  flex-direction: column;

        .expertise-item {
          margin-bottom: 40px;

          &:last-child {
            margin-bottom: 0px;
          }

          .expertise-name {
            @include section-content-step;
            color: $chaire;
            text-transform: uppercase;
            margin-bottom: 7px;
            line-height: 28px;
          }

          .expertise-description {
            margin-bottom: 5px;
          }

          .expertise__link {
            $color: $dark;

            a, p {
              text-decoration: underline;
            }
          }
        }
      }

      .details-contact {
        grid-column: 1/6;

        .contact-item {
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0px;
          }

          .contact-position {
            text-transform: uppercase;
            margin-bottom: 7px;
          }

          .contact-name {
            color: $color;
          }

          .contact-email {
            color: $dark;
            text-decoration: underline;
          }
        }
      }
    }


    .keyfigures {
      position: relative;

      .blobs {
        display: none;
        z-index: -1;
        top: -14rem;
        left: 0;
        right: initial;
        margin: 0;
        width: 100%;


        .blob {
          left: initial;
          right: 0;

          &.layer1 {
            width: 100%;
          }

          &.layer2 {
            width: 100%;
          }

          &.layer3 {
            width: 100%;
          }
        }
      }
    }

    .expertises {
      margin-bottom: 6rem;

      .head {
        max-width: 30rem;
      }
    }

    .expertises-list {
      margin: 0 0 8rem 0;
      width: 100%;

      .line {
        display: block;
        margin: .375rem 0;
        white-space: nowrap;
      }

      .bundle {
        display: inline-block;

        &.copy {
          display: none;
        }
      }

      .item {
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;

        &:before {
          content: '';
          display: inline-block;
          margin: .5rem 0 0 0;
          width: .625rem;
          height: .625rem;
          border-radius: .3125rem;
          background: linear-gradient(129.75deg, #7225f1 0%, #8e38ed 21%, #a43bed 50%, #bf35c9 75%, #f02988 100%);
          vertical-align: middle;
        }

        span {
          display: inline-block;
          margin: 0 1.5rem;
          font-family: $crimson;
          font-size: 2em;
          line-height: 3.125rem;
          color: $dark;
          font-weight: bold;
          text-transform: lowercase;
          vertical-align: middle;
          white-space: nowrap;
          opacity: .2;
        }

        &.highlighted {
          span {
            opacity: 1;
          }
        }
      }

    }
  }
}
